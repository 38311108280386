import React, { useEffect, useState } from 'react';
import PageHeaderSection from "../../UI-Components/PageHeaderSection";
import { openBoxModal } from "../../../actions/Modal";
import { connect, useDispatch } from "react-redux";
import ClientRequest from "../../../Services/ClientRequest";
import ConfirmDialog from "../../UI-Components/ConfirmDialog";
import BoxesTable from "../../UI-Components/Boxes/BoxesTable";
import useFilters from "../../../Services/CustomHooks/useFilters";
import usePrevious from "../../../Services/CustomHooks/usePrevious";
import { useTranslation } from "react-i18next";

const Index = ({onOpenBoxModal}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [boxes, setBoxes] = useState([]);
  const [filters, setFilters] = useFilters();
  const prevFilters = usePrevious(filters);

  const [ confirmDelete, setConfirmDelete ] = useState(false);
  const request = new ClientRequest(dispatch);

  useEffect(() => {
    fetchRecords(true);
  },[]);

  useEffect(() => {
    if(prevFilters?.page !== filters?.page || prevFilters?.status !== filters?.status){
      fetchRecords();
    }
  }, [filters]);

  const fetchRecords = (isFirstLoad = false) => {
    const copyFilters = {...filters};
    const { page } = copyFilters;

    request.read(`boxes?page=${page}`).then((res)=> {
      const {data:{data, total, per_page, from, to, last_page} } = res;
      setBoxes(data);

      if(isFirstLoad){
        setFilters({
          ...filters,
          pagination: {
            total,
            per_page,
            from,
            to,
            last_page
          }
        });
      }
    }).catch((err) => console.log(err));
  }

  const onDeleteBoxes = () => {
    const id = confirmDelete.id;

    request.delete(`boxes/${id}`)
      .then(() => {
        fetchRecords();
      })
      .catch((err) => console.log('error', err));
    setConfirmDelete(false);
  }

  return (
    <div>
      <div>
        <PageHeaderSection title={t('admin.boxes.title')} action={onOpenBoxModal} actionTitle={t('default.create')} callbackAction={fetchRecords} />
        <BoxesTable data={boxes} filters={filters} setFilters={setFilters} action={fetchRecords} deleteAction={setConfirmDelete} editAction={onOpenBoxModal} />
      </div>

      {confirmDelete && (
        <ConfirmDialog
          title={t('admin.boxes.delete.title')}
          description={t('admin.boxes.delete.description')}
          buttonTitle={t('admin.boxes.delete.action')}
          action={onDeleteBoxes}
          close={setConfirmDelete}
        />
      )}
    </div>
  );
};

const dispatchToProps = dispatch => ({
  onOpenBoxModal: (data = null, callback = null) => dispatch(openBoxModal(data, callback))
});

export default connect(null, dispatchToProps)(Index);

