import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import ClientRequest from "../ClientRequest";

const UseCountry = () => {
  const [countries, setCountry] = useState([]);
  const dispatch = useDispatch();
  const request = new ClientRequest(dispatch);

  useEffect(() => {
    request.read('countries')
      .then((res) => {
        setCountry(res.data);
      }).catch((err) => console.log(err));
  },[]);

  return [countries];
};

export default UseCountry;
