import React from 'react';
import PageHeader from "../UI-Components/PageHeader";
import { connect } from "react-redux";

const Index = () => {
  return (
    <div>
      <PageHeader title="Reports" />
    </div>
  );
};

const stateToProps = state => (
  {
    //auth: state.auth.authenticated
  }
);

const dispatchToProps = dispatch => (
  {
    //fetchDivisions: () => dispatch(fetchDivisions()),
  }
);

export default connect(stateToProps, dispatchToProps)(Index);
