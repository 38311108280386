import React, { useEffect, useState } from 'react';
import { XIcon } from "@heroicons/react/solid";
import { useDispatch } from "react-redux";
import ClientRequest from "../../Services/ClientRequest";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Map from "../UI-Components/Maps/Map";
import useCountry from "../../Services/CustomHooks/useCountry";
import useDrivers from "../../Services/CustomHooks/useDrivers";
import useTrucks from "../../Services/CustomHooks/useTrucks";
import processServerResponse from "../../Services/Utils/ProcessServerResponse";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";

const AddBoxesModal = ({onCloseModal}) => {
  const { t } = useTranslation();
  const [countries] = useCountry();
  const [trucks] = useTrucks();
  const [drivers] = useDrivers();
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [routeAddress, setRouteAddress] = useState([]);
  const [orders, setOrders] = useState([]);
  const [showRoutes, setShowRoutes] = useState(false);
  const [zoom, setZoom] = useState(3);
  const routeAdded = () => toast.success(t('routeGenerator.modal.actions.created'));
  const [routes, setRoutes] = useState([
    ['44.967243' , '-103.771556'],
  ]);
  let history = useHistory();

  const { register, handleSubmit, setError, setValue, clearErrors, formState: { errors } } = useForm();
  const dispatch = useDispatch();
  const request = new ClientRequest(dispatch);

  const onSubmit = (data) => {
    const activeOrders = orders.filter(el => el.active).map(el => el.id);
    const payload = {
      ...data,
      orders: activeOrders
    }

    request.create('routes', payload)
      .then((res) => {
        processServerResponse(res, setError, routeAdded, onCloseModal);
        if(res.success){
          history.push(`/route-generator/${res.data.id}`);
        }
      })
      .catch((err) => console.log(err));
  }

  useEffect(() => {
    request.read('shipment?status=5&isRoute=true')
      .then((res) => {
        const newOrders = res.data.map(newOrder => (
          {...newOrder, active: false}
        ));
        setOrders(newOrders);
      }).catch((err) => console.log(err));
  },[]);

  useEffect(() => {
    if(selectedCountry){
      const query = `route-addresses?country=${selectedCountry}`;
      request.read(query)
        .then((res) => {
          setRouteAddress(res.data);
        }).catch((err) => console.log(err));
    }
  }, [selectedCountry]);

  const addPinsToMap = (e) => {
    const {name, value} = e.target;
    switch (name){
      case "routes_start_address_id": {
        const newRoutes = [...routes];
        const address = routeAddress.find(el => el.id == value);
        const latLon = [address.latitude, address.longitude];
        newRoutes[0] = latLon;
        setRoutes(newRoutes);
        break;
      }
      case "routes_end_address_id": {
        const newRoutes = [...routes];
        const address = routeAddress.find(el => el.id == value);
        const latLon = [address.latitude, address.longitude];
        newRoutes[newRoutes.length - 1] = latLon;
        setRoutes(newRoutes);
        setZoom(4);
        //setShowRoutes(true);
        break;
      }
    }
  }

  const selectOrders = (id) => {
    setValue('orders', id, { shouldDirty: true });
    const newOrder = orders.find(el => el.id == id);
    newOrder['active'] = !newOrder['active'];
    setOrders([...orders]);
  }

  return (
    <div>
      <div
        className="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
      >
        <p className="font-semibold text-gray-800">Route</p>
        <XIcon className="w-6 h-6" onClick={() => onCloseModal()}/>
      </div>
      <div className="flex flex-col px-6 py-2 bg-gray-50">
        <form id="submit-order" onSubmit={handleSubmit(onSubmit)}>
          <div className="grid grid-cols-2 gap-5">
            {orders.length > 0 ? (
              <div className="mt-2">
                <div>
                  <div className="mt-1 space-y-1">
                    <select
                      name="country_id"
                      {...register('country_id', { required: true })}
                      onChange={e => setSelectedCountry(e.target.value)}
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    >
                      <option value="">{t('default.selectCountry')}</option>
                      {Object.values(countries).map((country) => (
                        <option key={country.id} value={country.id}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                    {errors.country_id && errors.country_id.message.split("|").map((error,index) => (
                      <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                        {error && t(`errors.${error}`)}
                      </span>
                    ))}
                  </div>
                </div>
                {selectedCountry && (
                  <div>
                    <div className="grid grid-cols-2 gap-5 my-3">
                      <div>
                        <div className="mt-1 space-y-1">
                          <select
                            {...register('routes_start_address_id', { required: true })}
                            onChange={e => addPinsToMap(e)}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          >
                            <option value="">{t('default.selectFromAddress')}</option>
                            {Object.values(routeAddress).map((address) =>  (
                              <option key={address.id} value={address.id}>
                                {address.state.name} - {address.zipcode} - {address.address_1}
                              </option>
                            ))}
                          </select>
                          {errors.routes_start_address_id && errors.routes_start_address_id.message.split("|").map((error,index) => (
                            <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                            {error && t(`errors.${error}`)}
                          </span>
                          ))}
                        </div>
                      </div>
                      <div>
                        <div>
                          <div className="mt-1 space-y-1">
                            <select
                              {...register('routes_end_address_id', { required: true })}
                              onChange={e => addPinsToMap(e)}
                              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            >
                              <option value="">{t('default.selectToAddress')}</option>
                              {Object.values(routeAddress).map((address) =>  (
                                <option key={address.id} value={address.id}>
                                  {address.state.name} - {address.zipcode} - {address.address_1}
                                </option>
                              ))}
                            </select>
                            {errors.routes_end_address_id && errors.routes_end_address_id.message.split("|").map((error,index) => (
                              <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                                {error && t(`errors.${error}`)}
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div>
                        <div className="mt-1 space-y-1">
                          <select
                            {...register('driver_id', { required: true })}
                            onChange={e => addPinsToMap(e)}
                            className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                          >
                            <option value="">{t('default.selectDriver')}</option>
                            {Object.values(drivers).map((driver) =>  (
                              <option key={driver.id} value={driver.id}>
                                {driver.name}
                              </option>
                            ))}
                          </select>
                          {errors.driver_id && errors.driver_id.message.split("|").map((error,index) => (
                            <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                            {error && t(`errors.${error}`)}
                          </span>
                          ))}
                        </div>
                      </div>
                      <div>
                        <div>
                          <div className="mt-1 space-y-1">
                            <select
                              {...register('truck_id', { required: true })}
                              onChange={e => addPinsToMap(e)}
                              className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                            >
                              <option value="">{t('default.selectTruck')}</option>
                              {Object.values(trucks).map((truck) =>  (
                                <option key={truck.id} value={truck.id}>
                                  {truck.name}
                                </option>
                              ))}
                            </select>
                            {errors.truck_id && errors.truck_id.message.split("|").map((error,index) => (
                              <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                                {error && t(`errors.${error}`)}
                              </span>
                            ))}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div  className='mt-2'>
                      <p>{t('default.pendingOrders')}</p>
                      {errors.orders && errors.orders.message.split("|").map((error,index) => (
                        <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                          {error && t(`errors.${error}`)}
                        </span>
                      ))}
                      <div className="grid grid-cols-2 gap-2 sm:grid-cols-2 mt-2">
                        {orders.map(order => (
                          <div onClick={() => selectOrders(order.id)} key={order.id} className={`relative rounded-lg border ${order.active ? 'border-blue-700 hover:border-blue-400': 'border-gray-300 hover:border-gray-400'} bg-white px-3 py-3 shadow-sm flex items-center space-x-3`}>
                            <div className="flex-1 min-w-0">
                              <a href="#" className="focus:outline-none">
                                <span className="absolute inset-0" aria-hidden="true" />
                                <p className="text-sm font-medium text-gray-900">
                                  {order?.pickup_address?.address_1}
                                </p>
                                <p className="text-sm text-gray-500 truncate">
                                  {order?.pickup_address?.state?.name}, {order?.pickup_address?.country?.name}. {order?.pickup_address?.zipcode}
                                </p>
                              </a>
                            </div>
                          </div>
                        ))}
                      </div>
                    </div>
                  </div>
                )}
              </div>
            ): (
              <div className='flex justify-center'>
                <p className='pt-20'>{t('default.noPendingOrders')}</p>
              </div>
            )}
            <div className="mt-2 generator-map">
              <Map routes={routes} positionCenter={routes[0]} zoom={zoom} showRoutes={showRoutes}/>
            </div>
          </div>
    </form>
      </div>
      <div
        className="flex flex-row items-center justify-between p-5 bg-white border-t border-gray-200 rounded-bl-lg rounded-br-lg"
      >
        <button className="px-4 py-2 font-semibold" onClick={() => onCloseModal()}>
          Cancel
        </button>
        <button
          type='submit'
          onClick={() => clearErrors('orders')}
          form="submit-order"
          className="px-4 py-2 text-white font-semibold bg-blue-500 rounded"
        >
          Save
        </button>
      </div>
    </div>
  );
};

export default AddBoxesModal;
