import { useEffect, useState } from "react";
import { NumberParam, StringParam, useQueryParam } from "use-query-params";

const useFilters = (isPage = true, isSearch = null, isStatus = null) => {
  const [page, setPage] = useQueryParam('page', NumberParam);
  const [search, setSearch] = useQueryParam('search', StringParam);
  const [status, setStatus] = useQueryParam('status', NumberParam);

  const initialState = {
    page: page || 1,
    pagination: {}
  };

  if(isSearch){
    initialState.search =  search || "";
  }

  if(isStatus){
    initialState.status =  status || 0;
  }

  const [filters, setFilters] = useState(initialState);

  useEffect(() => {
    const {search, page, status} = filters;
    setPage(page);
    if(isSearch){
      setSearch(search);
    }
    if(isStatus){
      setStatus(status);
    }
  }, [filters]);

  return [filters, setFilters];
}

export default useFilters;
