import React, { useEffect, useState } from 'react';
import i18n from "i18next";
import { useTranslation } from "react-i18next";

const Multilanguage = () => {
  const { t } = useTranslation();
  const initialState = [
    {
      title: "Español",
      ab: "es",
      active: false,
    },
    {
      title: "English",
      ab: "en",
      active: false,
    }
  ];
  const [languages, setLanguage] = useState(initialState);

  useEffect(() => {
    const lang = localStorage.getItem('i18nextLng') || navigator.language;
    const newLang = initialState.map(el => ({
      ...el,
      active: lang.includes(el.ab)
    }));
    setLanguage(newLang);
  }, []);


  useEffect(() => {
    if(languages){
      const selectedLanguage = languages.find(el => el.active);
      if(selectedLanguage){
        i18n.changeLanguage(selectedLanguage.ab);
      }
    }
  },[languages]);

  const changelanguage = (e) => {
    const { name, value } = e.target;
    const newLang = initialState.map(el => ({
      ...el,
      active: value.includes(el.ab)
    }));

    setLanguage(newLang);
  }

  return (
    <div>
      <select
        name="language"
        onChange={(e) => changelanguage(e)}
        className="justify-end block text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md w-full"
      >
        <option value="">{t('default.selectLang')}</option>
          {Object.values(languages).map((lang, index) => (
            <option
              key={index}
              value={lang.ab}
              selected={lang.active}
            >
              {lang.title}
            </option>
          ))}
        </select>
    </div>
  );
};

export default Multilanguage;
