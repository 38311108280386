import {
  OPEN_BOX_MODAL,
  CLOSE_MODAL,
  OPEN_ADDRESS_MODAL,
  OPEN_ROUTE_ADDRESS_MODAL,
  OPEN_DRIVERS_MODAL,
  OPEN_TRUCKS_MODAL,
  OPEN_ORDERS_MODAL,
  OPEN_ADD_BOX_MODAL,
  OPEN_ADD_USERS_MODAL,
  OPEN_BOX_DETAILS, OPEN_ROUTE_GENERATOR
} from "../actions/Modal";

const initialState = {
  activeModal: 0,
  onOk: null,
  options: null,
};

const MODAL_ADDRESS = 1;
const MODAL_BOX = 2;
const MODAL_DRIVERS = 3;
const MODAL_TRUCKS = 4;
const MODAL_ORDERS = 5;
const MODAL_ADD_BOXES = 6;
const MODAL_ADD_USERS = 7;
const MODAL_BOX_DETAILS = 8;
const MODAL_ROUTE_GENERATOR = 9;
const MODAL_ROUTE_ADDRESS = 10;

export default function(state = initialState, action){
  switch (action.type) {
    case CLOSE_MODAL: {
      return {...initialState};
    }
    case OPEN_ADDRESS_MODAL: {
      return {
        ...state,
        activeModal: MODAL_ADDRESS,
        onOk: action.onOk,
        options: action.options,
      };
    }
    case OPEN_ROUTE_ADDRESS_MODAL: {
      return {
        ...state,
        activeModal: MODAL_ROUTE_ADDRESS,
        onOk: action.onOk,
        options: action.options,
      };
    }
    case OPEN_BOX_MODAL: {
      return {
        ...state,
        activeModal: MODAL_BOX,
        onOk: action.onOk,
        options: action.options,
      };
    }
    case OPEN_DRIVERS_MODAL: {
      return {
        ...state,
        activeModal: MODAL_DRIVERS,
        onOk: action.onOk,
        options: action.options,
      };
    }
    case OPEN_TRUCKS_MODAL: {
      return {
        ...state,
        activeModal: MODAL_TRUCKS,
        onOk: action.onOk,
        options: action.options,
      };
    }
    case OPEN_ORDERS_MODAL: {
      return {
        ...state,
        activeModal: MODAL_ORDERS,
        onOk: action.onOk,
        options: action.options,
      };
    }
    case OPEN_ADD_BOX_MODAL: {
      return {
        ...state,
        activeModal: MODAL_ADD_BOXES,
        onOk: action.onOk,
        options: action.options
      }
    }
    case  OPEN_ADD_USERS_MODAL: {
      return {
        ...state,
        activeModal: MODAL_ADD_USERS,
        onOk: action.onOk,
        options: action.options
      }
    }
    case  OPEN_BOX_DETAILS: {
      return {
        ...state,
        activeModal: MODAL_BOX_DETAILS,
        onOk: action.onOk,
        options: action.options
      }
    }
    case OPEN_ROUTE_GENERATOR: {
      return {
        ...state,
        activeModal: MODAL_ROUTE_GENERATOR,
        onOk: action.onOk,
        options: action.options
      }
    }
    default: {
      return {...state};
    }
  }
}
