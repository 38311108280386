import './App.css';
import React, { Component } from 'react';
import {Redirect, Route, Switch} from 'react-router-dom';
import PropTypes from 'prop-types';
import { connect } from "react-redux";
import ProtectedRoute from "./pages/ProtectedRoute";
import NotFound from "./pages/NotFound";
import Login from "./pages/Login";
import Register from './pages/Register'
import Profile from "./components/Profile";
import Dashboard from "./components/Dashboard";
import OrderDetail from "./components/Orders";
import Admin from "./components/Admin";
import Users from "./components/Users";
import Reports from "./components/Reports";
import { getCurrentUser } from "./Services/authService";
import Loading from "./pages/Loading";
import ForgotPassword from "./pages/ForgotPassword";
import PasswordReset from "./pages/PasswordReset";
import RouteGenerator from './components/Route-Generator';
import RouteOrder from './components/Route-Order';

class App extends Component {
  constructor(props) {
    super(props);
    this.state = {
      loading: true
    }
  }
  componentDidMount(){
    const { getCurrentUser } = this.props;
    getCurrentUser();

    setTimeout(() => {
      this.setState({loading: false});
    },300);
  }

  render() {
    const { authenticated } = this.props;

    return(
      <React.Fragment>
          {this.state.loading ? (
            <Loading />
          ): (
            <Switch>
              <Route exact path="/login">
                {authenticated? <Redirect to='/' /> : <Login />}
              </Route>
              <Route exact path="/register">
                {authenticated? <Redirect to='/' /> : <Register />}
              </Route>
              <Route exact path="/forgot-password">
                {authenticated? <Redirect to='/' /> : <ForgotPassword />}
              </Route>
              <Route exact path="/password-reset/:token">
                {authenticated? <Redirect to='/' /> : <PasswordReset />}
              </Route>
              <ProtectedRoute exact path="/" component={Dashboard} isAuth={authenticated}/>
              <ProtectedRoute exact path="/orders/:id" component={OrderDetail} isAuth={authenticated}/>
              <ProtectedRoute exact path="/profile/:name(info|address|password-reset)" component={Profile} isAuth={authenticated}/>
              <ProtectedRoute exact path="/admin/:name(drivers|trucks|boxes|order-status|route-addresses)" component={Admin} isAuth={authenticated}/>
              <ProtectedRoute exact path="/reports" component={Reports} isAuth={authenticated}/>
              <ProtectedRoute exact path="/users" component={Users} isAuth={authenticated}/>
              <ProtectedRoute exact path="/route-generator" component={RouteGenerator} isAuth={authenticated}/>
              <ProtectedRoute exact path="/route-generator/:id" component={RouteOrder} isAuth={authenticated}/>
              <Route exact={true} path="*" component={NotFound}/>
            </Switch>
          )}
      </React.Fragment>
    );
  }
}

App.propTypes = {
  authenticated: PropTypes.bool
}

const mapStateToProps = (state) => ({ authenticated: state.auth.authenticated });

const mapDispatchToProps = (dispatch) => ({
  getCurrentUser: () => { dispatch(getCurrentUser()) },

});

export default connect(mapStateToProps, mapDispatchToProps)(App);
