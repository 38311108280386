import React from 'react';
import { PlusIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";

const EmptyStates = ({state, action}) => {
  const { t } = useTranslation();

  return (
    <div className="text-center pt-4">
      <svg className="mx-auto h-12 w-12 text-gray-400" fill="none" viewBox="0 0 24 24" stroke="currentColor"
           aria-hidden="true">
        <path vectorEffect="non-scaling-stroke" strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
              d="M9 13h6m-3-3v6m-9 1V7a2 2 0 012-2h6l2 2h6a2 2 0 012 2v8a2 2 0 01-2 2H5a2 2 0 01-2-2z"/>
      </svg>
      <h3 className="mt-2 text-sm font-medium text-gray-900">{t('default.emptyStates.notFound')} {state}</h3>
      <p className="mt-1 text-sm text-gray-500">
        {t('default.emptyStates.title')} {state}.
      </p>
      <div className="mt-6">
        <button
          type="button"
          onClick={()=> action()}
          className="inline-flex items-center px-4 py-2 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          <PlusIcon className="h-10 w-10" />
          {t('default.emptyStates.new')} {state}
        </button>
      </div>
    </div>
  );
};

export default EmptyStates;
