import React, { useState } from 'react';
import AuthLayout from "../components/Auth/AuthLayout";
import SubmitButton from "../components/Auth/submitButton";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import { Link, useHistory, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import ClientRequest from "../Services/ClientRequest";

const PasswordReset = () => {
  const { t } = useTranslation();
  const {token} = useParams();
  const history = useHistory();
  const { register, handleSubmit , setError, formState: { errors }} = useForm();
  const dispatch = useDispatch();
  const [serverError, setServerError] = useState(false);
  const request = new ClientRequest(dispatch);

  const onSubmit = (data) => {
    const payload = {...data, token};
    setServerError(false);

    request.create('auth/reset-password', payload)
      .then((res) => {
        const { success, data, message } = res;
        if(!success){
          //set errors
          if(data){
            const errors = data;
            Object.entries(errors).map(error => {
              setError(error[0], {
                type: "manual",
                message: error[1].join("|")
              });
            });
          }

          if(message && message === "Record not found"){
            setServerError(true);
          }
        }else{
          history.push('/');
        }
      })
      .catch((err) => {
        console.log('reset_password', err);
      });

  }

  return (
    <AuthLayout title={t('passwordReset.title')} displayBrand={true}>
      <form className="mt-2 space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="rounded-md shadow-sm space-y-3">
          <div>
            <label htmlFor="email" className="sr-only">{t('default.firstName')}</label>
            <input
              type="text"
              autoComplete="text"
              {...register('email', { required: true })}
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder={`${t('default.emailAddress')} ${t('default.or')} ${t('default.phoneNumber')}`}
            />
            {!errors.token && errors.email && errors.email.message.split("|").map((error,index) => (
              <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                {t(`errors.${error}`)}
              </span>
            ))}
          </div>
          <div>
            <label htmlFor="password" className="sr-only">{t('default.firstName')}</label>
            <input
              type="password"
              autoComplete="text"
              {...register('password', { required: true })}
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder={t('passwordReset.password')}
            />
            {!errors.token && errors.password && errors.password.message.split("|").map((error,index) => (
              <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                {t(`errors.${error}`)}
              </span>
            ))}
          </div>
          <div>
            <label htmlFor="password_confirmation" className="sr-only">{t('default.firstName')}</label>
            <input
              type="password"
              autoComplete="text"
              {...register('password_confirmation', { required: true })}
              className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
              placeholder={t('passwordReset.confirm')}
            />
          </div>
          { serverError && (
            <span className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1 mb-3'>
              {t('errors.SomethingHappened')}
            </span>
          )}
        </div>
        <div>
          {errors.token ? (
            <div className="text-sm mt-5 flex justify-center">
              <Link to='/forgot-password' className="font-medium text-indigo-600 hover:text-indigo-500">
                {t('forgotPassword.requestNew')}
              </Link>
            </div>
          ): (
            <div>
              <SubmitButton text={t('passwordReset.action')} />

              <div className="text-sm mt-5 flex justify-center">
                <Link to='/' className="font-medium text-indigo-600 hover:text-indigo-500">
                  {t('default.back')}
                </Link>
              </div>
            </div>
          )}
        </div>
      </form>
    </AuthLayout>
  );
};

export default PasswordReset;
