import { useEffect, useState } from 'react';

const UseCalculateEstimatedPrice = (order) => {
  const [estimatedPrice, setEstimatedPrice] = useState(0);
  const [isPriceComplete, setIsPriceComplete] = useState(true);

  useEffect(() => {
    let total = 0;
    for (let i = 0; i < order.details.length; i++) {
      const box = order.details[i].box;
      if (box && box.default_price > 0){
        total += box.default_price;
      }else{
        setIsPriceComplete(false);
      }
    }
    setEstimatedPrice(total);
  }, [order]);

  return [estimatedPrice, isPriceComplete];
};

export default UseCalculateEstimatedPrice;
