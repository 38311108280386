import React from 'react';
import { Link } from "react-router-dom";
import Pagination from "../Pagination";
import { useTranslation } from "react-i18next";
import useUserCanEdit from "../../../Services/CustomHooks/useUserCanEdit";
import useOrderCancelled from "../../../Services/CustomHooks/useOrderCancelled";

const OrdersDetailTable = ({data, isAdmin, onDeleteBox, onOpenBoxDetails, callback}) => {
  const { t } = useTranslation();
  const [userCanEdit] = useUserCanEdit(data);
  const [isOrderCancelled] = useOrderCancelled(data);

  return (
    <div className="pt-5">
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full sm:px-6 lg:px-8">
            <div className="overflow-hidden">
              <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                <tr>
                  <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('order.table.boxName')}
                  </th>
                  <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('order.table.fragile')}
                  </th>
                  <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('order.table.numberOfItems')}
                  </th>
                  <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('order.table.pricePerItem')}
                  </th>
                  <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                    {t('default.actions')}
                  </th>
                </tr>
                </thead>
                <tbody>
                  {Object.values(data.details).map((item, index) => (
                    <tr key={index} className={!Object.values(item.box_content).reduce((a, {number_of_items}) => a + parseFloat(number_of_items) , 0) ? "bg-yellow-50": "bg-white"}>
                      <td className="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-500">
                        {item.box ? (
                          <span>{item?.box?.name}</span>
                        ): (
                          <span>{t(`default.categoriesList.${item.category_detail.name}`)}</span>
                        )}
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-500">
                        <span>{(item?.fragile) ? t('default.yes') : t('default.no')}</span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-500">
                        <span>{Object.values(item.box_content).reduce((a, {number_of_items}) => a + number_of_items , 0)}</span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-500">
                        <span>${Object.values(item.box_content).reduce((a, {price_per_item}) => a + parseFloat(price_per_item) , 0)}</span>
                      </td>
                      <td className="px-6 py-4 whitespace-nowrap text-center text-sm text-gray-500">
                          <div>
                            {((!isOrderCancelled) && (isAdmin || userCanEdit)) ? (
                              <div>
                                <span className='mr-2 cursor-pointer' onClick={() => onOpenBoxDetails(item, true, callback)}>{t('default.details')}</span>
                                <span className='ml-2 cursor-pointer' onClick={() => onDeleteBox(item)}>{t('default.delete')}</span>
                              </div>
                            ): (
                              <span className='mr-2 cursor-pointer' onClick={() => onOpenBoxDetails(item, false, callback)}>{t('default.details')}</span>
                            )}
                          </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrdersDetailTable;
