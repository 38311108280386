import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import ClientRequest from "../ClientRequest";
import { loadCountries, loadStates } from "../../actions/Admin";

const UseCountry = () => {
  const [states, setStates] = useState([]);
  const dispatch = useDispatch();
  const request = new ClientRequest(dispatch);

  useEffect(() => {
    request.read('states')
      .then((res) => {
        setStates(res.data);
      }).catch((err) => console.log(err));
  },[]);

  return [states, setStates];
};

export default UseCountry;
