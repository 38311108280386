import axios from "axios";
import configuration from '../config.json';
import { logout } from './authService';

export const Client = (dispatch, {method = 'get', url, data = null}) => {
  return new Promise((resolve, reject) => {
    const token = localStorage.getItem('accessToken');
    const headers = {
      'Content-type': 'application/json',
      'Accept': 'application/json',
      'Authorization': `Bearer ${token}`
    };

    const config = {
      url: `${configuration.API_URL}/${url}`,
      method,
      headers,
    }

    if (data) {
      config.data = data;
    }

    axios.request(config)
      .then(response => {
        const { data } = response;
        resolve(data);
      })
      .catch(error => {
        const { response: {status, data}} = error;
        if(status === 401){
          switch (data.message) {
            default: {
              dispatch(logout());
              reject(data.message);
            }
          }
        }
        if(status === 403){
          console.log('redirect to homepage');
          window.location.href = '/';
        }

        reject({'handle exception': error});
      });
  });
}
