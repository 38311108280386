import React from 'react';
import { useTranslation } from "react-i18next";
import QRCode from "qrcode.react";

const OrdersAddresses = ({order}) => {
  const { t } = useTranslation();

  return (
    <div className="py-3 xl:pt-6 xl:pb-0">
      <h2 id="activity-title" className="text-lg font-medium text-gray-900">{t('default.OrderDetails')}</h2>
      <div className="flex grid grid-cols-3 gap-3 pt-5">
        <div className="">
          <h4 className="font-bold">{t('default.pickUpAddress')}</h4>
          <p> {order?.pickup_address?.contact_name}</p>
          <p>{order?.pickup_address.address_1} {order?.pickup_address.address_2}, {order?.pickup_address?.zipcode} ({order?.pickup_address?.country?.name})</p>
          <p>{order?.pickup_address.contact_number}</p>
        </div>
        <div className="">
          <h4 className="font-bold">{t('default.deliveryAddress')}</h4>
          <p>{order?.delivery_address.contact_name}</p>
          <p>{order?.delivery_address.address_1} {order?.delivery_address.address_2}, {order?.delivery_address?.zipcode} ({order?.delivery_address?.country?.name})</p>
          <p>{order?.delivery_address.contact_number}</p>
        </div>
        <div className="flex justify-center">
          <QRCode value={`https//app.globalxpressint.com/orders/${order.id}`} />
        </div>
      </div>
    </div>
  );
};

export default OrdersAddresses;
