import React, { useEffect, useState } from 'react';
import { CheckIcon, DotsVerticalIcon, XIcon } from "@heroicons/react/solid";
import DropdownMenu from "../DropdownMenu";
import { useDispatch } from "react-redux";
import ClientRequest from "../../../Services/ClientRequest";
import useUserCanEdit from "../../../Services/CustomHooks/useUserCanEdit";
import useOrderCancelled from "../../../Services/CustomHooks/useOrderCancelled";
import useUserCanSendOrder from "../../../Services/CustomHooks/useUserCanSendOrder";
import useIsOrderInProgress from "../../../Services/CustomHooks/useIsOrderInProgress";
import useOrderHasBoxes from "../../../Services/CustomHooks/useOrderHasBoxes";
import useOrderHasBoxContent from "../../../Services/CustomHooks/useOrderHasBoxContent";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";

const OrderSummary = ({order, onSubmitOrder, isAdmin, setMissingBoxesUponSubmit, setTotalPriceDialog}) => {
  const { t } = useTranslation();

  const dispatch = useDispatch();
  const request = new ClientRequest(dispatch);
  const [statuses, setStatuses] = useState([]);
  const [userCanEdit] = useUserCanEdit(order);
  const [isOrderCancelled] = useOrderCancelled(order);
  const [userCanSendOrder] = useUserCanSendOrder(order);
  const [isOrderInProgress] = useIsOrderInProgress(order);
  const [existBoxes] = useOrderHasBoxes(order);
  const [boxContent] = useOrderHasBoxContent(order);
  const orderError = () => toast.error(t('order.messages.content'));

  useEffect(() => {
    request.read('shipment-status')
      .then((res) => {
        setStatuses(res.data);
      })
      .catch((err) => console.log(err));
  },[]);


  const changeOrderStatus = (id) => {
    const status = statuses.find(el => el.id === id);
    let canContinue = true;

    if(id >= 4){
      if(order.total_price <= 0){
        setTotalPriceDialog(true);
        canContinue = false;
      }
    }

    if(id >= 3){
      if(!existBoxes){
        setMissingBoxesUponSubmit(true);
        canContinue = false;
      }else if(!boxContent){
        canContinue = false;
        orderError();
      }
    }

    if(canContinue){
      onSubmitOrder(status);
    }
  }

  return (
    <div className="md:flex md:items-center md:justify-between md:space-x-4 xl:border-b xl:pb-6">
      <div>
        <h1 className="text-2xl font-bold text-gray-900 m-0 p-0">#{order?.id} - {order?.customer?.email} - {order?.customer?.phone_number}</h1>
      </div>
      <div className="mt-4 flex space-x-3 md:mt-0">
        {isAdmin ? (
          <div className="flex grid-2 gap-2">
            <button
              type="button"
              onClick={() => setTotalPriceDialog(true)}
              className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-white bg-blue-600 hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
            >
              <span className="pl-2">{t('default.updatePrice')}</span>
            </button>
            <DropdownMenu items={statuses} action={changeOrderStatus}>
              <DotsVerticalIcon className="h-5 w-5"/>
            </DropdownMenu>
          </div>
        ): (
          <React.Fragment>
            {((!isOrderInProgress && !isOrderCancelled )|| userCanSendOrder) && (
              <button
                type="button"
                onClick={() => changeOrderStatus(order.status.actions.cancel)}
                className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-white bg-red-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
              >
                <XIcon className="h-5 w-5"/>
                <span className="pl-2">{t('default.cancelOrder')}</span>
              </button>
            )}
            {((!isOrderCancelled) && (userCanEdit || userCanSendOrder)) && (
              <button
                type="button"
                onClick={() => changeOrderStatus(order.status.actions.next)}
                className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-white bg-green-600 hover:bg-green-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
              >
                <CheckIcon className="h-5 w-5"/>
                <span className="pl-2">{t('default.sendOrder')}</span>
              </button>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default OrderSummary;
