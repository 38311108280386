import React, { useEffect, useState } from 'react';
import { XIcon } from "@heroicons/react/solid";
import { connect, useDispatch } from "react-redux";
import { useForm } from "react-hook-form";
import ClientRequest from "../../Services/ClientRequest";
import { openAddressModal, openAddUsersModal, openOrdersModal } from "../../actions/Modal";
import { useHistory } from "react-router-dom";
import { toast } from "react-toastify";
import { canDo } from "../../actions/Auth";
import { useTranslation } from "react-i18next";

const OrderModal = ({onCloseModal, user, onOpenAddressModal, onOpenAddUsersModal, selecteduser}) => {
  const { t } = useTranslation();
  const [isAdmin, setIsAdmin] = useState();
  const [pickupAddress, setPickUpAddress] = useState([]);
  const [deliveryAddresses, setDeliveryAddress] = useState([]);
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);

  const { register, handleSubmit, formState: { errors } } = useForm();
  const orderCreated = () => toast.success("Order Created");

  const dispatch = useDispatch();
  let history = useHistory();
  const request = new ClientRequest(dispatch);

  useEffect(() => {
    setIsAdmin(canDo(user));
  },[]);

  useEffect(() => {
    if(isAdmin){
      request.read('users?type=all')
        .then((res) => {
          setUsers(res.data);
        }).catch((err) => console.log(err));
    }
  },[isAdmin]);

  useEffect(() => {
    if(selecteduser){
      fetchRecords(selecteduser);
    }else{
      fetchRecords();
    }
  }, []);

  const fetchRecords = (userId = null) => {
    const url = userId ? `addresses?userId=${userId}`: 'addresses';

    if(userId){
      setSelectedUser(userId);
    }

    request.read(url)
      .then((res) => {
        const { data } = res;
        const pickUpAddress = Object.values(data).filter(el => el.country.id ===2);
        setPickUpAddress(pickUpAddress);
        setDeliveryAddress(res.data);
      }).catch((err) => console.log(err));
  }

  const fetchAddressesByUser = (id) => {
    fetchRecords(id);
  }

  const onSubmit = (data) => {
    request.create('shipment', data)
      .then((res) => {
        const { success, data } = res;
        if(success && data){
          history.push(`/orders/${data.id}`);
          orderCreated();
        }
      }).catch((err) => console.log(err));

    onCloseModal();
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div
        className="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
      >
        <p className="font-semibold text-gray-800">{t('order.modal.title')}</p>
        <XIcon className="w-6 h-6" onClick={() => onCloseModal()}/>
      </div>
      <div>
        <div className="flex flex-col px-6 py-5 bg-gray-50">
          <div className="flex flex-col sm:flex-col items-center mb-5 sm:space-x-5">
            {isAdmin && (
              <div className="w-full mx-0 mb-5" style={{marginLeft: 0}}>
                <div className="flex justify-between">
                  <label htmlFor="user_id" className="block text-sm font-medium text-gray-900">
                    {t('default.onBehalfOfUser')}
                  </label>
                  <span
                    onClick={() => onOpenAddUsersModal(null, 'orderModal')}
                    className="ml-2 text-xs font-medium text-gray-500 cursor-pointer"
                  >
                      ({t('default.addNewUser')})
                    </span>
                </div>
                <div>
                  <select
                    name="user_id"
                    {...register('user_id', { required: true })}
                    onChange={(e) => fetchAddressesByUser(e.target.value)}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    <option value="">{t('default.selectUser')}</option>
                    {Object.values(users).map((user) => (
                      <option key={user.id} value={user.id} selected={selecteduser == user.id ? "selected" : ""}>
                        {user.firstname} {user.lastname} - {user.email}
                      </option>
                    ))}
                  </select>
                  {errors.user_id && <span className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>{errors.user_id.message}</span>}
                </div>
              </div>
            )}
            <div className="w-full mt-3" style={{marginLeft: 0}}>
              <div className="grid grid-cols-2 gap-5">
                <div className="" style={{marginLeft: 0}}>
                  <div className="flex justify-between">
                    <label htmlFor="user_address_pickup" className="block text-sm font-medium text-gray-900">
                      {t('orders.table.pickupAddress')}
                    </label>
                    <span
                      onClick={() => onOpenAddressModal(null, 'orderModal', selectedUser)}
                      className="ml-2 text-xs font-medium text-gray-500 cursor-pointer"
                    >
                      ({t('default.addNewAddress')})
                    </span>
                  </div>
                  {pickupAddress && (
                    <select
                      name="user_address_pickup"
                      {...register('user_address_pickup', { required: true })}
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    >
                      <option value="">{t('default.selectAddress')}</option>
                      {Object.values(pickupAddress).map((address) => (
                        <option key={address.id} value={address.id}>
                          {address.country.name} - {address.address_1}, {address.zipcode}
                        </option>
                      ))}
                    </select>
                  )}
                  {errors.user_address_pickup && <span className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>{errors.user_address_pickup.message}</span>}
                </div>
                <div className="mx-0" style={{marginLeft: 0}}>
                  <div className="flex justify-between">
                    <label htmlFor="user_address_delivery" className="block text-sm font-medium text-gray-900">
                      {t('orders.table.deliveryAddress')}
                    </label>
                    <span
                      onClick={() => onOpenAddressModal(null, 'orderModal', selectedUser)}
                      className="ml-2 text-xs font-medium text-gray-500 cursor-pointer"
                    >
                      ({t('default.addNewAddress')})
                    </span>
                  </div>
                  {deliveryAddresses && (
                    <select
                      name="user_address_delivery"
                      defaultValue={1}
                      {...register('user_address_delivery', { required: true })}
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    >
                      <option value="">{t('default.selectAddress')}</option>
                      {Object.values(deliveryAddresses).map((address) => (
                        <option key={address.id} value={address.id}>
                          {address.country.name} - {address.address_1}, {address.zipcode}
                        </option>
                      ))}
                    </select>
                    )}
                  {errors.user_address_delivery && <span className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>{errors.user_address_delivery.message}</span>}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div
        className="flex flex-row items-center justify-between p-5 bg-white border-t border-gray-200 rounded-bl-lg rounded-br-lg"
      >
        <button className="px-4 py-2 font-semibold" onClick={() => onCloseModal()}>
          {t('default.cancel')}
        </button>
        <button type='submit' className="px-4 py-2 text-white font-semibold bg-blue-500 rounded">
          {t('default.save')}
        </button>
      </div>
    </form>
  );
};

const stateToProps = state => (
  {
    addresses: state.user.addresses,
    user: state.user.info
  }
);

const dispatchToProps = dispatch => ({
  onOpenAddressModal: (data = null, onOk = null, user = null) => dispatch(openAddressModal(data, onOk, user)),
  onOpenOrdersModal: (data = null) => dispatch(openOrdersModal(data)),
  onOpenAddUsersModal: (data = null, callback) => dispatch(openAddUsersModal(data, callback)),
});

export default connect(stateToProps, dispatchToProps)(OrderModal);
