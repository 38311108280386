import React from 'react';
import './Counter.css';

const Counter = ({item, updateValue}) => {
  return (
    <div className="custom-number-input h-10 w-full">
      <label htmlFor="custom-input-number" className="w-full text-gray-700 text-sm font-semibold flex justify-center">{(item.custom_size !== 0) ? `Custom ${item.height_in}x${item.width_in}x${item.length_in}` : item.name} </label>
      <div className="flex flex-row h-10 w-full rounded-lg relative bg-transparent mt-1">
        <button
          type="button"
          onClick={() => {(item.count > 0) && updateValue(item.id, item.count--)}}
          className="bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-l cursor-pointer outline-none">
          <span className="m-auto text-2xl font-thin">−</span>
        </button>
        <input
          type="number"
          min="0"
          className="outline-none focus:outline-none text-center w-full bg-gray-300 font-semibold text-md hover:text-black focus:text-black  md:text-basecursor-default flex items-center text-gray-700  outline-none cursor-default"
          name="custom-input-number"
          onChange={(e) => updateValue(item.id, e.target.value)}
          value={item.count} />
        <button
          type="button"
          onClick={() => updateValue(item.id, item.count++)}
          className="bg-gray-300 text-gray-600 hover:text-gray-700 hover:bg-gray-400 h-full w-20 rounded-r cursor-pointer">
          <span className="m-auto text-2xl font-thin">+</span>
        </button>
      </div>
    </div>
  );
};

export default Counter;

