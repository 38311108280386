import React from 'react';
import Table2 from "../Table2";
import { useTranslation } from "react-i18next";

const TrucksTable = ({data, action, setFilters, filters, deleteAction, editAction, callback}) => {
  const { t } = useTranslation();
  return (
    <Table2 action={action} setFilters={setFilters} filters={filters}>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
        <tr>
          <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
            {t('admin.trucks.table.name')}
          </th>
          <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
            {t('admin.trucks.table.actions')}
          </th>
        </tr>
        </thead>
        <tbody>
        {Object.keys(data).length > 0 ? (
          <React.Fragment>
            {Object.values(data).map((item, index) => (
              <tr key={index} className="bg-white">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                  <span>{item.name}</span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                  <span className="mx-2 cursor-pointer" onClick={() => editAction(item, callback)}>{t('default.edit')}</span>
                  <span className="mx-2 cursor-pointer" onClick={() => deleteAction(item, callback)}>{t('default.delete')}</span>
                </td>
              </tr>
            ))}
          </React.Fragment>
        ): (
          <tr className="bg-white">
            <td colSpan="7" className="px-6 py-4 whitespace-nowrap text-sm text-gray-500">Nothing to show</td>
          </tr>
        )}
        </tbody>
      </table>
    </Table2>
  );
};

export default TrucksTable;
