import {createStore, applyMiddleware, compose} from "redux";
import thunk from 'redux-thunk';
import rootReducer from './reducers';
import { Client } from "./Services/Client";

const store = createStore(
  rootReducer,
  compose(
    applyMiddleware(thunk.withExtraArgument(Client))
  )
);

export default store;
