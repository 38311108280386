import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import ClientRequest from "../../Services/ClientRequest";
import { useDispatch } from "react-redux";
import RouteAddresses from "../UI-Components/Route/RouteAddresses";
import RouteSummary from "../UI-Components/RouteGenerator/RouteSummary";
import RouteDetailSidebar from "../UI-Components/RouteGenerator/RouteDetailSidebar";
import { toast } from "react-toastify";
import ConfirmDialog from "../UI-Components/ConfirmDialog";

const Index = () => {
  const { t } = useTranslation();
  const forceUpdate = useCallback(() => fetchRecords(), []);
  const [order, setOrder] = useState(null);
  const dispatch = useDispatch();
  const request = new ClientRequest(dispatch);
  const {id: orderId} = useParams();
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const orderUpdated = () => toast.success("Order Updated");

  useEffect(() => {
    fetchRecords();
  },[]);

  const fetchRecords = () => {
    setOrder(null);
    request.read(`routes/${orderId}`)
      .then((res) => {
        const {data: order, success} = res;

        if(success){
          setOrder(order);
        }
      })
      .catch((e) => console.log('error shipment', e));
  }

  const updateOrder = () => {
    const url = `routes/${order.id}`;

    const payload = {};

    if(confirmSubmit){
      payload.status = confirmSubmit.id;
    }

    request.update(url, payload)
      .then((res) => {
        orderUpdated();
        setConfirmSubmit(false);
        forceUpdate();
      })
      .catch((err) => console.log(err));
  }

  return (
    <div>
      <main className="flex-1">
        <div className="px-2 py-5">
          <div className="max-w-full mx-auto px-8 sm:px-6 xl:grid xl:grid-cols-3">
            <div className="xl:col-span-2 xl:pr-8 xl:border-r xl:border-gray-200">
              <div className="pb-4">
               {order && (
                  <div>
                    <RouteSummary
                      onSubmitOrder={setConfirmSubmit}
                      order={order}
                    />
                    <RouteAddresses order={order} />
                  </div>
                )}
                <hr/>
                <div className="mt-3">
                  Mapa
                </div>
              </div>
            </div>
            <aside className="hidden xl:block xl:pl-8">
              {order && (
                  <RouteDetailSidebar order={order} />
              )}
            </aside>
          </div>
          {confirmSubmit && (
            <ConfirmDialog
              title={`${t(`order.messages.submit.title`)} ${t(`orders.items.${confirmSubmit.name}`)}?`}
              description={t('order.messages.submit.description')}
              buttonTitle={t('order.messages.submit.action')}
              action={updateOrder}
              close={setConfirmSubmit}
              type='add'
            />
          )}
        </div>
      </main>
    </div>
  );
};

export default Index;
