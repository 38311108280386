import React from 'react';
import Table2 from "../Table2";
import TableFilters from "../TableFilters";
import { useTranslation } from "react-i18next";

const UsersTable = ({data, filters, setFilters, fetchRecords, editAction, deleteAction}) => {
  const { t } = useTranslation();
  return (
    <Table2 filters={filters} setFilters={setFilters} action={fetchRecords}>
      <TableFilters filters={filters} setFilters={setFilters} action={fetchRecords} />
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
        <tr>
          <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
            {t('users.table.fullName')}
          </th>
          <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
            {t('users.table.email')}
          </th>
          <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
            {t('users.table.phoneNumber')}
          </th>
          <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider sm:hidden lg:table-cell">
            {t('users.table.whatsappNumber')}
          </th>
          <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider sm:hidden lg:table-cell">
            {t('users.table.createdDate')}
          </th>
          <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
            {t('users.table.actions')}
          </th>
        </tr>
        </thead>
        <tbody>
          {Object.keys(data).length > 0 ? (
            <React.Fragment>
              {Object.values(data).map((item, index) => (
                <tr
                  key={index}
                  className="bg-white"
                >
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                    <span>{item.firstname} {item.lastname}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                    <span>{item?.email}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                    <span>{item?.phone_number}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center sm:hidden lg:table-cell">
                    <span>{item?.whatsapp_number}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center sm:hidden lg:table-cell">
                    <span>{item?.created_at}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center sm:table-cell">
                    <span className="mx-2 cursor-pointer" onClick={() => editAction(item, fetchRecords)}>{t('default.edit')}</span>
                    <span className="mx-2 cursor-pointer" onClick={() => deleteAction(item, fetchRecords)}>{t('default.delete')}</span>
                  </td>
                </tr>
              ))}
            </React.Fragment>
            ): (
            <tr className="bg-white">
              <td colSpan="7" className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">{t('default.nothingToShow')}</td>
            </tr>
          )}
        </tbody>
      </table>
    </Table2>
  );
};

export default UsersTable;
