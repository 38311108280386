import React, { useEffect, useState } from 'react';
import { XIcon } from "@heroicons/react/solid";
import { useDispatch } from "react-redux";
import ClientRequest from "../../Services/ClientRequest";
import { toast } from "react-toastify";
import Counter from "../UI-Components/Counter";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import processServerResponse from "../../Services/Utils/ProcessServerResponse";

const AddBoxesModal = ({onCloseModal, order, specialRequest}) => {
  const { t } = useTranslation();
  const [boxes, setBoxes] = useState([]);
  const {register, handleSubmit, setError, formState: { errors } } = useForm();
  const [customSpecialRequest, setCustomSpecialRequest] = useState(specialRequest > 0);
  const [customSize, setCustomSize] = useState(specialRequest > 0);
  const [categories, setCategories] = useState([]);
  const dispatch = useDispatch();
  const orderCreated = () => toast.success(t('admin.boxes.modal.actions.created'));
  const request = new ClientRequest(dispatch);

  useEffect(() => {
    request.read(`boxes/${order.id}`)
      .then((res) => {
        const allBoxes = Object.values(res.data).map((box) => ({ ...box, count:0 }));
        setBoxes(allBoxes);
      })
      .catch((err) => console.log('fetch boxes error', err));
  },[]);

  useEffect(() => {
    request.read(`category-details`)
      .then((res) => {
        const {data: order} = res;
        setCategories(order);
      })
      .catch(() => console.log('error shipment'));
  },[]);

  const updateBoxCount = (id, value) => {
    const items = [...boxes];
    const index = Object.values(boxes).findIndex( el => el.id === id);
    const item = {...items[index], count: value};
    setBoxes(items);
  }

  const addCustomBox = (data) => {
    const payload = {
      ...data,
      name: data.name.slice(0, 18),
      'state_id': order.pickup_address.state_id,
      'custom_size' : order.id
    }

    const boxExist = boxes.find(el => el.height_in == data.height_in && el.width_in == data.width_in && el.length_in == data.length_in);

    if(boxExist){
      boxExist['count'] = boxExist['count'] + 1;
      setBoxes([...boxes]);
      setCustomSize(false);
    }else {
      request.create('boxes', payload)
        .then((res) => {
          const success = () =>{
            setCustomSize(false);
            const newBox = { ...res.data, count: 1 };
            setBoxes([...boxes, newBox]);
          }
          processServerResponse(res, setError, success, null);

        })
        .catch((err) => console.log(err));
    }
  }

  const onSubmit = () => {
    const selectedBoxes = Object.values(boxes).filter(el => el.count > 0).map(el => {
      return {
        box_id: el.id,
        count: el.count
      }
    });
    const payload = {
      shipment_id: order.id,
      boxes: selectedBoxes
    }
    request.create('shipment-details', payload)
      .then((res) => {
        processServerResponse(res, setError, orderCreated, onCloseModal);
      })
      .catch((err) => console.log(err));
  }

  const addUnknownSize = (data) => {
    const payload = {
      shipment_id: order.id,
     ...data
    }

    request.create('shipment-details', payload)
      .then((res) => {
        processServerResponse(res, setError, orderCreated, onCloseModal);
      })
      .catch((err) => console.log(err));
  }

  return (
    <div className="bg-white">
      <div
        className="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
      >
        <p className="font-semibold text-gray-800">{t('default.addBoxes')}</p>
        <XIcon className="w-6 h-6" onClick={() => onCloseModal()}/>
      </div>
      <div className="mb-2">
        <div className="flex flex-col px-6 py-5 bg-gray-50">
          {customSize ? (
            <div className="mx-3">
              {!customSpecialRequest && (
                <form onSubmit={handleSubmit(addCustomBox)}>
                  <div>
                    <p>{t('default.customBox')}</p>
                  </div>
                  <div className="grid grid-cols-5 gap-5 mt-3">
                    <input
                      className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                      type="text"
                      autoComplete="off"
                      placeholder={t('admin.boxes.table.name')}
                      {...register('name', { required: true })}
                    />
                    <input
                      className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                      type="text"
                      autoComplete="off"
                      placeholder={t('admin.boxes.table.height')}
                      {...register('height_in', { required: true })}
                    />
                    <input
                      className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                      type="text"
                      autoComplete="off"
                      placeholder={t('admin.boxes.table.width')}
                      {...register('width_in', { required: true })}
                    />
                    <input
                      className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                      type="text"
                      autoComplete="off"
                      placeholder={t('admin.boxes.table.length')}
                      {...register('length_in', { required: true })}
                    />
                    <button type='submit' className="px-4 py-2 text-white font-semibold bg-green-500 rounded">
                      {t('default.add')}
                    </button>
                  </div>
                  <div>
                    {errors.name && errors.name.message.split("|").map((error,index) => (
                      <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                        {error && t(`errors.${error}`)}
                      </span>
                    ))}
                  </div>
                </form>
              )}
              {customSpecialRequest && (
                <form id="customSpecialRequestForm" onSubmit={handleSubmit(addUnknownSize)}>
                  <div className="flex gap-5 mt-2 w-full">
                    <div className="grow block w-full">
                      <select
                        {...register('category_id', { required: true })}
                        value={specialRequest}
                        className="my-2 block w-full pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      >
                        {categories.map((item) => (
                          <option
                            key={item.name}
                            value={item.id}
                          >
                            {t(`default.categoriesList.${item.name}`)}
                          </option>
                        ))}
                      </select>
                      <textarea
                        placeholder={t('order.table.description')}
                        autoComplete="off"
                        {...register('custom_special_request', { required: true })}
                        className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md grow"
                      />
                    </div>
                  </div>
                  <p className="ml-1 mt-2 text-gray-500 text-sm">{t('order.modal.vehicle')}</p>
                  {errors.custom_special_request && errors.custom_special_request.message.split("|").map((error,index) => (
                    <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                      {error && t(`errors.${error}`)}
                    </span>
                  ))}
                </form>
              )}

            </div>
          ) : (
            <div className="flex flex-col sm:flex-col items-center sm:space-x-5">
              <div className='m-3 w-full'>
                <div>
                  {Object.keys(boxes).length > 0 && (
                    <div>
                      <p className="pb-4">{t('default.selectBoxes')}</p>
                      {errors.boxes && errors.boxes.message.split("|").map((error,index) => (
                        <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                          {error && t(`errors.${error}`)}
                        </span>
                      ))}
                      <div className='grid grid-cols-4 gap-14 mt-3'>
                        {Object.values(boxes).map(box => (
                          <Counter key={box.id} item={box} updateValue={updateBoxCount}/>
                        ))}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className="flex flex-row items-center justify-between p-5 border-t border-gray-200 bg-white  rounded-bl-lg rounded-br-lg"
      >
        <div>
          {!customSize ? (
            <button className="px-4 py-2 font-semibold" onClick={() => setCustomSize(true)}>
              {t('default.stillNotFoundTheRequiredsize')} <span className="text-blue-500 text-sm">{t('default.clickHere')}</span>
            </button>
          ) : (
            <div>
              {customSpecialRequest ? (
                <button className="px-4 py-2 font-semibold" onClick={() => onCloseModal()}>
                  {t('default.back')}
                </button>
              ): (
                <button className="px-4 py-2 font-semibold" onClick={() => setCustomSize(false)}>
                  {t('default.back')}
                </button>
              )}
            </div>
          )}
        </div>
        {!customSpecialRequest ? (
          <button
            type='submit'
            onClick={() => onSubmit()}
            className="px-4 py-2 text-white font-semibold bg-blue-500 rounded"
          >
            {t('default.save')}
          </button>
        ) : (
          <button type='submit' form="customSpecialRequestForm" className="px-4 py-2 h-12 text-white font-semibold bg-green-500 rounded">
            {t('default.add')}
          </button>
        )}
      </div>
    </div>
  );
};

export default AddBoxesModal;
