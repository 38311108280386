import React from 'react';
import { useForm } from "react-hook-form";
import {Dialog} from '@headlessui/react';
import {XIcon} from '@heroicons/react/outline';
import { useDispatch } from "react-redux";
import ClientRequest from "../../Services/ClientRequest";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import processServerResponse from "../../Services/Utils/ProcessServerResponse";
import Input from "react-phone-number-input/input";

const UsersModal = ({user, onCloseModal}) => {
  const { t } = useTranslation();
  const { register, handleSubmit, setError, setValue, formState: { errors } } = useForm({
    defaultValues: user
  });
  const dispatch = useDispatch();
  const request = new ClientRequest(dispatch);
  const userAdded = () => toast.success(t('users.modal.actions.created'));
  const userUpdated = () => toast.success(t('users.modal.actions.updated'));

  const onSubmit = payload => {
    if(user){
      request.update(`users/${user.id}`, payload)
        .then((res) => {
          processServerResponse(res, setError, userUpdated, onCloseModal);
        })
        .catch((err) => {
          console.log('user', err);
          onCloseModal();
        });
    }else{
      request.create('users', payload)
        .then((res) => {
          processServerResponse(res, setError, userAdded, onCloseModal);
        })
        .catch((err) => {
          console.log('user', err);
          onCloseModal();
        });
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
      <div className="flex-1 h-0 overflow-y-auto">
        <div className="py-6 px-4 bg-indigo-700 sm:px-6">
          <div className="flex items-center justify-between">
            <Dialog.Title className="text-lg font-medium text-white">{!user ? t('users.modal.titleNew') : t('users.modal.titleEdit')}</Dialog.Title>
            <div className="ml-3 h-7 flex items-center">
              <button
                type="button"
                className="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                onClick={() => onCloseModal()}
              >
                <span className="sr-only">Close panel</span>
                <XIcon className="h-6 w-6" aria-hidden="true"/>
              </button>
            </div>
          </div>
        </div>
        <div className="flex-1 flex flex-col justify-between">
          <div className="px-4 divide-y divide-gray-200 sm:px-6">
            <div className="space-y-6 pt-6 pb-5">
              <input
                type="hidden"
                {...register('id')}
                className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
              />
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-900">
                  {t('users.table.firstName')}
                </label>
                <div className="mt-1 space-y-1">
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder={t('users.table.firstName')}
                    {...register('firstname', { required: true })}
                    className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  />
                  {errors.firstname && errors.firstname.message.split("|").map((error,index) => (
                    <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                      {error && t(`errors.${error}`)}
                    </span>
                  ))}
                </div>
              </div>
              <div>
                <label htmlFor="lastname" className="block text-sm font-medium text-gray-900">
                  {t('users.table.lastName')}
                </label>
                <div className="mt-1 space-y-1">
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder={t('users.table.lastName')}
                    {...register('lastname', { required: true })}
                    className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  />
                  {errors.lastname && errors.lastname.message.split("|").map((error,index) => (
                    <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                      {error && t(`errors.${error}`)}
                    </span>
                  ))}
                </div>
              </div>
              <div>
                <label htmlFor="email" className="block text-sm font-medium text-gray-900">
                  {t('users.table.email')}
                </label>
                <div className="mt-1 space-y-1">
                  <input
                    type="email"
                    autoComplete="off"
                    placeholder={t('users.table.email')}
                    {...register('email')}
                    className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  />
                  {errors.email && errors.email.message.split("|").map((error,index) => (
                    <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                      {error && t(`errors.${error}`)}
                    </span>
                  ))}
                </div>
              </div>
              <div>
                <label htmlFor="phone_number" className="block text-sm font-medium text-gray-900">
                  {t('users.table.phoneNumber')}
                </label>
                <div className="mt-1 space-y-1">
                  <Input
                    {...register('phone_number', { required: true })}
                    placeholder={t('users.table.phoneNumber')}
                    value={user?.phone_number}
                    autoComplete="off"
                    onChange={(value) => setValue('phone_number', value)}
                    className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  />
                  {errors.phone_number && errors.phone_number.message.split("|").map((error,index) => (
                    <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                      {error && t(`errors.${error}`)}
                    </span>
                  ))}
                </div>
              </div>
              <div>
                <label htmlFor="whatsapp_number" className="block text-sm font-medium text-gray-900">
                  {t('users.table.whatsappNumber')}
                </label>
                <div className="mt-1 space-y-1">
                  <Input
                    {...register('whatsapp_number')}
                    placeholder={t('users.table.whatsappNumber')}
                    value={user?.whatsapp_number}
                    autoComplete="off"
                    onChange={(value) => setValue('whatsapp_number', value)}
                    className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  />
                  {errors.whatsapp_number && errors.whatsapp_number.message.split("|").map((error,index) => (
                    <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                      {error && t(`errors.${error}`)}
                    </span>
                  ))}
                </div>
              </div>
          </div>
        </div>
      </div>
      </div>
      <div className="flex-shrink-0 px-4 py-4 flex justify-end">
        <button
          type="button"
          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => onCloseModal()}
        >
          {t('default.cancel')}
        </button>
        <button
          type="submit"
          className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {t('default.save')}
        </button>
      </div>
    </form>
  );
};

export default UsersModal;
