import React from 'react';
import { useForm} from "react-hook-form";
import {Dialog} from '@headlessui/react';
import {XIcon} from '@heroicons/react/outline';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ClientRequest from "../../Services/ClientRequest";
import { useTranslation } from "react-i18next";
import processServerResponse from "../../Services/Utils/ProcessServerResponse";

const BoxDetailsModal = ({box, onCloseModal, canEdit}) => {
  const { t } = useTranslation();
  const { register, handleSubmit, setError, formState: { errors } } = useForm({
    defaultValues: box
  });
  const dispatch = useDispatch();
  const request = new ClientRequest(dispatch);
  const boxEdited = () => toast.success(t('admin.boxes.modal.actions.updated'));

  const onSubmit = payload => {
    if(canEdit){
      request.update(`shipment-details/${box.id}`, payload)
        .then((res) => {
          processServerResponse(res, setError, boxEdited, onCloseModal);
        })
        .catch((err) => {
          console.log('shipment-details', err);
          onCloseModal();
        });
    }
  }

  const style = canEdit ? 'focus:ring-indigo-500 focus:border-indigo-500 border-gray-300' : 'bg-gray-100 border-gray-300';

  return (
      <form onSubmit={handleSubmit(onSubmit)} className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
        <div className="flex-1 h-0 overflow-y-auto">
          <div className="py-6 px-4 bg-indigo-700 sm:px-6">
            <div className="flex items-center justify-between">
              <Dialog.Title className="text-lg font-medium text-white">{t('box.modal.title')}</Dialog.Title>
              <div className="ml-3 h-7 flex items-center">
                <button
                  type="button"
                  className="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                  onClick={() => onCloseModal()}
                >
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true"/>
                </button>
              </div>
            </div>
          </div>
          <div className="flex-1 flex flex-col justify-between">
            <div className="px-4 divide-y divide-gray-200 sm:px-6">
              <div className="space-y-6 pt-6 pb-5">
                <div>
                  <div className="py-1 space-y-1">
                    <label htmlFor="box_name" className="block text-sm font-medium text-gray-900">
                      {t('order.table.boxSelected')}
                    </label>
                    <input
                      type="text"
                      readOnly
                      defaultValue={box?.box?.name}
                      className="block w-full shadow-sm sm:text-sm bg-gray-100 border-gray-300 rounded-md"
                    />
                  </div>
                  <div className="py-2 space-y-1">
                    <label htmlFor="price_per_item" className="block text-sm font-medium text-gray-900">
                      {t('order.table.pricePerItem')}
                    </label>
                    <input
                      type="text"
                      disabled={!canEdit}
                      {...register('price_per_item', { required: true })}
                      className={`block w-full shadow-sm sm:text-sm rounded-md ${style}`}
                    />
                    {errors.price_per_item && errors.price_per_item.message.split("|").map((error,index) => (
                      <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                        {error && t(`errors.${error}`)}
                      </span>
                    ))}
                  </div>
                  <div className="py-2 space-y-1">
                    <label htmlFor="number_of_items" className="block text-sm font-medium text-gray-900">
                      {t('order.table.numberOfItems')}
                    </label>
                    <input
                      type="text"
                      disabled={!canEdit}
                      {...register('number_of_items', { required: true })}
                      className={`block w-full shadow-sm sm:text-sm rounded-md ${style}`}
                    />
                    {errors.number_of_items && errors.number_of_items.message.split("|").map((error,index) => (
                      <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                        {error && t(`errors.${error}`)}
                      </span>
                    ))}
                  </div>
                  <div className="py-2 space-y-1">
                    <label htmlFor="description" className="block text-sm font-medium text-gray-900">
                      {t('order.table.description')}
                    </label>
                    <textarea
                      className={`block w-full shadow-sm sm:text-sm rounded-md ${style}`}
                      rows={10}
                      disabled={!canEdit}
                      {...register('description', { required: true })}
                    />
                    {errors.description && errors.description.message.split("|").map((error,index) => (
                      <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                        {error && t(`errors.${error}`)}
                      </span>
                    ))}
                  </div>
                  <div className="py-2 space-y-1 flex">
                    <label className="text-gray-700">
                      <input
                        type="checkbox"
                        value={true}
                        disabled={!canEdit}
                        {...register('fragile')}
                      />
                      <span className='ml-1 mt-1'>{t('order.table.fragile')}</span>
                    </label>
                    {errors.fragile && errors.fragile.message.split("|").map((error,index) => (
                      <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                        {error && t(`errors.${error}`)}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-shrink-0 px-4 py-4 flex justify-end">
          <button
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => onCloseModal()}
          >
            {t('default.cancel')}
          </button>
          {canEdit && (
            <button
              type="submit"
              className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {t('default.save')}
            </button>
          )}
        </div>
      </form>
  );
};

export default BoxDetailsModal;
