import { combineReducers } from "redux";
import authReducer from './authReducer';
import menuReducer from './menuReducer';
import ordersReducer from "./ordersReducer";
import userReducer from "./userReducer";
import adminReducer from "./adminReducer";
import modalReducer from "./modalsReducer";
import usersReducer from "./usersReducer";

export default combineReducers({
  auth: authReducer,
  menu: menuReducer,
  orders: ordersReducer,
  user: userReducer,
  admin: adminReducer,
  modal: modalReducer,
  users: usersReducer,
});