import React, { useEffect, useState } from 'react';
import DropdownMenu from "../DropdownMenu";
import { ChevronDownIcon, DotsVerticalIcon } from "@heroicons/react/solid";
import { useDispatch } from "react-redux";
import ClientRequest from "../../../Services/ClientRequest";

const RouteSummary = ({order, onSubmitOrder}) => {
  const dispatch = useDispatch();
  const request = new ClientRequest(dispatch);
  const [statuses, setStatuses] = useState([]);

  useEffect(() => {
    request.read('shipment-status')
      .then((res) => {
        setStatuses(res.data);
      })
      .catch((err) => console.log(err));
  },[]);

  const changeOrderStatus = (id) => {
    const status = statuses.find(el => el.id === id);

    onSubmitOrder(status);
  }

  return (
    <div className="md:flex md:items-center md:justify-between md:space-x-4 xl:border-b xl:pb-6">
      <div>
        <h1 className="text-2xl font-bold text-gray-900 m-0 p-0">#Order {order.id}</h1>
      </div>
      <div className="mt-4 flex space-x-3 md:mt-0">
        <div className="flex grid-2 gap-2">
          <DropdownMenu items={statuses} action={changeOrderStatus}>
            <ChevronDownIcon className="h-5 w-5"/>
          </DropdownMenu>
        </div>
      </div>
    </div>
  );
};

export default RouteSummary;
