import axios from "axios";
import config from '../config.json';
import { loggedIn, loggedOut } from "../actions/Auth";
import { loadUserInfo } from "../actions/User";

const Headers = {
  'Content-type': 'application/x-www-form-urlencoded',
  'Accept': 'application/json'
};

export function login(data) {
  return axios.post(`${config.API_URL}/auth/login`, data, Headers);
}

export function registerUser(data) {
  return axios.post(`${config.API_URL}/auth/register`, data, Headers);
}

export function logout(){
  return (dispatch) => {
    const token = localStorage.getItem('accessToken');
    axios.post(`${config.API_URL}/auth/logout`, { token }, Headers);
    localStorage.removeItem('accessToken');
    dispatch(loggedOut());
  }
}

export function getCurrentUser(){
  return (dispatch) => {
    const token = localStorage.getItem('accessToken');
    if(token !== null){
      axios.post(`${config.API_URL}/auth/me`, { token }, Headers)
        .then((res) => {
          //update user information using the res
          dispatch(loadUserInfo(res.data))
          dispatch(loggedIn());
        }).catch(() => {
          dispatch(logout());
      });
    }
  }
}
