import React, { useEffect, useState } from 'react';

const UseOrderHasBoxes = (order) => {
  const [existBoxes, setExistBoxes] = useState(false);

  useEffect(() => {
    if(order && order.details.length > 0){
      setExistBoxes(true);
    }
  },[order]);

  return [existBoxes]
};

export default UseOrderHasBoxes;
