import React  from 'react';
import {geocodeByAddress, getLatLng} from 'react-places-autocomplete';
import { useForm } from "react-hook-form";
import { Dialog } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ClientRequest from "../../Services/ClientRequest";
import useCountry from "../../Services/CustomHooks/useCountry";
import useStates from "../../Services/CustomHooks/useStates";
import processServerResponse from "../../Services/Utils/ProcessServerResponse";
import { useTranslation } from "react-i18next";
import Input from "react-phone-number-input/input";
import axios from "axios";

const RouteAddressModal = ({address, onCloseModal}) => {
  const { t } = useTranslation();
  const { register, handleSubmit, setError, watch, setValue, formState: { errors } } = useForm({
    defaultValues: address
  });
  const [countries] = useCountry();
  const [states, setStates] = useStates();
  const dispatch = useDispatch();
  const request = new ClientRequest(dispatch);
  const addressAdded = () => toast.success("Address Added");
  const addressUpdated = () => toast.success("Address Updated");

  const stateId = watch("state_id");
  const address1 = watch("address_1");
  const zipcode = watch("zipcode");

  if(stateId && address1 && zipcode){
    const stateMap = Object.values(states).find(el => el.id == stateId);
    if(stateMap){
      const config = {
        url: `https://open.mapquestapi.com/geocoding/v1/address?key=FpwoyPASFPeMVnz87PdAu0EFmZyna8zr&location=${stateMap.name} ${address1}, ${zipcode}`,
        method: 'get'
      }

      axios.request(config)
        .then(response => {
          const { data: {results} } = response;
          console.log(results);
          if(results){
            const location = results[0].locations[0];
            if(location){
              const {latLng} = location;
              if(latLng){
                if(latLng.lat != "39.78373"){
                  setValue("latitude", latLng.lat);
                }
                if(latLng.lng != "-100.445882"){
                  setValue("longitude", latLng.lng);
                }
              }
            }
          }
        })
        .catch(error => {
          console.log('error', error);
        })
    }
  }

  const onSubmit = payload => {
    if(address){
      request.update(`route-addresses/${address.id}`, payload)
        .then((res) => {
          processServerResponse(res, setError,addressUpdated, onCloseModal);
        })
        .catch((err) => {
          console.log('address', err);
          onCloseModal();
        });
    }else{
      request.create('route-addresses', payload)
        .then((res) => {
          processServerResponse(res, setError,addressAdded, onCloseModal);
        })
        .catch((err) => {
          console.log('address', err);
          onCloseModal();
        });
    }
  }

  const onHandleChangeCountry = (id) => {
    request.read(`states/${id}`)
      .then((res) => {
        setStates(res.data);
      }).catch((err) => console.log(err));
  }

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const { lat, lng } = await getLatLng(results[0]);

    const { short_name: postalCode } = results[0].address_components.find((el) => el.types.includes("postal_code")) || {};
    const { short_name: country } = results[0].address_components.find((el) => el.types.includes("country")) || {};
    const { short_name: state } = results[0].address_components.find((el) => el.types.includes("locality")) || {};
    const { long_name: address_1 } = results[0].address_components.find((el) => el.types.includes("administrative_area_level_1")) || {};
    const { long_name: address_2 } = results[0].address_components.find((el) => el.types.includes("administrative_area_level_2")) || {};
    const selectedCountry = countries.find((el) => el.name === country) || 0;
    const selectedState = states.find((el) => el.name === state) || 0;

    setValue('latitude', lat);
    setValue('longitude', lng);
    setValue('zipcode', postalCode);
    setValue('country_id', selectedCountry.id);
    setValue('state_id', selectedState.id);
    setValue('address_1', address_1);
    setValue('address_2', address_2);
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
      <div className="flex-1 h-0 overflow-y-auto">
        <div className="py-6 px-4 bg-indigo-700 sm:px-6">
          <div className="flex items-center justify-between">
            <Dialog.Title className="text-lg font-medium text-white">{!address ? t('admin.routeAddress.titleNew') : t('admin.routeAddress.titleEdit')}</Dialog.Title>
            <div className="ml-3 h-7 flex items-center">
              <button
                type="button"
                className="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                onClick={() => onCloseModal()}
              >
                <span className="sr-only">Close panel</span>
                <XIcon className="h-6 w-6" aria-hidden="true"/>
              </button>
            </div>
          </div>
          <div className="mt-1">
            <p className="text-sm text-indigo-300">
              {t('admin.routeAddress.description')}
            </p>
          </div>
        </div>
        <div className="flex-1 flex flex-col justify-between">
          <div className="px-4 divide-y divide-gray-200 sm:px-6">
            <div className="space-y-6 pt-6 pb-5">
              <div>
                <label htmlFor="country_id" className="block text-sm font-medium text-gray-900">
                  {t('admin.addresses.table.country')}
                </label>
                <div className="mt-1 space-y-1">
                  <select
                    name="country_id"
                    {...register('country_id', { required: true })}
                    onChange={(e) => onHandleChangeCountry(e.target.value)}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    <option value="">{t('default.selectCountry')}</option>
                    {Object.values(countries).map((country) => (
                      <option key={country.id} value={country.id} selected={address && address.country_id === country.id ? "selected" : ""}>
                        {country.name}
                      </option>
                    ))}
                  </select>
                  {errors.country_id && <span className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>{errors.country_id.message}</span>}
                </div>
              </div>
              <div>
                <label htmlFor="state_id" className="block text-sm font-medium text-gray-900">
                  {t('admin.addresses.table.state')}
                </label>
                <div className="mt-1 space-y-1">
                  <select
                    name="state_id"
                    {...register('state_id', { required: true })}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    <option value="">{t('default.selectState')}</option>
                    {Object.values(states).map((state) => (
                      <option key={state.id} value={state.id} selected={address && address.state_id === state.id ? "selected" : ""}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                  {errors.state_id && <span className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>{errors.state_id.message}</span>}
                </div>
              </div>
              <div>
                <label htmlFor="address_1" className="block text-sm font-medium text-gray-900">
                  {t('default.address')}
                </label>
                <div className="mt-1 space-y-1">
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder={t('default.address1')}
                    {...register('address_1', { required: true })}
                    className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  />
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder={t('default.address2')}
                    {...register('address_2')}
                    className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  />
                  {errors.address_1 && (
                    <span className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>{errors.address_1.message}</span>
                  )}
                  {errors.address_2 && (
                    <span className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>{errors.address_2.message}</span>
                  )}
                </div>
                <div className="py-2 space-y-1">
                  <label htmlFor="zipcode" className="block text-sm font-medium text-gray-900">
                    {t('default.zipCode')}
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    {...register('zipcode', { required: true })}
                    className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  />
                  {errors.zipcode && (
                    <span className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>{errors.zipcode.message}</span>
                  )}
                </div>
                <div className="py-2 space-y-1">
                  <label htmlFor="address_link" className="block text-sm font-medium text-gray-900">
                    {t('default.addressLink')}
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    {...register('address_link')}
                    className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  />
                  {errors.address_link && (
                    <span className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>{errors.address_link.message}</span>
                  )}
                </div>
                <div className="py-2 space-y-1">
                  <label htmlFor="latitude" className="block text-sm font-medium text-gray-900">
                    {t('default.latitude')}
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    {...register('latitude', { required: true })}
                    className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  />
                  {errors.latitude && (
                    <span className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>{errors.latitude.message}</span>
                  )}
                </div>
                <div className="py-2 space-y-1">
                  <label htmlFor="longitude" className="block text-sm font-medium text-gray-900">
                    {t('default.longitude')}
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    {...register('longitude', { required: true })}
                    className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  />
                  {errors.longitude && (
                    <span className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>{errors.longitude.message}</span>
                  )}
                </div>
                <div className="py-2 space-y-1">
                  <label htmlFor="contact_name" className="block text-sm font-medium text-gray-900">
                    {t('default.contactInfo')}
                  </label>
                  <input
                    type="text"
                    autoComplete="off"
                    placeholder={t('users.table.fullName')}
                    {...register('contact_name', { required: true })}
                    className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  />
                  <Input
                    {...register('contact_number')}
                    placeholder={t('users.table.phoneNumber')}
                    value={address?.contact_number}
                    autoComplete="off"
                    onChange={(value) => setValue('contact_number', value)}
                    className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  />
                  {errors.contact_number && (
                    <span className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>{errors.contact_number.message}</span>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex-shrink-0 px-4 py-4 flex justify-end">
        <button
          type="button"
          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => onCloseModal()}
        >
          {t('default.cancel')}
        </button>
        <button
          type="submit"
          className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {t('default.save')}
        </button>
      </div>
    </form>
  );
};

export default RouteAddressModal;
