export const LOAD_COUNTRIES = 'LOAD_COUNTRIES';
export const LOAD_STATES = 'LOAD_STATES';
export const LOAD_SHIPMENT_STATUS = 'LOAD_SHIPMENT_STATUS';

export const LOAD_DRIVERS = 'LOAD_DRIVERS';
export const EDIT_DRIVERS = 'EDIT_DRIVERS';
export const ADD_DRIVERS = 'ADD_DRIVERS';
export const DELETE_DRIVERS = 'DELETE_DRIVERS';

export const LOAD_TRUCKS = 'LOAD_TRUCKS';
export const EDIT_TRUCK = 'EDIT_TRUCK';
export const ADD_TRUCK = 'ADD_TRUCK';
export const DELETE_TRUCK = 'DELETE_TRUCK';

export const LOAD_BOXES = 'LOAD_BOXES';
export const EDIT_BOX = 'EDIT_BOX';
export const ADD_BOX = 'ADD_BOX';
export const DELETE_BOX = 'DELETE_BOX';

/* ShipmentStatus */
export const loadShipmentStatus = (data) => {
  return {
    type: LOAD_SHIPMENT_STATUS,
    value: data
  }
}

/* States */
export const loadStates = (data) => {
  return {
    type: LOAD_STATES,
    value: data
  }
}

/* Countries */
export const loadCountries = (data) => {
  return {
    type: LOAD_COUNTRIES,
    value: data
  }
}

/* Drivers */
export const loadDrivers = (data) => {
  return {
    type: LOAD_DRIVERS,
    value: data
  }
}

export const editDriver = (data) => {
  return {
    type: EDIT_DRIVERS,
    value: data,
    id: data.id
  }
}

export const addDriver = (data) => {
  return {
    type: ADD_DRIVERS,
    value: data
  }
}

export const deleteDriver = (id) => {
  return {
    type: DELETE_DRIVERS,
    id
  }
}

/* Trucks */
export const loadTrucks = (data) => {
  return {
    type: LOAD_TRUCKS,
    value: data
  }
}

export const editTruck = (data) => {
  return {
    type: EDIT_TRUCK,
    value: data,
    id: data.id
  }
}

export const addTruck = (data) => {
  return {
    type: ADD_TRUCK,
    value: data
  }
}

export const deleteTruck = (id) => {
  return {
    type: DELETE_TRUCK,
    id
  }
}

export const editBoxes = (data) => {
  return {
    type: EDIT_BOX,
    value: data,
    id: data.id
  }
}

export const addBox = (data) => {
  return {
    type: ADD_BOX,
    value: data
  }
}
