import React from 'react';
import {Link} from "react-router-dom";
import { LogoutIcon } from "@heroicons/react/outline";
import { useTranslation } from "react-i18next";
import Multilanguage from "./Multilanguage";

const Sidebar = ({user, updateMenu, classNames, navigation, shipmentStatus, logout, canISee}) => {
  const { t } = useTranslation();
  return (
    <div className="hidden lg:flex lg:flex-shrink-0">
      <div className="flex flex-col w-64 border-r border-gray-200 pt-5 pb-4 bg-gray-100">
        <div className="flex items-center flex-shrink-0 px-6">
          <img
            style={{marginLeft: "auto", marginRight: "auto", display: "block"}}
            className="w-12"
            src="/logo-global2.png"
            alt="Workflow"
          />
        </div>
        <div className="h-0 flex-1 flex flex-col overflow-y-auto">
          <div className="px-3 mt-6 relative inline-block text-left">
            <button
              className="group w-full bg-gray-100 rounded-md px-3.5 py-2 text-sm text-left font-medium text-gray-700 hover:bg-gray-200 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-purple-500"
            >
              <Link to="/profile/info">
                <span className="flex w-full justify-between items-center">
                  <span className="flex min-w-0 items-center justify-between space-x-3">
                    {user.avatar && (
                      <img
                        className="w-10 h-10 bg-gray-300 rounded-full flex-shrink-0"
                        src="https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=3&w=256&h=256&q=80"
                        alt=""
                      />
                    )}
                    <span className="flex-1 flex flex-col min-w-0">
                      <span className="text-gray-900 text-sm font-medium truncate">{user.firstname} {user.lastname}</span>
                      <span className="text-gray-500 text-sm truncate">{user.email}</span>
                    </span>
                  </span>
                </span>
              </Link>
            </button>
          </div>
          <nav className="px-3 mt-6">
            <div className="space-y-1">
              {Object.values(navigation).map((item, index) => (
                <div key={index}>
                  {(!item.restricted || (item.restricted && canISee)) && (
                    <Link
                      key={item.name}
                      to={item.href}
                      onClick={() => updateMenu(index)}
                      className={classNames(
                        item.current ? 'bg-gray-200 text-gray-900' : 'text-gray-700 hover:text-gray-900 hover:bg-gray-50',
                        'group flex items-center px-2 py-2 text-sm font-medium rounded-md'
                      )}
                      aria-current={item.current ? 'page' : undefined}
                    >
                      <item.icon
                        className={classNames(
                          item.current ? 'text-gray-500' : 'text-gray-400 group-hover:text-gray-500',
                          'mr-3 h-6 w-6'
                        )}
                        aria-hidden="true"
                      />
                      {t(`menu.${item.name}`)}
                    </Link>
                  )}
                </div>
              ))}
              <Link
                className={classNames('text-gray-700 hover:text-gray-900 hover:bg-gray-50', 'group flex items-center px-2 py-2 text-sm font-medium rounded-md')}
                onClick={(e) => logout()}
                to='#'
              >
                <LogoutIcon
                  className={classNames('text-gray-400 group-hover:text-gray-500', 'mr-3 h-6 w-6')}
                  aria-hidden="true"
                />
                {t('menu.logout')}
              </Link>
            </div>
            {/*<div className="mt-8">
              <h3 className="px-3 text-xs font-semibold text-gray-500 uppercase tracking-wider"
                  id="status-headline">
                Orders
              </h3>
              <div className="mt-1 space-y-1" role="group" aria-labelledby="status-headline">
                {shipmentStatus.map((status) => (
                  <Link
                    key={status.name}
                    to={`/?page=1&status=${status.id}`}
                    className="group flex items-center px-3 py-2 text-sm font-medium text-gray-700 rounded-md hover:text-gray-900 hover:bg-gray-50"
                  >
                    <span
                      className={classNames(status['bg-color'], 'w-2.5 h-2.5 mr-4 rounded-full')}
                      aria-hidden="true"
                    />
                    <span className="truncate">{status.name}</span>
                  </Link>
                ))}
              </div>
            </div>*/}
          </nav>
        </div>
        <div className='px-6'>
          <ul>
            <li href="#" className="text-gray-600 hover:bg-gray-50 hover:text-gray-900 flex items-center px-3 py-2 text-sm font-medium rounded-md cursor-pointer">
              <Multilanguage />
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Sidebar;
