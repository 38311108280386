import {
  ADD_BOX,
  ADD_DRIVERS,
  ADD_NEW_QUOTE, ADD_TRUCK, DELETE_BOX, DELETE_DRIVERS, DELETE_TRUCK, EDIT_BOX,
  EDIT_DRIVERS,
  EDIT_QUOTE, EDIT_TRUCK, LOAD_BOXES,
  LOAD_COUNTRIES,
  LOAD_DRIVERS, LOAD_SHIPMENT_STATUS, LOAD_STATES,
  LOAD_TRUCKS
} from "../actions/Admin";

const initialState = {
  boxes: [],
  drivers: [],
  trucks: [],
  countries: [],
  states: [],
  shipmentStatus: [],
  routeGenerator:[],
  language: [],
  settings: [],
};

export default function(state = initialState, action){
  switch (action.type) {
    case LOAD_SHIPMENT_STATUS: {
      return {
        ...state,
        shipmentStatus: action.value
      }
    }
    case LOAD_STATES: {
      return {
        ...state,
        states: action.value
      }
    }
    case LOAD_COUNTRIES: {
      return {
        ...state,
        countries: action.value
      }
    }
    case LOAD_DRIVERS: {
      return {
        ...state,
        drivers: action.value
      }
    }
    case EDIT_DRIVERS: {
      const drivers = [...state.drivers];
      const index = drivers.findIndex(obj => obj.id === action.id);
      drivers[index] = {...action.value};

      return {
        ...state,
        drivers
      }
    }
    case ADD_DRIVERS: {
      return {
        ...state,
        drivers: [
          ...state.drivers,
          action.value,
        ]
      }
    }
    case DELETE_DRIVERS:{
      const drivers = [...state.drivers];
      const index = drivers.findIndex(obj => obj.id === action.id);
      drivers.splice(index, 1);

      return {
        ...state,
        drivers
      }
    }
    case LOAD_TRUCKS: {
      return {
        ...state,
        trucks: action.value
      }
    }
    case EDIT_TRUCK: {
      const trucks = [...state.trucks];
      const index = trucks.findIndex(obj => obj.id === action.id);
      trucks[index] = {...action.value};

      return {
        ...state,
        trucks
      }
    }
    case ADD_TRUCK: {
      return {
        ...state,
        trucks: [
          ...state.trucks,
          action.value,
        ]
      }
    }
    case DELETE_TRUCK:{
      const trucks = [...state.trucks];
      const index = trucks.findIndex(obj => obj.id === action.id);
      trucks.splice(index, 1);

      return {
        ...state,
        trucks
      }
    }
    case LOAD_BOXES: {
      return {
        ...state,
        boxes: action.value
      }
    }
    case EDIT_BOX: {
      const boxes = [...state.boxes];
      const index = boxes.findIndex(obj => obj.id === action.id);
      boxes[index] = {...action.value};

      return {
        ...state,
        boxes
      }
    }
    case ADD_BOX: {
      return {
        ...state,
        boxes: [
          ...state.boxes,
          action.value,
        ]
      }
    }
    case DELETE_BOX:{
      const boxes = [...state.boxes];
      const index = boxes.findIndex(obj => obj.id === action.id);
      boxes.splice(index, 1);

      return {
        ...state,
        boxes
      }
    }
    default: {
      return {...state};
    }
  }
}
