const processServerResponse = (response, setError, callback, close) => {
  const { success, data } = response;
  if(!success){
    //set errors
    const errors = data;
    Object.entries(errors).map(error => {
      setError(error[0], {
        type: "manual",
        message: error[1].join("|")
      });
    });
  }else{
    //updated correctly
    callback();
    close();
  }
}

export default processServerResponse;
