import React, { useState } from 'react';
import AuthLayout from "../components/Auth/AuthLayout";
import { useTranslation } from "react-i18next";
import { useForm } from "react-hook-form";
import SubmitButton from "../components/Auth/submitButton";
import { useDispatch } from "react-redux";
import ClientRequest from "../Services/ClientRequest";
import { Link } from "react-router-dom";
import Input from "react-phone-number-input/input";

const ForgotPassword = () => {
  const { t } = useTranslation();
  const { register, handleSubmit , setError, formState: { errors }} = useForm();
  const [submitted, setSubmitted] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [type, setType] = useState("email");
  const dispatch = useDispatch();
  const request = new ClientRequest(dispatch);

  const onSubmit = (data) => {
    setServerError(false);
    request.create('auth/forgot-password', data)
      .then((res) => {
        const { success, data } = res;
        if(!success){
          //set errors
          const errors = data;
          Object.entries(errors).map(error => {
            setError(error[0], {
              type: "manual",
              message: error[1].join("|")
            });
          });
        }else{
          //updated correctly
          setSubmitted(true);
          console.log("submitted");
        }
      })
      .catch((err) => {
        setSubmitted(true);
        setServerError(true);
        console.log('forgot-password', err);
      });
  }

  return (
    <AuthLayout title={t('forgotPassword.title')} displayBrand={true}>
      <form className="mt-2 space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <div className="flex justify-center">
          <div className="form-check form-check-inline mr-3">
            <input
              className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              type="radio"
              value="email"
              id="radio-email"
              onChange={() => setType("email")}
              checked={type === "email"}
              />
            <label className="form-check-label inline-block text-gray-800" htmlFor="radio-email">Email</label>
          </div>
          <div className="form-check form-check-inline">
            <input
              className="form-check-input form-check-input appearance-none rounded-full h-4 w-4 border border-gray-300 bg-white checked:bg-blue-600 checked:border-blue-600 focus:outline-none transition duration-200 mt-1 align-top bg-no-repeat bg-center bg-contain float-left mr-2 cursor-pointer"
              type="radio"
              value="phone"
              id="radio-phone"
              onChange={() => setType("phone")}
              checked={type === "phone"}
            />
            <label className="form-check-label inline-block text-gray-800" htmlFor="radio-phone">Phone Number</label>
          </div>
        </div>
        <div className="rounded-md shadow-sm space-y-3">
          <div>
            {type === "email" ? (
              <input
                type="text"
                autoComplete="off"
                {...register('email', { required: true })}
                className="appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm"
                placeholder={`${t('default.emailAddress')}`}
              />
            ) : (
              <Input
                {...register('email', { required: true })}
                placeholder={t('users.table.phoneNumber')}
                onChange={(value) => console.log(value)}
                autoComplete="off"
                className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
              />
            )}

            {errors.email && errors.email.message.split("|").map((error,index) => (
              <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                {t(`errors.${error}`)}
              </span>
            ))}

          </div>
        </div>
        <div>
          {submitted ? (
            <div>
              { serverError ? (
                <div>
                  <span className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1 mb-3'>
                    {t('errors.SomethingHappened')}
                  </span>
                  <SubmitButton text={t('forgotPassword.action')} />
                </div>
                ): (
                <span className='flex items-center font-medium tracking-wide text-green-500 text-xs mt-1 ml-1'>
                  {t('forgotPassword.success')}
                </span>
              ) }
            </div>
          ): (
            <SubmitButton text={t('forgotPassword.action')} />
          )}
          <div className="text-sm mt-5 flex justify-center">
            <Link to='/' className="font-medium text-indigo-600 hover:text-indigo-500">
              {t('default.back')}
            </Link>
          </div>
        </div>
      </form>
    </AuthLayout>
  );
};

export default ForgotPassword;
