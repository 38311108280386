import React from 'react';
import PageHeaderSection from "../../UI-Components/PageHeaderSection";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";
import ClientRequest from "../../../Services/ClientRequest";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";

const Index = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const request = new ClientRequest(dispatch);
  const { register, handleSubmit, setError, reset, formState: { errors } } = useForm();
  const passwordUpdated = () => toast.success(t('passwordReset.updated'));

  const onSubmit = data => {
    request.create(`password-reset`, data)
      .then(res => {
        const { success, data } = res;
        if(!success){
          //set errors
          const errors = data;
          Object.entries(errors).map(error => {
            setError(error[0], {
              type: "manual",
              message: error[1].join('|')
            });
          });
        }else{
          //updated correctly
          passwordUpdated();
          reset();
        }
      })
      .catch((error) => console.log('request could not be made', error));
  }

  return (
    <div>
      <PageHeaderSection title={t('passwordReset.title')} />
      <div>
        <form onSubmit={handleSubmit(onSubmit)} className="h-full flex flex-col bg-white">
        <div className="flex-1 flex flex-col justify-between">
          <div className="divide-y divide-gray-200">
            <div className="space-y-6 pt-6 pb-5">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-900">
                  {t('passwordReset.password')}
                </label>
                <div className="mt-1 space-y-1">
                  <input
                    type="password"
                    {...register('password', { required: true })}
                    className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  />
                  {errors.password && errors.password.message.split("|").map((error, index) => (
                    <span key={index} className='flex font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                      {t(`passwordReset.errors.${error}`)}
                    </span>
                  ))}
                </div>
              </div>
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-900">
                  {t('passwordReset.confirm')}
                </label>
                <div className="mt-1 space-y-1">
                  <input
                    type="password"
                    {...register('password_confirmation', { required: true })}
                    className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  />
                  {errors.password_confirmation && errors.password_confirmation.message.split("|").map(error => (
                    <span className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                      {t(`passwordReset.errors.${error}`)}
                    </span>
                  ))}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="py-3 text-right">
          <button
            type="submit"
            className="inline-flex justify-center py-2 px-4 text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {t('default.update')}
          </button>
        </div>
      </form>
      </div>
    </div>
  );
};

export default Index;
