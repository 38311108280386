import { useEffect, useState } from 'react';

const UseOrderCancelled = (order) => {
  const [isOrderCancelled, setIsOrderCancelled] = useState(false);

  useEffect(() => {
    if(order){
      const orderCancelled = order.status.id === 2;// Cancelled
      setIsOrderCancelled(orderCancelled);
    }
  },[order]);

  return [isOrderCancelled, setIsOrderCancelled];
};

export default UseOrderCancelled;
