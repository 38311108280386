import React from 'react';
import {Route, Redirect} from 'react-router-dom';
import AppLayout from "../components/AppLayout";
import { ToastContainer } from "react-toastify";

const ProtectedRoute = ({isAuth, component: Component, ...rest}) => {
  return (
    <Route {...rest} render={
      (props) => {
        if (isAuth) {
          return (
            <AppLayout>
              <Component { ...props } />
              <ToastContainer autoClose={5000} hideProgressBar={false} closeOnClick position="bottom-right"/>
            </AppLayout>
          );
        } else {
          return <Redirect to={
            {
              pathname: '/login',
              state: {from: props.location}
            }
          }
          />
        }
      }
    }
    />
  );
};

export default ProtectedRoute;
