import React from 'react';
import { useForm } from "react-hook-form";
import PageHeaderSection from "../../UI-Components/PageHeaderSection";
import { CheckCircleIcon } from "@heroicons/react/solid";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { connect, useDispatch } from "react-redux";
import { updateUserInfo } from "../../../actions/User";
import ClientRequest from "../../../Services/ClientRequest";
import { useTranslation } from "react-i18next";
import Input from "react-phone-number-input/input";

const Index = ({user}) => {
  const { t } = useTranslation();
  const { register, handleSubmit, setError, setValue, formState: { errors } } = useForm({
    defaultValues: user
  });
  const userUpdated = () => toast.success("Information Updated");
  const dispatch = useDispatch();
  const request = new ClientRequest(dispatch);

  const onSubmit = data => {
    request.update(`users/${user.id}`, data)
    .then(res => {
      const { success, data } = res;
      if(!success){
        //set errors
        const errors = data;
        Object.entries(errors).map(error => {
          setError(error[0], {
            type: "manual",
            message: error[1]
          });
        });
      }else{
        //updated correctly
        dispatch(updateUserInfo(data));
        userUpdated();
      }
    })
    .catch((error) => console.log('request could not be made', error));
  }

  return (
    <div>
      <PageHeaderSection title={t('admin.info.title')} description={t('admin.info.description')}/>
      <div className="pt-1">
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="mt-6 grid grid-cols-1 gap-y-6 gap-x-4 sm:grid-cols-8">
            <div className="sm:col-span-4">
              <input
                type="hidden"
                {...register('id')}
                className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
              />
              <label htmlFor="first_name" className="block text-sm font-medium text-gray-700">
                {t('default.firstName')}
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  autoComplete="off"
                  {...register('firstname', { required: true })}
                  className={`${errors.firstname ? 'border-red-300 text-red-900': ''} shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md`}
                />
                {errors.firstname && <span className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>{errors.firstname.message}</span>}
              </div>
            </div>
            <div className="sm:col-span-4">
              <label htmlFor="last_name" className="block text-sm font-medium text-gray-700">
                {t('default.lastName')}
              </label>
              <div className="mt-1">
                <input
                  type="text"
                  autoComplete="off"
                  {...register('lastname', { required: true })}
                  className={`${errors.lastname ? 'border-red-300 text-red-900': ''} shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md`}
                />
                {errors.lastname && <span className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>{errors.lastname.message}</span>}
              </div>
            </div>
            <div className="sm:col-span-8">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                <span>{t('default.emailAddress')}</span>
                {user.verified_at && <CheckCircleIcon className="inline-block h-4 w-4 text-blue-500"/>}
              </label>
              <div className="mt-1">
                <input
                  type="email"
                  autoComplete="off"
                  {...register('email', { required: true })}
                  className={`${errors.email ? 'border-red-300 text-red-900': ''} shadow-sm focus:ring-indigo-500 focus:border-indigo-500 block w-full sm:text-sm border-gray-300 rounded-md`}
                />
                {errors.email && <span className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>{errors.email.message}</span>}
              </div>
            </div>
            <div className="sm:col-span-4">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                {t('default.phoneNumber')}
              </label>
              <div className="mt-1">
                <Input
                  {...register('phone_number', { required: true })}
                  placeholder={t('users.table.phoneNumber')}
                  value={user?.phone_number}
                  autoComplete="off"
                  onChange={(value) => setValue('phone_number', value)}
                  className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                />
                {errors.phone_number && <span className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>{errors.phone_number.message}</span>}
              </div>
            </div>
            <div className="sm:col-span-4">
              <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                {t('default.whatsappNumber')}
              </label>
              <div className="mt-1">
                <Input
                  {...register('whatsapp_number', { required: true })}
                  placeholder={t('users.table.whatsappNumber')}
                  value={user?.whatsapp_number}
                  autoComplete="off"
                  onChange={(value) => setValue('whatsapp_number', value)}
                  className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                />
                {errors.whatsapp_number && <span className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>{errors.whatsapp_number.message}</span>}
              </div>
            </div>
          </div>
          <div className="py-3 text-right">
            <button
              type="submit"
              className="inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            >
              {t('default.update')}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};


const mapStateToProps = state => ({
  user: state.user.info
});

export default connect(mapStateToProps, null)(Index);
