import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react'
import { closeModal } from "../../actions/Modal";
import { connect } from "react-redux";
import OrderModal from "./OrderModal";
import AddBoxesModal from "./AddBoxesModal";
import RouteGeneratorModal from "./RouteGeneratorModal";
import AddBoxContentDetails from "./AddBoxContentDetails";

const MODAL_ORDERS = 5;
const MODAL_ADD_BOXES = 6;
const MODAL_BOX_DETAILS = 8;
const MODAL_ROUTE_GENERATOR = 9;
const ModalsArray = [5,6,8,9];

const Modals = ({closeModal, activeModal, options}) => {
  const open = (ModalsArray.includes(activeModal));

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" static className="z-10 fixed inset-0 overflow-hidden" open={open} onClose={()=> closeModal()}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-x-0 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-y-full"
              enterTo="translate-y-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-y-0"
              leaveTo="-translate-y-full"
            >
              <div
                className="flex flex-col my-10 w-9/12 sm:w-11/12 lg:w-11/12 xl:w-9/12 mx-auto rounded-lg border border-gray-300 shadow-xl">
                {activeModal === MODAL_ORDERS &&
                  <OrderModal selecteduser={options && options.selectedUser} onCloseModal={closeModal} />
                }
                {activeModal === MODAL_ADD_BOXES &&
                  <AddBoxesModal onCloseModal={closeModal} order={options && options.order} specialRequest={options.specialRequest}/>
                }
                {activeModal === MODAL_ROUTE_GENERATOR &&
                  <RouteGeneratorModal onCloseModal={closeModal} />
                }
                {activeModal === MODAL_BOX_DETAILS &&
                  <AddBoxContentDetails onCloseModal={closeModal} details={options && options.box} />
                }
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>

  );
};

const stateToProps = state => (
  {
    activeModal: state.modal.activeModal,
    options: state.modal.options,
    user: state.user.info
  }
);

const dispatchToProps = dispatch => (
  {
    closeModal: () => dispatch(closeModal()),
  }
);

export default connect(stateToProps, dispatchToProps)(Modals);

