import { useEffect, useState } from 'react';

const useIsOrderInWareHouse = (order) => {
  const [inWareHouse, setInWareHouse] = useState(true);

  useEffect(() => {
    if(order){
      const warehouse = order.status.id >= 8 && order.status.id !== 2; //Greater than Reviewed
      setInWareHouse(warehouse);
    }
  },[order]);

  return [inWareHouse];
};

export default useIsOrderInWareHouse;
