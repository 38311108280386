import React, { useEffect } from 'react';
import { ChevronDownIcon, DotsVerticalIcon, PlusIcon } from "@heroicons/react/solid";
import EmptyStates from "../EmptyStates";
import OrdersDetailTable from "../OrdersDetails/OrdersDetailTable";
import { useTranslation } from "react-i18next";
import useUserCanEdit from "../../../Services/CustomHooks/useUserCanEdit";
import useOrderCancelled from "../../../Services/CustomHooks/useOrderCancelled";
import DropdownMenu from "../DropdownMenu";

const OrderBoxes = ({order, isAdmin, onOpenAddBoxesModal, forceUpdate, onDeleteBox, onOpenBoxDetails, categories}) => {
  const { t } = useTranslation();
  const [userCanEdit] = useUserCanEdit(order);
  const [isOrderCancelled] = useOrderCancelled(order);

  const selectCategory = (id) => {
    onOpenAddBoxesModal(order, forceUpdate, id);
  }

  return (
    <section aria-labelledby="activity-title" className="mt-2">
      <div>
        <div className="divide-y divide-gray-200">
          <div className="pb-4">
            <div className="flex w-full justify-between">
              <div>
                <h2 id="activity-title" className="text-lg font-medium text-gray-900">{t('default.details')}</h2>
              </div>
              {((!isOrderCancelled) && (isAdmin || userCanEdit)) && (
                <div className="flex grid-cols-2 gap-2">
                  <div>
                    <DropdownMenu
                      className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700"
                      items={categories}
                      action={selectCategory}
                      type="cat"
                    >
                      <div>
                        <span className="inline-block">{t('default.categories')}</span>
                        <ChevronDownIcon className="h-5 w-5 inline-block"/>
                      </div>
                    </DropdownMenu>
                  </div>
                  <div>
                  <button
                    onClick={() => onOpenAddBoxesModal(order, forceUpdate)}
                    type="button"
                    className="inline-flex justify-center px-4 py-2 border border-gray-300 shadow-sm text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-700"
                  >
                    <span className="pl-2">{t('default.add')} {t('default.box')}</span>
                  </button>
                </div>
                </div>
              )}
            </div>
          </div>
          <div className="">
            <div className="flow-root">
              {order?.details.length > 0 ? (
                <OrdersDetailTable isAdmin={isAdmin} onDeleteBox={onDeleteBox} data={order} onOpenBoxDetails={onOpenBoxDetails} callback={forceUpdate}/>
              ): (
                <EmptyStates state={t('default.boxes')} action={() => onOpenAddBoxesModal(order, forceUpdate)}/>
                )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OrderBoxes;
