import { useEffect, useState } from 'react';

const UseIsOrderReviewed = (order) => {
  const [isReviewed, setIsReviewed] = useState(false);

  useEffect(() => {
    if(order){
      const reviewed = order.status.id === 4; //Reviewed
      setIsReviewed(reviewed);
    }
  },[order]);

  return [isReviewed, setIsReviewed];
};

export default UseIsOrderReviewed;
