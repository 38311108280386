import { useEffect, useState } from 'react';

const UseUserCanEdit = (order) => {
  const [userCanEdit, setUserCanEdit] = useState(false);

  useEffect(() => {
    if(order){
      const canEdit = order.status.id === 1; //Draft
      setUserCanEdit(canEdit);
    }
  },[order]);

  return [userCanEdit, setUserCanEdit];
};

export default UseUserCanEdit;
