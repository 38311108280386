import { LOAD_USERS } from "../actions/Users";

const initialState = {
  items: [],
}

export default function(state = initialState, action){
  switch (action.type) {
    case LOAD_USERS: {
      return {
        ...state,
        items: action.value
      }
    }
    default: {
      return {...state};
    }
  }
}
