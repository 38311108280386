import React, { useEffect, useState } from 'react';
import { useForm} from "react-hook-form";
import {Dialog} from '@headlessui/react';
import {XIcon} from '@heroicons/react/outline';
import { useDispatch } from "react-redux";
import ClientRequest from "../../Services/ClientRequest";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
import processServerResponse from "../../Services/Utils/ProcessServerResponse";

const BoxesModal = ({box, onCloseModal}) => {
  const { t } = useTranslation();
  const { register, handleSubmit, setError, formState: { errors } } = useForm({
    defaultValues: box
  });
  const [states, setStates] = useState([]);
  const dispatch = useDispatch();
  const request = new ClientRequest(dispatch);
  const boxAdded = () => toast.success(t('admin.boxes.modal.actions.created'));
  const boxUpdated = () => toast.success(t('admin.boxes.modal.actions.updated'));

  useEffect(() => {
    request.read('states')
      .then((res) => {
        setStates(res.data);
      }).catch((err) => console.log(err));
  },[]);

  const onSubmit = payload => {
    if(box){
      request.update(`boxes/${box.id}`, payload)
        .then((res) => {
          processServerResponse(res, setError, boxUpdated, onCloseModal);
        })
        .catch((err) => {
          console.log('boxes', err);
          onCloseModal();
        });
    }else{
      request.create('boxes', payload)
        .then((res) => {
          processServerResponse(res, setError, boxAdded, onCloseModal);
        })
        .catch((err) => {
          console.log('drivers', err);
          onCloseModal();
        });
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)} className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
      <div className="flex-1 h-0 overflow-y-auto">
        <div className="py-6 px-4 bg-indigo-700 sm:px-6">
          <div className="flex items-center justify-between">
            <Dialog.Title className="text-lg font-medium text-white">{!box ? t('admin.boxes.modal.titleNew') : t('admin.boxes.modal.titleEdit')}</Dialog.Title>
            <div className="ml-3 h-7 flex items-center">
              <button
                type="button"
                className="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                onClick={() => onCloseModal()}
              >
                <span className="sr-only">Close panel</span>
                <XIcon className="h-6 w-6" aria-hidden="true"/>
              </button>
            </div>
          </div>
          <div className="mt-1">
            <p className="text-sm text-indigo-300">
              {t('admin.boxes.modal.descripcion')}
            </p>
          </div>
        </div>
        <div className="flex-1 flex flex-col justify-between">
          <div className="px-4 divide-y divide-gray-200 sm:px-6">
            <div className="space-y-6 pt-6 pb-5">
              <div>
                <label htmlFor="name" className="block text-sm font-medium text-gray-900">
                  {t('admin.boxes.table.name')}
                </label>
                <div className="mt-1 space-y-1">
                  <input
                    type="text"
                    autoComplete="off"
                    {...register('name', { required: true })}
                    className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  />
                  {errors.name && errors.name.message.split("|").map((error,index) => (
                    <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                      {error && t(`errors.${error}`)}
                    </span>
                  ))}
                </div>
              </div>
              <div>
                <label htmlFor="state_id" className="block text-sm font-medium text-gray-900">
                  {t('admin.boxes.table.country')}
                </label>
                <div className="mt-1 space-y-1">
                  <select
                    id="state_id"
                    name="state_id"
                    autoComplete="off"
                    {...register('state_id', { required: true })}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    <option value="">{t('default.selectState')}</option>
                    {Object.values(states).map((state) => (
                      <option key={state.id} value={state.id} selected={box && box.state_id == state.id ? "selected" : ""}>
                        {state.name}
                      </option>
                    ))}
                  </select>
                  {errors.state_id && errors.state_id.message.split("|").map((error,index) => (
                    <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                      {error && t(`errors.${error}`)}
                    </span>
                  ))}
                </div>
              </div>
              <div>
                <label htmlFor="width_in" className="block text-sm font-medium text-gray-900">
                  {t('admin.boxes.table.width')}
                </label>
                <div className="mt-1 space-y-1">
                  <input
                    type="text"
                    autoComplete="off"
                    {...register('width_in', { required: true })}
                    className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  />
                  {errors.width_in && errors.width_in.message.split("|").map((error,index) => (
                    <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                      {error && t(`errors.${error}`)}
                    </span>
                  ))}
                </div>
              </div>
              <div>
                <label htmlFor="height_in" className="block text-sm font-medium text-gray-900">
                  {t('admin.boxes.table.height')}
                </label>
                <div className="mt-1 space-y-1">
                  <input
                    type="text"
                    autoComplete="off"
                    {...register('height_in', { required: true })}
                    className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  />
                  {errors.height_in && errors.height_in.message.split("|").map((error,index) => (
                    <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                      {error && t(`errors.${error}`)}
                    </span>
                  ))}
                </div>
            </div>
              <div>
                <label htmlFor="length_in" className="block text-sm font-medium text-gray-900">
                  {t('admin.boxes.table.length')}
                </label>
                <div className="mt-1 space-y-1">
                  <input
                    type="text"
                    autoComplete="off"
                    {...register('length_in', { required: true })}
                    className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  />
                  {errors.length_in && errors.length_in.message.split("|").map((error,index) => (
                    <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                      {error && t(`errors.${error}`)}
                    </span>
                  ))}
                </div>
              </div>
              <div>
                <label htmlFor="default_price" className="block text-sm font-medium text-gray-900">
                  {t('default.price')}
                </label>
                <div className="mt-1 space-y-1">
                  <div className="mt-1 relative rounded-md shadow-sm">
                    <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                      <span className="text-gray-500 sm:text-sm"> $ </span>
                    </div>
                    <input
                      type="text"
                      placeholder="0.00"
                      autoComplete="off"
                      {...register('default_price', { required: true })}
                      className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                    />
                  </div>
                  {errors.default_price && errors.default_price.message.split("|").map((error,index) => (
                    <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                      {error && t(`errors.${error}`)}
                    </span>
                  ))}
                </div>
              </div>
          </div>
        </div>
      </div>
      </div>
      <div className="flex-shrink-0 px-4 py-4 flex justify-end">
        <button
          type="button"
          className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          onClick={() => onCloseModal()}
        >
          {t('default.cancel')}
        </button>
        <button
          type="submit"
          className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
        >
          {t('default.save')}
        </button>
      </div>
    </form>
  );
};

export default BoxesModal;
