import { Fragment } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import AddressModal from "./AddressModal";
import BoxesModal from "./BoxesModal";
import DriversModal from "./DriversModal";
import TrucksModal from "./TrucksModal";
import { closeModal } from "../../actions/Modal";
import { connect } from "react-redux";
import UsersModal from "./UsersModal";
import BoxDetailsModal from "./BoxDetailsModal";
import RouteAddressModal from "./RouteAddressModal";

const MODAL_ADDRESS = 1;
const MODAL_BOX = 2;
const MODAL_DRIVERS = 3;
const MODAL_TRUCKS = 4;
const MODAL_ADD_USERS = 7;
const MODAL_BOX_DETAILS = 8;
const MODAL_ROUTE_ADDRESS = 10;

const ModalsArray = [1,2,3,4,7,10];

const ToolModals = ({closeModal, activeModal, options, countries, states, user}) => {
  const open = (ModalsArray.includes(activeModal));

  return (
    <Transition.Root show={open} as={Fragment}>
      <Dialog as="div" static className="z-10 fixed inset-0 overflow-hidden" open={open} onClose={()=> closeModal()}>
        <div className="absolute inset-0 overflow-hidden">
          <Transition.Child
            as={Fragment}
            enter="ease-in-out duration-500"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in-out duration-500"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <Dialog.Overlay className="absolute inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>

          <div className="fixed inset-y-0 right-0 pl-10 max-w-full flex">
            <Transition.Child
              as={Fragment}
              enter="transform transition ease-in-out duration-500 sm:duration-700"
              enterFrom="translate-x-full"
              enterTo="translate-x-0"
              leave="transform transition ease-in-out duration-500 sm:duration-700"
              leaveFrom="translate-x-0"
              leaveTo="translate-x-full"
            >
              <div className="w-screen max-w-md">
                {activeModal === MODAL_ADDRESS &&
                  <AddressModal selecteduser={options && options.selectedUser} user={user} address={options && options.address} onCloseModal={closeModal}/>
                }
                {activeModal === MODAL_ROUTE_ADDRESS &&
                  <RouteAddressModal address={options && options.address} countries={countries} states={states} onCloseModal={closeModal}/>
                }
                {activeModal === MODAL_BOX &&
                  <BoxesModal box={options && options.box} states={states} onCloseModal={closeModal}/>
                }
                {activeModal === MODAL_DRIVERS &&
                  <DriversModal driver={options && options.driver} onCloseModal={closeModal}/>
                }
                {activeModal === MODAL_TRUCKS &&
                  <TrucksModal truck={options && options.truck} onCloseModal={closeModal}/>
                }
                {activeModal === MODAL_ADD_USERS &&
                  <UsersModal user={options && options.user} onCloseModal={closeModal}/>
                }
                {activeModal === MODAL_BOX_DETAILS &&
                  <BoxDetailsModal box={options && options.box} canEdit={options && options.canEdit} onCloseModal={closeModal} />
                }
              </div>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
};

const stateToProps = state => (
  {
    activeModal: state.modal.activeModal,
    options: state.modal.options,
    countries: state.admin.countries,
    states: state.admin.states,
    user: state.user.info
  }
);

const dispatchToProps = dispatch => (
  {
    closeModal: () => dispatch(closeModal()),
  }
);

export default connect(stateToProps, dispatchToProps)(ToolModals);


