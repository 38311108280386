import React from 'react';
import AuthLayout from "../components/Auth/AuthLayout";

const Loading = () => {
  return (
    <AuthLayout>
      <div className='flex justify-center text-4xl text-gray-400'>
        <h3>Loading...</h3>
      </div>
    </AuthLayout>
  );
};


export default Loading;
