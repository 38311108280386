import React, { useState } from 'react';
import {
  MapContainer,
  LayersControl,
  TileLayer,
} from 'react-leaflet';
import RoutingControl from "./RoutingControl";

const maps = {
  base: "https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
};

const Map = ({routes, positionCenter, zoom, showRoutes = true}) => {
  const [map, setMap] = useState(null);
  const [keyMap] = useState(Math.random());

  return (
    <MapContainer
      key={keyMap}
      center={positionCenter}
      zoom={zoom}
      zoomControl={false}
      scrollWheelZoom={false}
      whenCreated={map => setMap(map)}
    >
      {showRoutes && (
        <RoutingControl routeSteps={routes} />
      )}
      <LayersControl position="topright">
        <LayersControl.BaseLayer checked name="Map">
          <TileLayer
            attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
            url={maps.base}
          />
        </LayersControl.BaseLayer>
      </LayersControl>
    </MapContainer>
  );
};

export default Map;
