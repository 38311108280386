import React, { useEffect, useState } from 'react';
import { XIcon } from "@heroicons/react/solid";
import { useDispatch } from "react-redux";
import ClientRequest from "../../Services/ClientRequest";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import Map from "../UI-Components/Maps/Map";
import useCountry from "../../Services/CustomHooks/useCountry";
import useDrivers from "../../Services/CustomHooks/useDrivers";
import useTrucks from "../../Services/CustomHooks/useTrucks";
import processServerResponse from "../../Services/Utils/ProcessServerResponse";
import { toast } from "react-toastify";
import { useHistory } from "react-router-dom";
import OrdersDetailsTable from "../UI-Components/Orders/ContentDetailsTable";

const AddBoxesModal = ({onCloseModal, details}) => {
  const { t } = useTranslation();
  const [orderDetails, setOrderDetails] = useState([]);
  const [selectedItem, setSelectedItem] = useState(details.category_id ? details.box_content[0]: null);
  const defaultValues = {
    number_of_items: "",
    price_per_item: "",
    description: "",
    old_new: 0,
    id: 0
  }
  const { register, handleSubmit, setError, reset, formState: { errors } } = useForm({
    defaultValues: (selectedItem || defaultValues),
  });

  const dispatch = useDispatch();
  const request = new ClientRequest(dispatch);

  useEffect(() => {
    if(selectedItem){
      const item = {
        number_of_items: selectedItem.number_of_items,
        price_per_item: selectedItem.price_per_item,
        description: selectedItem.description,
        old_new: selectedItem.old_new,
        id: selectedItem.id,
      }
      reset(item);
    }else{
      reset(defaultValues);
    }
  }, [selectedItem]);

  const fetchRecords = () => {
    const url = `shipment-content-details/${details.id}`
    request.read(url)
      .then((res) => {
        const {success, data} = res;
        if(success){
          setOrderDetails(data);
        }
        console.log(res);
      }).catch((err) => console.log(err));
  }

  useEffect(() => {
    fetchRecords();
  },[]);

  const deleteRecord = (id) => {
    const url = `shipment-content-details/${id}`
    request.delete(url)
      .then((res) => {
        const {success} = res;
        if(success){
          fetchRecords();
        }
      }).catch((err) => console.log(err));
  }

  const saveRecord = (data) => {
    const payload = {
      "shipment_details_id": details.id,
      ...data
    }
    if(selectedItem){
      const url = `shipment-content-details/${data.id}`

      request.update(url, payload)
        .then((res) => {
          processServerResponse(res, setError, fetchRecords, reset);
          if(res.success) setSelectedItem(null);
          if(details.category_id) onCloseModal();
        }).catch((err) => console.log(err));
    }else{
      const url = `shipment-content-details`

      request.create(url, payload)
        .then((res) => {
          processServerResponse(res, setError, fetchRecords, reset);
        }).catch((err) => console.log(err));
    }
  }

  return (
    <div>
      <div
        className="flex flex-row justify-between p-6 bg-white border-b border-gray-200 rounded-tl-lg rounded-tr-lg"
      >
        <p className="font-semibold text-gray-800">{t('order.boxDetails')}</p>
        <XIcon className="w-6 h-6" onClick={() => onCloseModal()}/>
      </div>
      <div className="flex flex-col px-6 py-2 bg-gray-50">
        <div className="grid grid-cols-1">
          <form id="newRecord" className="flex flex-col pb-3" onSubmit={handleSubmit(saveRecord)}>
            <div className="grid grid-cols-3 gap-5 pb-2">
              <div>
                <label htmlFor="address_1" className="block text-sm font-medium text-gray-900">
                  {t('order.table.numberOfItems')}
                </label>
                <input
                  type="hidden"
                  {...register('id')}
                />
                <div className="mt-1 space-y-1">
                  <input
                    type="text"
                    autoComplete="off"
                    disabled={details.category_id}
                    {...register('number_of_items', { required: true })}
                    className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                  />
                  {errors.number_of_items && errors.number_of_items.message.split("|").map((error,index) => (
                    <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                    {error && t(`errors.${error}`)}
                  </span>
                  ))}
                </div>
              </div>
              <div>
                <label htmlFor="address_1" className="block text-sm font-medium text-gray-900">
                  {t('order.table.pricePerItem')}
                </label>
                <div className="mt-1 relative rounded-md shadow-sm">
                  <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
                    <span className="text-gray-500 sm:text-sm"> $ </span>
                  </div>
                  <input
                    type="text"
                    placeholder="0.00"
                    autoComplete="off"
                    {...register('price_per_item', { required: true })}
                    className="focus:ring-indigo-500 focus:border-indigo-500 block w-full pl-7 pr-12 sm:text-sm border-gray-300 rounded-md"
                  />
                </div>
                {errors.price_per_item && errors.price_per_item.message.split("|").map((error,index) => (
                  <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                  {error && t(`errors.${error}`)}
                </span>
                ))}

              </div>
              <div>
                <label className="text-gray-700">
                  {t('order.table.newOrOld')}
                </label>
                <div className="mt-1 space-y-1">
                  <select
                    autoComplete="off"
                    {...register('old_new', { required: true })}
                    className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                  >
                    <option value="0" selected>{t('order.table.usedItem')}</option>
                    <option value="1">{t('order.table.newItem')}</option>
                  </select>
                </div>
              </div>
            </div>
            <div>
              <label htmlFor="address_1" className="block text-sm font-medium text-gray-900">
                {t('order.table.description')}
              </label>
              <textarea
                rows="3"
                {...register('description', { required: true })}
                className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
              />
              {errors.description && errors.description.message.split("|").map((error,index) => (
                <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                  {error && t(`errors.${error}`)}
                </span>
              ))}
            </div>
          </form>
          {!details.category_id && (
            <div className="flex flex-col mt-4">
              <div className="overflow-x-auto sm:-mx-4 lg:-mx-5">
                <div className="inline-block min-w-full px-5">
                  <div className="overflow-hidden shadow-md sm:rounded-lg">
                    <table className="w-full table-fixed divide-y divide-gray-200">
                      <thead className="bg-gray-50">
                      <tr>
                        <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                          {t('order.table.quantity')}
                        </th>
                        <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                          {t('order.table.price')}
                        </th>
                        <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                          {t('order.table.new')}
                        </th>
                        <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider table-cell">
                          {t('order.table.description')}
                        </th>
                        <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
                          {t('default.actions')}
                        </th>
                      </tr>
                      </thead>
                      <tbody>
                      {Object.keys(orderDetails).length > 0 ? (
                        <React.Fragment>
                          {Object.values(orderDetails).map((item, index) => (
                            <tr key={index} className="bg-white break-words">
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center table-cell">
                                <span>{item.number_of_items}</span>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center table-cell">
                                <span>{item.price_per_item}</span>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center table-cell">
                                <span>{item.old_new? t('default.yes') : t('default.no')}</span>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center table-cell w-40 overflow-scroll">
                                <p className="break-all">{item.description}</p>
                              </td>
                              <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center table-cell">
                                <span className='ml-2 cursor-pointer' onClick={() => setSelectedItem(item)}>{t('default.edit')}</span>
                                <span className='ml-2 cursor-pointer' onClick={() => deleteRecord(item.id)}>{t('default.delete')}</span>
                              </td>
                            </tr>
                          ))}
                        </React.Fragment>
                      ): (
                        <tr className="bg-white">
                          <td colSpan="5" className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">{t('default.nothingToShow')}</td>
                        </tr>
                      )}
                      </tbody>
                    </table>
                  </div>
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
      <div
        className="flex flex-row items-center justify-between p-5 bg-white border-t border-gray-200 rounded-bl-lg rounded-br-lg"
      >
        <button className="px-4 py-2 font-semibold" onClick={() => onCloseModal()}>
          {t('default.close')}
        </button>
        <div>
          {selectedItem && !details.category_id &&(
            <button onClick={()=> setSelectedItem(null)} className="px-4 py-2 text-white font-semibold bg-red-400 rounded">
              {t('default.cancel')} {t('default.edit')}
            </button>
          )}
          <button type='submit' form="newRecord" className="ml-2 px-4 py-2 text-white font-semibold bg-blue-500 rounded">
            {t('default.save')}
          </button>
        </div>
      </div>
    </div>
  );
};

export default AddBoxesModal;
