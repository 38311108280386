import { LOAD_ORDERS } from "../actions/Orders";

const initialState = {
  items:[]
};

export default function(state = initialState, action){
  switch (action.type) {
    case LOAD_ORDERS: {
      return {
        ...state,
        items: action.value
      }
    }
    default: {
      return {...state};
    }
  }
}
