import React, { useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import PageHeader from "../UI-Components/PageHeader";
import { openOrdersModal } from "../../actions/Modal";
import ClientRequest from "../../Services/ClientRequest";
import OrdersTable from "../UI-Components/Orders/OrdersTable";
import useFilters from "../../Services/CustomHooks/useFilters";
import usePrevious from "../../Services/CustomHooks/usePrevious";
import { useTranslation } from "react-i18next";

const Index = ({onOpenOrdersModal}) => {
  const { t } = useTranslation();
  const [orders, setOrders] = useState([]);
  const [statuses, setStatuses] = useState([]);
  const [filters, setFilters] = useFilters(true, true, true);
  const prevFilters = usePrevious(filters);

  const dispatch = useDispatch();
  const request = new ClientRequest(dispatch);

  useEffect(() => {
    fetchRecords(true);
  }, []);


  useEffect(() => {
    request.read('shipment-status')
      .then((res) => {
        setStatuses(res.data);
      })
      .catch((err) => console.log(err));
  },[orders]);

  useEffect(() => {
    if(prevFilters?.page !== filters?.page || prevFilters?.status !== filters?.status){
      fetchRecords();
    }
  }, [filters]);

  const fetchRecords = (isFirstLoad = false) => {
    const copyFilters = {...filters};
    const { search, status, page } = copyFilters;

    request.read(`shipment?page=${page}&status=${status}&search=${search}`).then((res)=> {
      const {data:{data, total, per_page, from, to, last_page} } = res;
      setOrders(data);

      if(isFirstLoad){
        setFilters({
          ...filters,
          pagination: {
            total,
            per_page,
            from,
            to,
            last_page
          }
        });
      }
    }).catch((err) => console.log(err));
  }

  return (
    <div>
      <div>
        <PageHeader title={t('orders.title')} action={onOpenOrdersModal} actionTitle={t('default.create')}/>
        <div className="px-8">
          <OrdersTable statuses={statuses} filters={filters} setFilters={setFilters} fetchRecords={fetchRecords} data={orders}/>
        </div>
      </div>
    </div>
  );
};

const dispatchToProps = dispatch => ({
  onOpenOrdersModal: (data = null) => dispatch(openOrdersModal(data))
});

export default connect(null, dispatchToProps)(Index);
