import React, { useEffect, useState } from 'react';
import MobileMenu from "../Menu/MobileMenu";
import Sidebar from "../Menu/Sidebar";
import MobileHeader from "../Menu/MobileHeader";
import Container from "../UI-Components/Container";
import ToolModals from "../../components/Modals/ToolModals";
import { updateMenu } from "../../actions/Menu";
import { logout } from "../../Services/authService";
import { connect, useDispatch } from "react-redux";
import { loadShipmentStatus } from "../../actions/Admin";
import ClientRequest from "../../Services/ClientRequest";
import Modals from "../Modals/Modals";
import { canDo } from "../../actions/Auth";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const AppLayout = ({user, menu, updateMenu,shipmentStatus, logout, children}) => {
  const dispatch = useDispatch();
  const request = new ClientRequest(dispatch);
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [canISee, setCanISee] = useState();

  useEffect(() =>{
    setCanISee(canDo(user));
  }, [])

  useEffect(() => {
    request.read('shipment-status')
      .then((res) => {
        dispatch(loadShipmentStatus(res.data));
      }).catch((err) => console.log(err));
  },[]);

  return (
    <div className="h-screen flex overflow-hidden bg-white">
      <MobileMenu
        navigation={menu}
        shipmentStatus={shipmentStatus}
        classNames={classNames}
        sidebarOpen={sidebarOpen}
        setSidebarOpen={setSidebarOpen}
        updateMenu={updateMenu}
        logout={logout}
        canISee={canISee}
      />
      <Sidebar
        user={user}
        navigation={menu}
        shipmentStatus={shipmentStatus}
        classNames={classNames}
        updateMenu={updateMenu}
        logout={logout}
        canISee={canISee}
      />
      <div className="flex flex-col flex-1 w-0 flex-1 overflow-hidden">
        <MobileHeader user={user} setSidebarOpen={setSidebarOpen}/>
        <Container>
          {children}
        </Container>
        <ToolModals />
        <Modals />
      </div>
    </div>
  );
};

const stateToProps = state => (
  {
    menu: state.menu.items,
    user: state.user.info,
    shipmentStatus: state.admin.shipmentStatus,
  }
);

const dispatchToProps = dispatch => (
  {
    updateMenu: (id) => dispatch(updateMenu(id)),
    logout: () => dispatch(logout()),
  }
);

export default connect(stateToProps, dispatchToProps)(AppLayout);

