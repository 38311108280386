import React, { useEffect, useState } from 'react';
import PageHeader from "../UI-Components/PageHeader";
import { connect, useDispatch } from "react-redux";
import ClientRequest from "../../Services/ClientRequest";
import UsersTable from "../UI-Components/Users/UsersTable";
import useFilters from "../../Services/CustomHooks/useFilters";
import { openAddUsersModal } from "../../actions/Modal";
import ConfirmDialog from "../UI-Components/ConfirmDialog";
import usePrevious from "../../Services/CustomHooks/usePrevious";
import { useTranslation } from "react-i18next";

const Index = ({onOpenAddUsersModal}) => {
  const { t } = useTranslation();
  const [users, setUsers] = useState([]);
  const [filters, setFilters] = useFilters(true, true);
  const [ confirmDelete, setConfirmDelete ] = useState(false);
  const prevFilters = usePrevious(filters);

  const dispatch = useDispatch();
  const request = new ClientRequest(dispatch);

  useEffect(() => {
    fetchRecords(true);
  }, []);

  useEffect(() => {
    if(prevFilters?.page !== filters?.page || prevFilters?.status !== filters?.status){
      fetchRecords();
    }
  }, [filters]);

  const fetchRecords = (isFirstLoad = false) => {
    const copyFilters = {...filters};
    const { search, page } = copyFilters;

    request.read(`users?page=${page}&search=${search}`).then((res)=> {
      const {data:{data, total, per_page, from, to, last_page} } = res;
      setUsers(data);

      if(isFirstLoad){
        setFilters({
          ...filters,
          pagination: {
            total,
            per_page,
            from,
            to,
            last_page
          }
        });
      }
    }).catch((err) => console.log(err));
  }

  const onDeleteUser = () => {
    const id = confirmDelete.id;

    request.delete(`users/${id}`)
      .then(() => {
        fetchRecords();
      })
      .catch((err) => console.log('error', err));
    setConfirmDelete(false);
  }

  return (
    <div>
      <PageHeader title={t('users.title')} action={onOpenAddUsersModal} actionTitle={t('default.create')} callback={fetchRecords}/>
      <div className="px-8">
        <UsersTable data={users} filters={filters} setFilters={setFilters} fetchRecords={fetchRecords} deleteAction={setConfirmDelete} editAction={onOpenAddUsersModal}/>
      </div>

      {confirmDelete && (
        <ConfirmDialog
          title={t('users.delete.title')}
          description={t('users.delete.description')}
          buttonTitle={t('users.delete.action')}
          action={onDeleteUser}
          close={setConfirmDelete}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = dispatch => (
  {
    onOpenAddUsersModal: (data = null, callback) => dispatch(openAddUsersModal(data, callback)),
  }
);

export default connect(null, mapDispatchToProps)(Index);
