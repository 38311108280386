export const LOGGED_IN = 'LOGGED_IN';
export const LOGGED_OUT = 'LOGGED_OUT';
export const REGISTERED = 'REGISTERED';
export const PASSWORD_RESETTED = 'PASSWORD_RESETTED';

export function loggedIn(){
  return { type: LOGGED_IN };
}

export function registered(){
  return { type: REGISTERED };
}

export function passwordResetted(){
  return { type:  PASSWORD_RESETTED};
}

export function loggedOut(){
  return { type: LOGGED_OUT };
}

export const canDo = (user) => {
  const admin = Object.values(user.roles).find(el => el.name === 'admin');
  return (admin? true: false);
}
