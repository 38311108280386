export const UPDATE_MENU = 'UPDATE_MENU';
export const UPDATE_ADMIN_MENU = 'UPDATE_ADMIN_MENU';
export const UPDATE_USER_MENU = 'UPDATE_USER_MENU';

export const updateMenu = (id) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_MENU,
      id
    });
  }
}

export const updateAdminMenu = (id) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_ADMIN_MENU,
      id
    });
  }
}

export const updateUserMenu = (id) => {
  return (dispatch) => {
    dispatch({
      type: UPDATE_USER_MENU,
      id
    });
  }
}