import React, { useEffect, useState } from 'react';
import {Link, useHistory} from "react-router-dom";
import { useTranslation } from "react-i18next";
import { canDo } from "../../actions/Auth";

function classNames(...classes) {
  return classes.filter(Boolean).join(' ')
}

const NavTabs = ({tabs, activeItem, user}) => {
  const history = useHistory();
  const { t } = useTranslation();
  const [menuItems, setMenuItems] = useState([]);

  useEffect(() => {
    let menu = [];
    if(canDo(user)){
      menu = tabs.filter(el => el.name !== "address");
    }else{
      menu = tabs;
    }

    setMenuItems(menu);

  },[]);

  const handleClickOnSelect = (value) => {
    history.push(`${value}`);
  }

  return (
    <div>
      <div className="sm:hidden">
        <label htmlFor="tabs" className="sr-only">
          Select a tab
        </label>
        <select
          id="tabs"
          name="tabs"
          onChange={event => handleClickOnSelect(event.target.value)}
          className="mt-1 block w-full pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
          defaultValue={tabs.find((tab) => tab.current).name}
        >
          {menuItems.map((tab) => (
            <option
              key={tab.name}
              value={tab.href}
            >
              {t(`menu.${tab.name}`)}
            </option>
          ))}
        </select>
      </div>
      <div className="hidden sm:block">
        <div className="border-b border-gray-200">
          <nav className="-mb-px flex space-x-8" aria-label="Tabs">
            {menuItems.map((tab, index) => (
              <Link
                key={tab.name}
                to={tab.href}
                className={classNames(
                  tab.linkName === activeItem.linkName
                    ? 'border-indigo-500 text-indigo-600'
                    : 'border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300',
                  'group inline-flex items-center py-4 px-1 border-b-2 font-medium text-sm'
                )}
                aria-current={tab.linkName === activeItem.linkName ? 'page' : undefined}
              >
                <tab.icon
                  className={classNames(
                    tab.linkName === activeItem.linkName ? 'text-indigo-500' : 'text-gray-400 group-hover:text-gray-500',
                    '-ml-0.5 mr-2 h-5 w-5'
                  )}
                  aria-hidden="true"
                />
                <span>{t(`menu.${tab.name}`)}</span>
              </Link>
            ))}
          </nav>
        </div>
      </div>
    </div>
  );
};

export default NavTabs;
