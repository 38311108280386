import React, { useRef, useState } from 'react';
import { useTranslation } from "react-i18next";
import useOutSideClick from "../../Services/CustomHooks/useOutSideClick";

const DropdownMenu = ({children, items, action, type}) => {
  const { t } = useTranslation();
  const ref = useRef();
  const [active, setActive] = useState(false);

  const clickOnAction = (item) => {
    action(item);
    setActive(false);
  }

  useOutSideClick(ref, () =>{
    setActive(false);
  });

  return (
    <div className="relative" ref={ref}>
      <button
        type="button"
        onClick={() => setActive(!active)}
        className="inline-flex justify-center px-4 py-2 border border-gray-100 text-sm font-medium rounded-md text-gray-700 bg-white hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-gray-900"
      >
        {children}
      </button>
      {type !== "cat" && (
        <React.Fragment>
          {active && (
            <div className="absolute right-0 mt-0 py-0 w-48 bg-white rounded-md shadow-xl z-20">
              {Object.values(items).map(item => (
                <span key={item.id} onClick={() => clickOnAction(item.id)} className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-500 hover:text-white">
                  {t(`orders.items.${item.name}`)}
                </span>
              ))}
            </div>
          )}
        </React.Fragment>
      )}

      {type === "cat" && (
        <React.Fragment>
          {active && (
            <div className="absolute right-0 mt-0 py-0 w-48 bg-white rounded-md shadow-xl z-20">
              {Object.values(items).map(item => (
                <span key={item.id} onClick={() => clickOnAction(item.id)} className="block px-4 py-2 text-sm capitalize text-gray-700 hover:bg-blue-500 hover:text-white">
                  {t(`default.categoriesList.${item.name}`)}
                </span>
              ))}
            </div>
          )}
        </React.Fragment>
      )}
    </div>
  );
};

export default DropdownMenu;
