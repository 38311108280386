export const LOAD_USER = 'LOAD_USER';
export const LOAD_ADDRESS = 'LOAD_ADDRESS';
export const EDIT_ADDRESS = 'EDIT_ADDRESS';
export const ADD_ADDRESS = 'ADD_ADDRESS';
export const DELETE_ADDRESS = 'DELETE_ADDRESS';
export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';

export const loadUserInfo = (data) => {
  return {
    type: LOAD_USER,
    value: data
  }
}
export const updateUserInfo = (data) => {
  return {
    type: UPDATE_USER_INFO,
    value: data
  }
}

export const loadAddress = (data) => {
  return {
    type: LOAD_ADDRESS,
    value: data,
  }
}

export const editAddress = (data) => {
  return {
    type: EDIT_ADDRESS,
    value: data,
    id: data.id
  }
}

export const addAddress = (data) => {
  return {
    type: ADD_ADDRESS,
    value: data
  }
}

export const deleteAddress = (id) => {
  return {
    type: DELETE_ADDRESS,
    id
  }
}


