import React from 'react';
import { Link } from "react-router-dom";
import Table2 from "../Table2";
import TableFilters from "../TableFilters";
import { useTranslation } from "react-i18next";

const RouteGeneratorTable = ({data, filters, setFilters, fetchRecords, deleteAction, editAction}) => {
  const { t } = useTranslation();

  return (
    <Table2 action={fetchRecords} setFilters={setFilters} filters={filters}>
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
          <tr>
            <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
              {t('routeGenerator.table.routeStart')}
            </th>
            <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
              {t('routeGenerator.table.routeEnd')}
            </th>
            <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
              {t('routeGenerator.table.pickups')}
            </th>
            <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider sm:hidden lg:table-cell">
              {t('routeGenerator.table.truck')}
            </th>
            <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider sm:hidden lg:table-cell">
              {t('routeGenerator.table.driver')}
            </th>
            <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider sm:hidden lg:table-cell">
              {t('routeGenerator.table.date')}
            </th>
            <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
              {t('routeGenerator.table.actions')}
            </th>
          </tr>
        </thead>
        <tbody>
          {Object.keys(data).length > 0 ? (
            <React.Fragment>
              {Object.values(data).map((item, index) => (
                <tr key={index} className="bg-white">
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                    <span>{item?.start_route?.address_1} {item?.start_route?.zipcode}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                    <span>{item?.end_route?.address_1} {item?.end_route?.zipcode}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                    <span>{item?.details.length}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center sm:hidden lg:table-cell">
                    <span>{item?.truck?.name}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center sm:hidden lg:table-cell">
                    <span>{item?.driver?.name}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center sm:hidden lg:table-cell">
                    <span>{item?.created_at}</span>
                  </td>
                  <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                    <Link to={`/route-generator/${item.id}`} >{t('default.view')}</Link>
                    <span className="mx-2 cursor-pointer" onClick={() => deleteAction(item, fetchRecords)}>{t('default.delete')}</span>
                  </td>
                </tr>
              ))}
            </React.Fragment>
          ): (
            <tr className="bg-white">
              <td colSpan="7" className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 flex justify-center">{t('default.nothingToShow')}</td>
            </tr>
          )}
        </tbody>
      </table>
    </Table2>
  );
};

export default RouteGeneratorTable;
