import React, { useEffect, useState } from 'react';
import {geocodeByAddress, getLatLng} from 'react-places-autocomplete';
import { useForm } from "react-hook-form";
import { Dialog } from '@headlessui/react';
import { XIcon } from '@heroicons/react/outline';
import 'react-toastify/dist/ReactToastify.css';
import { useDispatch } from "react-redux";
import { toast } from "react-toastify";
import ClientRequest from "../../Services/ClientRequest";
import { canDo } from "../../actions/Auth";
import useCountry from "../../Services/CustomHooks/useCountry";
import useStates from "../../Services/CustomHooks/useStates";
import processServerResponse from "../../Services/Utils/ProcessServerResponse";
import { useTranslation } from "react-i18next";
import axios from "axios";
import Input from 'react-phone-number-input/input'

const AddressModal = ({user, selecteduser, address, onCloseModal}) => {
  const { t } = useTranslation();
  const { register, handleSubmit, setError, watch, setValue, formState: { errors } } = useForm({
    defaultValues: address
  });
  const [isAdmin, setIsAdmin] = useState(false);
  const [countries] = useCountry();
  const [states, setStates] = useStates();
  const [users, setUsers]= useState([]);
  const dispatch = useDispatch();
  const request = new ClientRequest(dispatch);
  const addressAdded = () => toast.success(t('admin.addresses.modal.actions.created'));
  const addressUpdated = () => toast.success(t('admin.addresses.modal.actions.updated'));

  const stateId = watch("state_id");
  const address1 = watch("address_1");
  const zipcode = watch("zipcode");

  if(stateId && address1 && zipcode){
    const stateMap = Object.values(states).find(el => el.id == stateId);
    if(stateMap){
      const config = {
        url: `https://open.mapquestapi.com/geocoding/v1/address?key=FpwoyPASFPeMVnz87PdAu0EFmZyna8zr&location=${stateMap.name} ${address1}, ${zipcode}`,
        method: 'get'
      }

      axios.request(config)
        .then(response => {
          const { data: {results} } = response;
          console.log(results);
          if(results){
            const location = results[0].locations[0];
            if(location){
              const {latLng} = location;
              if(latLng){
                if(latLng.lat != "39.78373"){
                  setValue("latitude", latLng.lat);
                }
                if(latLng.lng != "-100.445882"){
                  setValue("longitude", latLng.lng);
                }
              }
            }
          }
        })
        .catch(error => {
          console.log('error', error);
        })
    }
  }

  useEffect(() => {
    if(user){
      setIsAdmin(canDo(user));
    }
  },[]);

  useEffect(() => {
    if(isAdmin){
      request.read('users?type=all')
        .then((res) => {
          setUsers(res.data);
        }).catch((err) => console.log(err));
    }
  },[isAdmin]);

  const onHandleChangeCountry = (id) => {
    request.read(`states/${id}`)
      .then((res) => {
        setStates(res.data);
      }).catch((err) => console.log(err));
  }

  const onSubmit = payload => {
    if(address){
      request.update(`addresses/${address.id}`, payload)
        .then((res) => {
          processServerResponse(res, setError,addressUpdated, onCloseModal);
        })
        .catch((err) => {
          console.log('address', err);
          onCloseModal();
        });
    }else{
      request.create('addresses', payload)
        .then((res) => {
          processServerResponse(res, setError,addressAdded, onCloseModal);
        })
        .catch((err) => {
          console.log('address', err);
          onCloseModal();
        });
    }
  }

  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const { lat, lng } = await getLatLng(results[0]);

    const { short_name: postalCode } = results[0].address_components.find((el) => el.types.includes("postal_code")) || {};
    const { short_name: country } = results[0].address_components.find((el) => el.types.includes("country")) || {};
    const { short_name: state } = results[0].address_components.find((el) => el.types.includes("locality")) || {};
    const { long_name: address_1 } = results[0].address_components.find((el) => el.types.includes("administrative_area_level_1")) || {};
    const { long_name: address_2 } = results[0].address_components.find((el) => el.types.includes("administrative_area_level_2")) || {};
    const selectedCountry = countries.find((el) => el.name === country) || 0;
    const selectedState = states.find((el) => el.name === state) || 0;

    setValue('latitude', lat);
    setValue('longitude', lng);
    setValue('zipcode', postalCode);
    setValue('country_id', selectedCountry.id);
    setValue('state_id', selectedState.id);
    setValue('address_1', address_1);
    setValue('address_2', address_2);
  }

  return (
      <form id="addressForm" onSubmit={handleSubmit(onSubmit)} className="h-full divide-y divide-gray-200 flex flex-col bg-white shadow-xl">
        <div className="flex-1 h-0 overflow-y-auto">
          <div className="py-6 px-4 bg-indigo-700 sm:px-6">
            <div className="flex items-center justify-between">
              <Dialog.Title className="text-lg font-medium text-white">{!address ? t('admin.addresses.titleNew') : t('admin.addresses.titleEdit')}</Dialog.Title>
              <div className="ml-3 h-7 flex items-center">
                <button
                  type="button"
                  className="bg-indigo-700 rounded-md text-indigo-200 hover:text-white focus:outline-none focus:ring-2 focus:ring-white"
                  onClick={() => onCloseModal()}
                >
                  <span className="sr-only">Close panel</span>
                  <XIcon className="h-6 w-6" aria-hidden="true"/>
                </button>
              </div>
            </div>
            <div className="mt-1">
              <p className="text-sm text-indigo-300">
                {t('admin.addresses.description')}
              </p>
            </div>
          </div>
          <div className="flex-1 flex flex-col justify-between">
            <div className="px-4 divide-y divide-gray-200 sm:px-6">
              <div className="space-y-6 pt-6 pb-5">
                {isAdmin && (
                  <div>
                    <label htmlFor="country_id" className="block text-sm font-medium text-gray-900">
                      {t('users.title')}
                    </label>
                    <div>
                      <select
                        name="user_id"
                        autoComplete="off"
                        {...register('user_id', { required: true })}
                        className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                      >
                        <option value="">{t('default.selectUser')}</option>
                        {Object.values(users).map((user) =>  (
                          <option key={user.id} value={user.id} selected={selecteduser == user.id ? "selected" : ""}>
                            {user.firstname} {user.lastname} - {user.email}
                          </option>
                        ))}
                      </select>
                      {errors.user_id && errors.user_id.message.split("|").map((error,index) => (
                        <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                          {error && t(`errors.${error}`)}
                        </span>
                      ))}
                    </div>
                  </div>
                )}
                <div>
                  <label htmlFor="country_id" className="block text-sm font-medium text-gray-900">
                    {t('admin.addresses.table.country')}
                  </label>
                  <div className="mt-1 space-y-1">
                    <select
                      name="country_id"
                      {...register('country_id', { required: true })}
                      onChange={(e) => onHandleChangeCountry(e.target.value)}
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    >
                      <option value="">{t('default.selectCountry')}</option>
                      {Object.values(countries).map((country) => (
                        <option key={country.id} value={country.id} selected={address && address.country_id === country.id ? "selected" : ""}>
                          {country.name}
                        </option>
                      ))}
                    </select>
                    {errors.country_id && errors.country_id.message.split("|").map((error,index) => (
                      <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                        {error && t(`errors.${error}`)}
                      </span>
                    ))}
                  </div>
                </div>
                <div>
                  <label htmlFor="state_id" className="block text-sm font-medium text-gray-900">
                    {t('admin.addresses.table.state')}
                  </label>
                  <div className="mt-1 space-y-1">
                    <select
                      name="state_id"
                      {...register('state_id', { required: true })}
                      className="mt-1 block w-full pl-3 pr-10 py-2 text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
                    >
                      <option value="">{t('default.selectState')}</option>
                      {Object.values(states).map((state) => (
                        <option key={state.id} value={state.id} selected={address && address.state_id === state.id ? "selected" : ""}>
                          {state.name}
                        </option>
                      ))}
                    </select>
                    {errors.state_id && errors.state_id.message.split("|").map((error,index) => (
                      <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                        {error && t(`errors.${error}`)}
                      </span>
                    ))}
                  </div>
                </div>
                <div>
                  <label htmlFor="address_1" className="block text-sm font-medium text-gray-900">
                    {t('default.address')}
                  </label>
                  <div className="mt-1 space-y-1">
                    <input
                      type="text"
                      placeholder={t('default.address1')}
                      autoComplete="off"
                      {...register('address_1', { required: true })}
                      className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    />
                    <input
                      type="text"
                      placeholder={t('default.address2')}
                      autoComplete="off"
                      {...register('address_2')}
                      className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    />
                    {errors.address_1 && errors.address_1.message.split("|").map((error,index) => (
                      <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                        {error && t(`errors.${error}`)}
                      </span>
                    ))}
                    {errors.address_2 && errors.address_2.message.split("|").map((error,index) => (
                      <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                        {error && t(`errors.${error}`)}
                      </span>
                    ))}
                  </div>
                  <div className="py-2 space-y-1">
                    <label htmlFor="zipcode" className="block text-sm font-medium text-gray-900">
                      {t('default.zipCode')}
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      {...register('zipcode', { required: true })}
                      className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    />
                    {errors.zipcode && errors.zipcode.message.split("|").map((error,index) => (
                      <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                        {error && t(`errors.${error}`)}
                      </span>
                    ))}
                  </div>
                  <div className="py-2 space-y-1">
                    <label htmlFor="address_link" className="block text-sm font-medium text-gray-900">
                      {t('default.addressLink')}
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      {...register('address_link')}
                      className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    />
                    {errors.address_link && errors.address_link.message.split("|").map((error,index) => (
                      <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                        {error && t(`errors.${error}`)}
                      </span>
                    ))}
                  </div>
                  <div className="py-2 space-y-1">
                    <label htmlFor="latitude" className="block text-sm font-medium text-gray-900">
                      {t('default.latitude')}
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      {...register('latitude', { required: true })}
                      className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    />
                    {errors.latitude && errors.latitude.message.split("|").map((error,index) => (
                      <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                        {error && t(`errors.${error}`)}
                      </span>
                    ))}
                  </div>
                  <div className="py-2 space-y-1">
                    <label htmlFor="longitude" className="block text-sm font-medium text-gray-900">
                      {t('default.longitude')}
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      {...register('longitude', { required: true })}
                      className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    />
                    {errors.longitude && errors.longitude.message.split("|").map((error,index) => (
                      <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                        {error && t(`errors.${error}`)}
                      </span>
                    ))}
                  </div>
                  <div className="py-2 space-y-1">
                    <label htmlFor="contact_name" className="block text-sm font-medium text-gray-900">
                      {t('default.contactInfo')}
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder={t('users.table.fullName')}
                      {...register('contact_name', { required: true })}
                      className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    />
                    <Input
                      {...register('contact_number', { required: true })}
                      placeholder={t('users.table.phoneNumber')}
                      value={address?.contact_number}
                      autoComplete="off"
                      onChange={(value) => setValue('contact_number', value)}
                      className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    />
                    {errors.contact_name && errors.contact_name.message.split("|").map((error,index) => (
                      <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                        {error && t(`errors.${error}`)}
                      </span>
                    ))}
                    {errors.contact_number && errors.contact_number.message.split("|").map((error,index) => (
                      <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                        {error && t(`errors.${error}`)}
                      </span>
                    ))}
                  </div>
                  <div className="py-2 space-y-1">
                    <label htmlFor="contact_name_2" className="block text-sm font-medium text-gray-900">
                      {t('default.contactInfo')} {t('default.optional')}
                    </label>
                    <input
                      type="text"
                      autoComplete="off"
                      placeholder={t('users.table.fullName')}
                      {...register('contact_name_2')}
                      className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    />
                    <Input
                      {...register('contact_number_2')}
                      placeholder={t('users.table.phoneNumber')}
                      value={address?.contact_number_2}
                      autoComplete="off"
                      onChange={(value) => setValue('contact_number_2', value)}
                      className="block w-full shadow-sm sm:text-sm focus:ring-indigo-500 focus:border-indigo-500 border-gray-300 rounded-md"
                    />
                    {errors.contact_name_2 && errors.contact_name_2.message.split("|").map((error,index) => (
                      <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                        {error && t(`errors.${error}`)}
                      </span>
                    ))}
                    {errors.contact_number_2 && errors.contact_number_2.message.split("|").map((error,index) => (
                      <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                        {error && t(`errors.${error}`)}
                      </span>
                    ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex-shrink-0 px-4 py-4 flex justify-end">
          <button
            type="button"
            className="bg-white py-2 px-4 border border-gray-300 rounded-md shadow-sm text-sm font-medium text-gray-700 hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
            onClick={() => onCloseModal()}
          >
            {t('default.cancel')}
          </button>
          <button
            type="submit"
            className="ml-4 inline-flex justify-center py-2 px-4 border border-transparent shadow-sm text-sm font-medium rounded-md text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
          >
            {t('default.save')}
          </button>
        </div>
      </form>
  );
};

export default AddressModal;
