import React from 'react';
import {MenuAlt1Icon, UserCircleIcon} from "@heroicons/react/solid";
import {Link} from "react-router-dom";

const MobileHeader = ({user, setSidebarOpen}) => {
  return (
    <div className="relative z-10 flex-shrink-0 flex h-16 bg-white border-b border-gray-200 lg:hidden">
      <button
        className="px-4 border-r border-gray-200 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-purple-500 lg:hidden"
        onClick={() => setSidebarOpen(true)}
      >
        <span className="sr-only">Open sidebar</span>
        <MenuAlt1Icon className="h-6 w-6" aria-hidden="true"/>
      </button>
      <div className="flex-1 flex justify-between px-4 sm:px-6 lg:px-8">
        <div className="flex-1 flex"></div>
        <div className="flex items-center">

          <div>
            <button
              className="max-w-xs bg-white flex items-center text-sm rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-purple-500">

              <Link to="/profile/info">
                <span className="sr-only">Open user menu</span>
                {user.avatar ? (
                  <img
                    className="h-8 w-8 rounded-full"
                    src="https://images.unsplash.com/photo-1502685104226-ee32379fefbe?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=facearea&facepad=2&w=256&h=256&q=80"
                    alt=""
                  />
                ) : (
                  <UserCircleIcon className='h-8 w-8 rounded-full' />
                )}
              </Link>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileHeader;
