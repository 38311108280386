export const CLOSE_MODAL = 'CLOSE_MODAL';
export const OPEN_ADDRESS_MODAL = 'OPEN_ADDRESS_MODAL';
export const OPEN_ROUTE_ADDRESS_MODAL = 'OPEN_ROUTE_ADDRESS_MODAL';
export const OPEN_BOX_MODAL = 'OPEN_BOX_MODAL';
export const OPEN_DRIVERS_MODAL = 'OPEN_DRIVERS_MODAL';
export const OPEN_TRUCKS_MODAL = 'OPEN_TRUCKS_MODAL';
export const OPEN_ORDERS_MODAL = 'OPEN_ORDERS_MODAL';
export const OPEN_ADD_BOX_MODAL = 'OPEN_ADD_BOX_MODAL';
export const OPEN_ADD_USERS_MODAL = 'OPEN_ADD_USERS_MODAL';
export const OPEN_BOX_DETAILS = 'OPEN_BOX_DETAILS';
export const OPEN_ROUTE_GENERATOR = 'OPEN_ROUTE_GENERATOR';

export function closeModal(){
  return (dispatch, getState) => {
    const state = getState();
    const { modal: {onOk, options}} = state;

    if(onOk){
      switch(onOk){
        case "orderModal": {
          if(options.selectedUser){
            dispatch(openOrdersModal(null, options.selectedUser));
          }else{
            dispatch(openOrdersModal());
          }

          break;
        }
        default: {
          onOk();
          dispatch({
            type: CLOSE_MODAL
          });
          break;
        }
      }
    }else{
      dispatch({
        type: CLOSE_MODAL
      });
    }
  }
}

export function openAddressModal(data, onOk = null, user = null) {
  return (dispatch) => {
    dispatch({
      type: OPEN_ADDRESS_MODAL,
      onOk,
      options: {
        address: data,
        selectedUser: user
      }
    });
  }
}

export function openRouteAddressModal(data, onOk = null, user = null) {
  return (dispatch) => {
    dispatch({
      type: OPEN_ROUTE_ADDRESS_MODAL,
      onOk,
      options: {
        address: data,
        selectedUser: user
      }
    });
  }
}

export function openBoxModal(data, onOk) {
  return {
    type: OPEN_BOX_MODAL,
    onOk,
    options: {
      box: data
    }
  }
}

export function openDriversModal(data, onOk) {
  return {
    type: OPEN_DRIVERS_MODAL,
    onOk,
    options: {
      driver: data
    }
  }
}

export function openTrucksModal(data, onOk) {
  return {
    type: OPEN_TRUCKS_MODAL,
    onOk,
    options: {
      truck: data
    }
  }
}

export function openOrdersModal(data, user_id = null){
  return {
    type: OPEN_ORDERS_MODAL,
    options: {
      order: data,
      selectedUser: user_id
    }
  }
}

export function openAddBoxesModal(data, onOk, specialRequest){
  return {
    type: OPEN_ADD_BOX_MODAL,
    onOk,
    options: {
      order: data,
      specialRequest
    },
  }
}

export function openAddUsersModal(data, onOk){
  return {
    type: OPEN_ADD_USERS_MODAL,
    onOk,
    options: {
      user: data
    },
  }
}

export function openBoxDetails(data, canEdit, onOk){
  return {
    type: OPEN_BOX_DETAILS,
    onOk,
    options: {
      box: data,
      canEdit
    },
  }
}

export function openRouteGeneratorModal(data, onOk){
  return {
    type: OPEN_ROUTE_GENERATOR,
    onOk,
    options: {
      box: data,
    },
  }
}
