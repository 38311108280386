import React from 'react';
import { useTranslation } from "react-i18next";

const TableFilters = ({setFilters, filters, action, statuses = null}) => {
  const { t } = useTranslation();
  const updateFilters = (e) => {
    const { name, value } = e.target;
    setFilters({...filters, page: 1, [name]: value});
  }

  return (
    <div>
      <div className="align-middle rounded-tl-lg rounded-tr-lg inline-block w-full py-4 bg-gray-50 px-5">
        <div className="flex justify-between">
          <div className="inline-flex border rounded w-6/12 pl-2 lg:pl-6 h-12 bg-transparent">
            <div className="flex flex-wrap items-stretch w-full h-full mb-6 relative">
              <div className="flex">
                <span
                  className="flex items-center leading-normal bg-transparent rounded rounded-r-none border border-r-0 border-none py-3 whitespace-no-wrap text-grey-dark text-sm"
                >
                   <svg width="18" height="18" className="w-4 lg:w-auto" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M8.11086 15.2217C12.0381 15.2217 15.2217 12.0381 15.2217 8.11086C15.2217 4.18364 12.0381 1 8.11086 1C4.18364 1 1 4.18364 1 8.11086C1 12.0381 4.18364 15.2217 8.11086 15.2217Z" stroke="#455A64" strokeLinecap="round" strokeLinejoin="round"/>
                    <path d="M16.9993 16.9993L13.1328 13.1328" stroke="#455A64" strokeLinecap="round" strokeLinejoin="round"/>
                  </svg>
                </span>
              </div>
              <input
                type="text"
                name="search"
                className="flex-shrink flex-grow flex-auto leading-normal tracking-wide w-px flex-1 border border-none border-l-0 rounded rounded-l-none relative focus:outline-none text-xxs lg:text-xs lg:text-base text-gray-500 font-thin"
                placeholder={t('default.search')}
                value={filters?.search}
                onChange={(e) => updateFilters(e)}
                onKeyDown={ event => {
                  if(event.key === 'Enter'){
                    action();
                  }
                }}
              />
            </div>
          </div>
          {statuses && statuses.length > 0 && (
            <div className="inline-flex w-2/12 mx-3 sm:mr-5 items-stretch bg-transparent flex">
              <select
                name="status"
                defaultValue={filters?.status}
                onChange={(e) => updateFilters(e)}
                className="justify-end block text-base border-gray-300 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 sm:text-sm rounded-md"
              >
                <option value="0">{t('orders.items.status')}</option>
                {Object.values(statuses).map(status => (
                  <option
                    key={status.id}
                    value={status.id}
                  >
                    {t(`orders.items.${status.name}`)}
                  </option>
                ))}
              </select>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default TableFilters;
