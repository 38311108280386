import React, { Suspense } from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import { BrowserRouter as Router, Route } from "react-router-dom";
import { QueryParamProvider } from 'use-query-params';
import { Provider} from "react-redux";
import store from './store';
import "./i18nextConf";
import Loading from "./pages/Loading";

ReactDOM.render(
    <Provider store={store}>
      <Router>
        <QueryParamProvider ReactRouterRoute={Route}>
          <Suspense fallback={<Loading />}>
            <App />
          </Suspense>
        </QueryParamProvider>
      </Router>
    </Provider>,
  document.getElementById('root')
);
