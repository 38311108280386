import React, { useCallback, useEffect, useState } from 'react';
import { connect } from "react-redux";
import PageHeaderSection from "../../UI-Components/PageHeaderSection";
import EmptyStates from "../../UI-Components/EmptyStates";
import { useDispatch } from "react-redux";
import { openAddressModal, openRouteAddressModal } from "../../../actions/Modal";
import ConfirmDialog from "../../UI-Components/ConfirmDialog";
import ClientRequest from "../../../Services/ClientRequest";
import { useTranslation } from "react-i18next";

const Index = ({onOpenRouteAddressModal}) => {
  const { t } = useTranslation();
  const [addresses, setAddresses] = useState([]);
  const forceUpdate = useCallback(() => fetchRecords(), []);
  const [ confirmDelete, setConfirmDelete ] = useState(false);
  const dispatch = useDispatch();
  const request = new ClientRequest(dispatch);

  useEffect(() => {
    fetchRecords();
  }, []);

  const fetchRecords = () => {
    request.read('route-addresses')
      .then((res) => {
        setAddresses(res.data);
      }).catch((err) => {
      console.log(err);
    });
  }

  const onDeleteAddress = () => {
    const id = confirmDelete.id;

    request.delete(`route-addresses/${id}`)
      .then((res) => {
        const {success} = res;
        if(success){
          fetchRecords();
        }
      })
      .catch((err) => {
        console.log('error', err);
      });
    setConfirmDelete(false);
  }

  return (
    <div>
      {addresses.length > 0 ? (
        <div>
          <PageHeaderSection title={t('admin.routeAddress.title')} action={() => onOpenRouteAddressModal(null, forceUpdate)} actionTitle={t('default.create')} />
          <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 py-8">
            {addresses.map((address, index) => (
              <div
                key={index}
                className="relative rounded-lg border border-gray-300 bg-white px-6 py-5 shadow-sm flex items-center space-x-3 hover:border-gray-400"
              >
                <div className="flex-1 min-w-0">
                  <span className="absolute inset-0" aria-hidden="true"/>
                  <p className="text-sm font-medium text-gray-900">{`${address.country.name} - ${address.state.name} - ${address.zipcode}`}</p>
                  <p className="text-sm text-gray-500 truncate">{`${address.address_1} - ${address.address_2}`}</p>
                  <p className="text-sm text-gray-500 truncate">{`${address.contact_name} - ${address.contact_number}`}</p>
                </div>
                <div className="min-w-0 space-x-2 z-10">
                  <button
                    type="button"
                    onClick={() => onOpenRouteAddressModal(address, forceUpdate)}
                    className="cursor-pointer inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {t('default.edit')}
                  </button>
                  <button
                    type="button"
                    onClick={() => setConfirmDelete(address)}
                    className="cursor-pointer inline-flex items-center px-4 py-2 border border-transparent text-sm font-medium rounded-md shadow-sm text-white bg-indigo-600 hover:bg-indigo-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500"
                  >
                    {t('default.delete')}
                  </button>
                </div>
              </div>
            ))}
          </div>

          {confirmDelete && (
            <ConfirmDialog
              title={t('admin.addresses.delete.title')}
              description={t('admin.addresses.delete.description')}
              buttonTitle={t('admin.addresses.delete.action')}
              action={onDeleteAddress}
              close={setConfirmDelete}
            />
          )}
        </div>
      ): (
        <div>
          <PageHeaderSection title={t('admin.addresses.title')} description={t('admin.addresses.description')} />
          <EmptyStates action={() => onOpenRouteAddressModal(null, forceUpdate)} state={t('default.address')} />
        </div>
      )}
    </div>
  );
};

const mapDispatchToProps = dispatch => (
  {
    onOpenRouteAddressModal: (data = null, callback = null) => dispatch(openRouteAddressModal(data, callback)),
  }
);

export default connect(null, mapDispatchToProps)(Index);
