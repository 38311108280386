import React, { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from "react-redux";
import ClientRequest from "../../Services/ClientRequest";
import OrderSummary from "../UI-Components/Orders/OrderSummary";
import OrderDetailSidebar from "../UI-Components/Orders/OrderDetailSidebar";
import OrderBoxes from "../UI-Components/Orders/OrderBoxes";
import OrderPayments from "../UI-Components/Orders/OrderPayments";
import { openAddBoxesModal, openBoxDetails } from "../../actions/Modal";
import OrdersAddresses from "../UI-Components/Orders/OrdersAddresses";
import { useParams } from 'react-router-dom';
import ConfirmDialog from "../UI-Components/ConfirmDialog";
import { toast } from "react-toastify";
import { canDo } from "../../actions/Auth";
import { useTranslation } from "react-i18next";
import PromptPriceDialog from "../UI-Components/PromptPriceDialog";

const OrderDetail = ({user, onOpenAddBoxesModal, onOpenBoxDetails}) => {
  const { t } = useTranslation();
  const forceUpdate = useCallback(() => fetchRecords(), []);
  const [confirmDelete, setConfirmDelete] = useState(false);
  const [confirmSubmit, setConfirmSubmit] = useState(false);
  const [missingBoxesUponSubmit, setMissingBoxesUponSubmit] = useState(false);
  const [totalPriceDialog, setTotalPriceDialog] = useState(false);
  const [isAdmin, setIsAdmin] = useState(true);
  const [order, setOrder] = useState(null);
  const [categories, setCategories] = useState([]);
  const dispatch = useDispatch();
  const request = new ClientRequest(dispatch);
  const {id: orderId} = useParams();
  const boxDeleted = () => toast.success(t('order.messages.boxDeleted'));
  const orderUpdated = () => toast.success(t('order.messages.orderUpdated'));

  useEffect(() => {
    fetchRecords();
    setIsAdmin(canDo(user));
  },[]);

  useEffect(() => {
    request.read(`category-details`)
      .then((res) => {
        const {data: order} = res;
        setCategories(order);
      })
      .catch(() => console.log('error shipment'));
  },[]);

  const fetchRecords = () => {
    setOrder(null);
    request.read(`shipment/${orderId}`)
      .then((res) => {
        const {data: order} = res;
        setOrder(order);
      })
      .catch(() => console.log('error shipment'));
  }

  const onDeleteBoxes = () => {
    const id = confirmDelete.id;

    request.delete(`shipment-details/${id}`)
      .then(() => {
        const boxes = Object.values(order.details).filter(el => el.id !== id);
        setOrder({
          ...order,
          details: boxes
        });
        boxDeleted();
        fetchRecords();
      })
      .catch((err) => console.log('error', err));
    setConfirmDelete(false);
  }

  //use this one to update all aspects of the order
  const updateOrder = (totalPrice = false) => {
    const url = `shipment/${order.id}`;

    const payload = {};

    if(confirmSubmit){
      payload.status = confirmSubmit.id;
    }

    if(totalPrice){
      payload.total_price = totalPrice;
    }

    request.update(url, payload)
      .then((res) => {
        orderUpdated();
        setConfirmSubmit(false);
        forceUpdate();
      })
      .catch((err) => console.log(err));
  }

  return (
    <div>
      <main className="flex-1">
        <div className="px-2 py-5">
          <div className="max-w-full mx-auto px-8 sm:px-6 xl:grid xl:grid-cols-3">
            <div className="xl:col-span-2 xl:pr-8 xl:border-r xl:border-gray-200">
              <div className="pb-4">
                {order && (
                  <div>
                    <OrderSummary
                      isAdmin={isAdmin}
                      onSubmitOrder={setConfirmSubmit}
                      setMissingBoxesUponSubmit={setMissingBoxesUponSubmit}
                      setTotalPriceDialog={setTotalPriceDialog}
                      order={order} />
                    <OrdersAddresses order={order} />
                  </div>
                )}
              </div>
              <hr/>
              <aside className="mt-8 xl:hidden">
                {order && (
                  <OrderDetailSidebar order={order} />
                )}
              </aside>
              {order && (
                <OrderBoxes
                  isAdmin={isAdmin}
                  onDeleteBox={setConfirmDelete}
                  order={order}
                  onOpenBoxDetails={onOpenBoxDetails}
                  onOpenAddBoxesModal={onOpenAddBoxesModal}
                  forceUpdate={forceUpdate}
                  categories={categories}
                />
              )}
              {order?.payments && (
                <OrderPayments order={order}/>
              )}
            </div>
            <aside className="hidden xl:block xl:pl-8">
              {order && (
                <OrderDetailSidebar order={order} />
              )}
            </aside>
          </div>

          {confirmDelete && (
            <ConfirmDialog
              title={t(`box.messages.delete.title`)}
              description={t(`box.messages.delete.description`)}
              buttonTitle={t(`box.messages.delete.action`)}
              action={onDeleteBoxes}
              close={setConfirmDelete}
            />
          )}

          {confirmSubmit && (
            <ConfirmDialog
              title={`${t(`order.messages.submit.title`)} ${t(`orders.items.${confirmSubmit.name}`)}?`}
              description={t('order.messages.submit.description')}
              buttonTitle={t('order.messages.submit.action')}
              action={updateOrder}
              close={setConfirmSubmit}
              type='add'
            />
          )}

          {missingBoxesUponSubmit && (
            <ConfirmDialog
              title={t(`order.messages.boxes.title`)}
              description={t('order.messages.boxes.description')}
              buttonTitle={t('order.messages.boxes.action')}
              action={() => {onOpenAddBoxesModal(order, forceUpdate); setMissingBoxesUponSubmit(false)}}
              close={setMissingBoxesUponSubmit}
              type='add'
            />
          )}

          {totalPriceDialog && (
            <PromptPriceDialog
              title={t(`order.messages.price.title`)}
              description={t('order.messages.price.description')}
              buttonTitle={t('order.messages.price.action')}
              action={(price) => {updateOrder(price); setTotalPriceDialog(false)}}
              close={setTotalPriceDialog}
              type='add'
            />
          )}
        </div>
      </main>
    </div>
  );
};

const stateToProps = state => (
  {
    user: state.user.info,
  }
);

const dispatchToProps = dispatch => (
  {
    onOpenAddBoxesModal: (order, callback, category = null) => dispatch(openAddBoxesModal(order, callback, category)),
    onOpenBoxDetails : (box, canEdit, callback) => dispatch(openBoxDetails(box, canEdit, callback))
  }
);

export default connect(stateToProps, dispatchToProps)(OrderDetail);
