import { useEffect, useState } from 'react';
import { useDispatch } from "react-redux";
import ClientRequest from "../ClientRequest";

const UseDrivers = () => {
  const [drivers, setDrivers] = useState([]);
  const dispatch = useDispatch();
  const request = new ClientRequest(dispatch);

  useEffect(() => {
    request.read('drivers?isRoute=true')
      .then((res) => {
        setDrivers(res.data);
      }).catch((err) => console.log(err));
  },[]);

  return [drivers];
};

export default UseDrivers;
