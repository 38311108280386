import React from 'react';

const OrderPayments = () => {
  return (
    <section aria-labelledby="activity-title" className="mt-8 xl:mt-10">
      <div>
        <div className="divide-y divide-gray-200">
          <div className="pb-4">
            <h2 id="activity-title" className="text-lg font-medium text-gray-900">Payments</h2>
          </div>
          <div className="pt-6">
            <div className="flow-root">
              <p>Details Content</p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default OrderPayments;
