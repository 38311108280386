import {UPDATE_ADMIN_MENU, UPDATE_MENU, UPDATE_USER_MENU} from '../actions/Menu';
import { CogIcon, HomeIcon, UsersIcon, UserIcon, LocationMarkerIcon, CubeIcon, TruckIcon, MapIcon } from "@heroicons/react/outline";
import Boxes from '../components/Admin/Boxes';
import Address from '../components/Profile/Address';
import Info from '../components/Profile/Info';
import PasswordReset from '../components/Profile/PasswordReset';
import Drivers from '../components/Admin/Drivers';
import Trucks from '../components/Admin/Trucks';
import RouteAddress from '../components/Admin/RouteAddress';

const initialState = {
  items: [
    { id: 1, name: 'dashboard', restricted: false, href: '/', icon: HomeIcon, current: false },
    { id: 2, name: 'routeGenerator', restricted: true, href: '/route-generator', icon: MapIcon, current: false },
    { id: 3, name: 'users', restricted: true, href: '/users', icon: UsersIcon, current: false },
    { id: 4, name: 'adminPanel', restricted: true, href: '/admin/boxes', icon: CogIcon, current: false },
  ],
  admin: [
    { id: 1, name: 'boxes', href: '/admin/boxes', restricted: true, linkName: 'boxes', component: <Boxes />,icon: CubeIcon, current: true },
    { id: 2, name: 'drivers', href: '/admin/drivers', restricted: true, linkName: 'drivers', component: <Drivers />,icon: UserIcon, current: true },
    { id: 3, name: 'trucks', href: '/admin/trucks', restricted: true, linkName: 'trucks', component: <Trucks />,icon: TruckIcon, current: true },
    { id: 4, name: 'route-addresses', href: '/admin/route-addresses', restricted: true, linkName: 'route-addresses', component: <RouteAddress />,icon: LocationMarkerIcon, current: true },
  ],
  user: [
    { id: 1, name: 'info', href: '/profile/info', restricted: false, linkName: 'info',component: <Info />,icon: UserIcon, current: true },
    { id: 2, name: 'address',  href: '/profile/address', restricted: false, linkName: 'address',component: <Address />,icon: LocationMarkerIcon, current: false },
    { id: 3, name: 'passwordReset',  href: '/profile/password-reset', restricted: false, linkName: 'password-reset',component: <PasswordReset />,icon: CogIcon, current: false },
  ]
};

export default function(state = initialState, action){
  switch (action.type) {
    case UPDATE_MENU:{

      const items = state.items.map((item, index) => {
        return {...item, current: index === action.id}
      });

      return {
        ...state,
        items: items
      };
    }
    case UPDATE_ADMIN_MENU: {
      const items = state.admin.map((item, index) => {
        return {...item, current: index === action.id}
      });

      return {
        ...state,
        admin: items
      };
    }
    case UPDATE_USER_MENU: {
      const items = state.user.map((item, index) => {
        return {...item, current: index === action.id}
      });

      return {
        ...state,
        user: items
      };
    }
    default: {
      return {...state};
    }
  }
}
