import { Client } from "./Client"

export default class ClientRequest {
  constructor(dispatch) {
    this.dispatch = dispatch;
  }

  request(options){
    return Client(this.dispatch, options);
  }

  read(url) {
    return this.request({method: 'get',url});
  }

  create(url, data){
    return this.request({method: 'post', url, data});
  }

  update(url, data){
    return this.request({method: 'put', url, data});
  }

  delete(url){
    return this.request({method: 'delete', url});
  }
}
