import React from 'react';
import PropTypes from "prop-types";
import AuthLayout from "../components/Auth/AuthLayout";
import { useForm} from "react-hook-form";
import SubmitButton from "../components/Auth/submitButton";
import { useDispatch } from "react-redux";
import { registerUser } from "../Services/authService";
import { registered } from "../actions/Auth";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Input from "react-phone-number-input/input";
import processServerResponse from "../Services/Utils/ProcessServerResponse";

const Register = () => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit , setValue, setError, formState: { errors }} = useForm();
  const inputClass = 'appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm';

  const onSubmit = data => {
    registerUser(data).then((res) => {
      const { data } = res;

      if(!data.success){
        processServerResponse(data, setError, null, null);
      }else{
        dispatch(registered());
        history.push('/');
      }
    }).catch((error) => console.log(error));
  }

  return (
      <AuthLayout title={t('register.title')} displayBrand={true}>
        <form className="mt-2 space-y-6" onSubmit={handleSubmit(onSubmit)}>
          <input type="hidden" name="remember" value="true" />
          <div className="rounded-md shadow-sm space-y-3">
            <div>
              <label htmlFor="firstname" className="sr-only">{t('default.firstName')}</label>
              <input
                type="text"
                autoComplete="off"
                {...register('firstname', { required: true })}
                className={inputClass}
                placeholder={t('default.firstName')}
              />
              {errors.phone_firstnamenumber && errors.firstname.message.split("|").map((error,index) => (
                <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                  {error && t(`errors.${error}`)}
                </span>
              ))}
            </div>
            <div>
              <label htmlFor="lastname" className="sr-only">{t('default.lastName')}</label>
              <input
                type="text"
                autoComplete="off"
                {...register('lastname', { required: true })}
                className={inputClass}
                placeholder={t('default.lastName')}
              />
              {errors.lastname && errors.lastname.message.split("|").map((error,index) => (
                <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                  {error && t(`errors.${error}`)}
                </span>
              ))}
            </div>
            <div>
              <label htmlFor="phone_number" className="sr-only">{t('default.phoneNumber')}</label>
              <Input
                {...register('phone_number')}
                placeholder={t('users.table.phoneNumber')}
                autoComplete="off"
                onChange={(value) => setValue('phone_number', value)}
                className={inputClass}
              />
              {errors.phone_number && errors.phone_number.message.split("|").map((error,index) => (
                <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                  {error && t(`errors.${error}`)}
                </span>
              ))}
            </div>
            <div>
              <label htmlFor="email-address" className="sr-only">{t('default.emailAddress')}</label>
              <input
                type="email"
                autoComplete="off"
                {...register('email', { required: true })}
                className={inputClass}
                placeholder={t('default.emailAddress')}
              />
              {errors.email && errors.email.message.split("|").map((error,index) => (
                <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                  {error && t(`errors.${error}`)}
                </span>
              ))}
            </div>
            <div>
              <label htmlFor="password" className="sr-only">{t('default.password')}</label>
              <input
                type="password"
                autoComplete="off"
                {...register('password', { required: true })}
                className={inputClass}
                placeholder={t('default.password')}
              />
              {errors.password && errors.password.message.split("|").map((error,index) => (
                <span key={index} className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>
                  {error && t(`errors.${error}`)}
                </span>
              ))}
            </div>
          </div>
          <div>
            <SubmitButton text={t('register.submit')} />
          </div>
          <div className="text-sm flex justify-center">
            <Link to='/login' className="text-indigo-600 hover:text-indigo-500">
              {t('default.logIntoYourAccount')}
            </Link>
          </div>
        </form>
      </AuthLayout>
  );
};

Register.propTypes = {
  onSignIn: PropTypes.func
}

Register.defaultProps = {
  onSignIn: null,
};

export default Register;
