import React, { useEffect, useState } from 'react';

const UseOrderHasBoxContent = (order) => {
  const [existBoxes, setExistBoxes] = useState(false);

  useEffect(() => {
    if(order){
      let canContinue = true;
      for(let i = 0; i < order.details.length; i++){
        if(order.details[i].box_content.length === 0){
          canContinue = false;
        }
      }
      setExistBoxes(canContinue);
    }
  },[order]);

  return [existBoxes]
};

export default UseOrderHasBoxContent;
