import React from 'react';
import { Link } from "react-router-dom";
import Table2 from "../Table2";
import TableFilters from "../TableFilters";
import { useTranslation } from "react-i18next";

const OrdersTable = ({data, filters, setFilters, statuses, fetchRecords}) => {
  const { t } = useTranslation();

  return (
    <Table2 setFilters={setFilters} filters={filters}>
      {filters && (
        <TableFilters setFilters={setFilters} filters={filters} action={fetchRecords} statuses={statuses} />
      )}
      <table className="min-w-full divide-y divide-gray-200">
        <thead className="bg-gray-50">
        <tr>
          <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
            {t('orders.table.customer')}
          </th>
          <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
            {t('orders.table.pickupAddress')}
          </th>
          <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
            {t('orders.table.deliveryAddress')}
          </th>
          <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider sm:hidden lg:table-cell">
            {t('orders.table.boxes')}
          </th>
          <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider sm:hidden lg:table-cell">
            {t('orders.table.createdDate')}
          </th>
          <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
            {t('orders.table.status')}
          </th>
          <th scope="col" className="px-6 py-3 text-center text-xs font-medium text-gray-500 uppercase tracking-wider">
            {t('default.actions')}
          </th>
        </tr>
        </thead>
        <tbody>
        {Object.keys(data).length > 0 ? (
          <React.Fragment>
            {Object.values(data).map((item, index) => (
              <tr key={index} className="bg-white">
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                  <span>{item?.customer?.firstname} {item?.customer?.lastname}</span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                  <span>{item?.pickup_address?.address_1} - {item?.pickup_address?.zipcode}</span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                  <span>{item?.delivery_address?.address_1} - {item?.pickup_address?.zipcode}</span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center sm:hidden lg:table-cell">
                  <span>{item?.details?.length}</span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center sm:hidden lg:table-cell">
                  <span>{item?.created_at}</span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">

                  <span>{t(`orders.items.${item.status.name}`)}</span>
                </td>
                <td className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 text-center">
                  <Link to={`/orders/${item.id}`} >{t('default.view')}</Link>
                </td>
              </tr>
            ))}
          </React.Fragment>
        ): (
          <tr className="bg-white">
            <td colSpan="7" className="px-6 py-4 whitespace-nowrap text-sm text-gray-500 flex justify-center">{t('default.nothingToShow')}</td>
          </tr>
        )}
        </tbody>
      </table>
    </Table2>
  );
};

export default OrdersTable;
