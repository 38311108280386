import { useEffect, useState } from 'react';

const UseUserCanSendOrder = (order) => {
  const [userCanEdit, setUserCanEdit] = useState(false);

  useEffect(() => {
    if(order){
      const canEdit = order.status.id === 4; //Reviewed
      setUserCanEdit(canEdit);
    }
  },[order]);

  return [userCanEdit, setUserCanEdit];
};

export default UseUserCanSendOrder;
