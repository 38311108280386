import React from 'react';
import { connect } from "react-redux";
import PageHeader from "../UI-Components/PageHeader";
import NavTabs from "../UI-Components/NavTabs";
import { updateUserMenu } from "../../actions/Menu";
import { useTranslation } from "react-i18next";

const Index = ({user, userMenu, updateUserMenu, match}) => {
  const { t } = useTranslation();
  const paramName = match.params.name;
  const activeMenuItem = userMenu.find((item) => item.linkName === paramName);

  return (
    <div>
        <PageHeader title={t('admin.title')} />
        <div className="px-8">
          <NavTabs tabs={userMenu} user={user} activeItem={activeMenuItem} handleClick={updateUserMenu} />
            <div className="py-4">
              {
                activeMenuItem.component
              }
            </div>
        </div>
    </div>
  );
};

const mapStateToProps = state => ({
  userMenu: state.menu.user,
  user: state.user.info,
});

const mapDispatchToProps = dispatch => (
  {
    updateUserMenu: (id) => dispatch(updateUserMenu(id)),
  }
);

export default connect(mapStateToProps, mapDispatchToProps)(Index);
