import React, { useEffect, useState } from 'react';
import { CalendarIcon, CubeIcon, CurrencyDollarIcon, LockOpenIcon } from "@heroicons/react/solid";
import { useTranslation } from "react-i18next";
import useIsOrderReviewed from "../../../Services/CustomHooks/useIsOrderReviewed";
import useIsOrderInProgress from "../../../Services/CustomHooks/useIsOrderInProgress";
import useIsOrderInWareHouse from "../../../Services/CustomHooks/useIsOrderInWareHouse";
import useCalculateEstimatedPrice from "../../../Services/CustomHooks/useCalculateEstimatedPrice";
import Map from "../Maps/Map";
import Alert from "../alert";
import QRCode from "qrcode.react";

const OrderDetailSidebar = ({order}) => {
  const { t } = useTranslation();
  const [isReviewed] = useIsOrderReviewed(order);
  const [isOrderInProgress] = useIsOrderInProgress(order);
  const [inWareHouse] = useIsOrderInWareHouse(order);
  const [estimatedPrice, isPriceComplete] = useCalculateEstimatedPrice(order);
  const [routes, setRoutes] = useState([]);
  const [isMapReady, setIsMapReady] = useState(false);

  useEffect(() => {
    setRoutes([]);
    const {latitude: initLat, longitude: initLon} = order.pickup_address;
    const {latitude: endLat, longitude: endLong} = order.delivery_address;

    const ports = {
      "miami": ['25.771341', '-80.1629589'],
      "new-jersey": ['40.6891201', '-74.1240285'],
      "limon": ['9.991650', '-83.026227'],
    }

    //Delivery to CR
    if(order.delivery_address.country_id === 1){
      if(inWareHouse){
        //In CR
        setRoutes([ports['limon'], [endLat, endLong]]);
      }else{
        //In US
        switch(order.pickup_address.state.abbreviation){
          case 'TX':
          case 'AR':
          case 'TN':
          case 'LA':
          case 'AL':
          case 'MS':
          case 'OH':
          case 'FL':{
            setRoutes([[initLat, initLon], ports['miami']]);
            break;
          }
          case 'MD':
          case 'DC':
          case 'DE':
          case 'VG':
          case 'NC':
          case 'SC':
          case 'GA':
          case 'MA':
          case 'CT':
          case 'NY':
          case 'NJ':
          case 'PA':{
            setRoutes([[initLat, initLon], ports["new-jersey"]]);
            break;
          }
          default:{
            setRoutes([[initLat, initLon], [endLat, endLong]]);
            break;
          }
        }
      }
    }else{
      setRoutes([[initLat, initLon], [endLat, endLong]]);
    }

  },[order, inWareHouse]);

  useEffect(() => {
    if(routes){
      setIsMapReady(true);
    }
  }, [routes])

  return (
    <div>
      <h2 className="sr-only">Details</h2>
      <div className="space-y-5">
        <div className="sm:flex lg:block sm:justify-between">
          <div className="flex items-center space-x-2 sm:my-3">
            <LockOpenIcon className="h-5 w-5"/>
            <span className="text-green-700 text-sm font-medium">{t(`orders.items.${order?.status?.name}`)}</span>
          </div>
          <div className="flex items-center space-x-2 sm:my-3">
            <CurrencyDollarIcon className="h-5 w-5"/>
            {isReviewed ? (
              <span className="text-white bg-green-500 text-sm font-medium">{t('default.price')}: ${order?.total_price}</span>
            ): (
              <React.Fragment>
                {isOrderInProgress || order.total_price > 0 ? (
                  <span className="text-gray-900 text-sm font-medium">{t('default.price')}: ${order?.total_price}</span>
                ): (
                  <span className="text-gray-900 text-sm font-medium">{t('default.estimatedPrice')}: ${estimatedPrice}</span>
                )}
              </React.Fragment>
            )}
          </div>
          <div className="flex items-center space-x-2 sm:my-3">
            <CubeIcon className="h-5 w-5"/>
            <span className="text-gray-900 text-sm font-medium">{order?.details.length} {t('default.boxes')}</span>
          </div>
          <div className="flex items-center space-x-2 sm:my-3">
            <CalendarIcon className="h-5 w-5"/>
            <span className="text-gray-900 text-sm font-medium">{t('default.createdDate')} <time dateTime="2020-12-02">{order?.created_at}</time></span>
          </div>
        </div>
      </div>
      {isMapReady && isOrderInProgress && (
        <div className="mt-6 border-t border-gray-200 py-6 space-y-8">
            <div className='order-sidebar-map'>
              <h2 className="text-sm font-medium text-gray-500">{t('default.map')}</h2>
                <Map routes={routes} positionCenter={routes[0]} zoom={4}/>
            </div>
        </div>
      )}
      <div className="mt-3 border-t border-gray-200 py-3 space-y-8">
        <div>
          <Alert title={`${t('default.termsConditions')}`} link="https://globalxpressint.com/terminos-y-condiciones/" type="neutral"/>
        </div>
        {isReviewed ? (
          <Alert title={t('default.newPriceHasBeenSetUp')} type="success"/>
          ): (
          <React.Fragment>
            {(!isOrderInProgress || order.total_price == 0 ) && (
              <React.Fragment>
                {!isPriceComplete && (
                  <Alert title={t('order.pendingToSetPrice')} type="warning"/>
                )}
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    </div>
  );
};

export default OrderDetailSidebar;
