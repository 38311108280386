import React from 'react';

const AuthLayout = ({displayBrand, children, title}) => {
  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-50 py-2 px-4 lg:px-2">
      <div className="max-w-md w-full space-y-8 -mt-36">
        {displayBrand && (
          <div>
            <img
              className="mx-auto h-24 w-auto"
              src="/logo-global2.png" alt="Workflow"
            />
            <h2 className="mt-6 text-center text-3xl font-extrabold text-gray-900">
              {title}
            </h2>
          </div>
        )}
        {children}
      </div>
    </div>
  );
};

export default AuthLayout;
