import {
  UPDATE_USER_INFO,
  ADD_ADDRESS,
  EDIT_ADDRESS,

  LOAD_USER, LOAD_ADDRESS, DELETE_ADDRESS
} from "../actions/User";

const initialState = {
  info: {},
  addresses: [],
}

export default function(state = initialState, action) {
  switch (action.type) {
    case LOAD_USER: {
      return {
        ...state,
        info: action.value
      }
    }
    case UPDATE_USER_INFO: {
      return {
        ...state,
        info: action.value,
      }
    }
    case LOAD_ADDRESS: {
      return {
        ...state,
        addresses: action.value
      }
    }
    case ADD_ADDRESS: {
      return {
        ...state,
        addresses: [
          ...state.addresses,
          action.value,
        ]
      }
    }
    case EDIT_ADDRESS: {
      const addresses = [...state.addresses];
      const index = addresses.findIndex(obj => obj.id === action.id);
      addresses[index] = {...action.value};

      return {
        ...state,
        addresses
      }
    }
    case DELETE_ADDRESS: {
      const addresses = [...state.addresses];
      const index = addresses.findIndex(obj => obj.id === action.id);
      addresses.splice(index, 1);

      return {
        ...state,
        addresses
      }
    }
    default: {
      return state;
    }
  }
}
