import React from 'react';
import { useTranslation } from "react-i18next";

const OrdersAddresses = ({order}) => {
  const { t } = useTranslation();

  return (
    <div className="py-3 xl:pt-6 xl:pb-4 xl:pb-0">
      <h2 id="activity-title" className="text-lg font-medium text-gray-900">{t('default.addressDetails')}</h2>
      <div className="flex w-full justify-around pt-5">
        <div className="w-1/2">
          <h4>{t('default.pickUpAddress')}</h4>
          <p>{t('default.address')}: {order.start_route.country.name}, {order.start_route.address_1} {order.start_route.address_2}, {order.start_route.zipcode}</p>
          <p>{t('default.contact')}: {order.start_route.contact_name} {order.start_route.contact_number}</p>
        </div>
        <div className="w-1/2">
          <h4>{t('default.deliveryAddress')}</h4>
          <p>{t('default.address')}: {order.end_route.country.name}, {order.end_route.address_1} {order.end_route.address_2}, {order.end_route.zipcode}</p>
          <p>{t('default.contact')}: {order.end_route.contact_name} {order.end_route.contact_number}</p>
        </div>
      </div>
    </div>
  );
};

export default OrdersAddresses;
