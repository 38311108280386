import { useEffect, useState } from 'react';

const useIsOrderInProgress = (order) => {
  const [inProgress, setInProgress] = useState(false);

  useEffect(() => {
    if(order){
      const reviewed = order.status.id > 4; //Greater than Reviewed
      setInProgress(reviewed);
    }
  },[order]);

  return [inProgress, setInProgress];
};

export default useIsOrderInProgress;
