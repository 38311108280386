import { LOGGED_IN, LOGGED_OUT, REGISTERED } from "../actions/Auth";

const initialState = {
  authenticated: false,
  registered: false
};

export default function(state = initialState, action){
  switch(action.type){
    case LOGGED_IN:
      return {...state, authenticated: true, registered: false};
    case LOGGED_OUT:
      return {...state, authenticated: false, registered: false};
    case REGISTERED:
      return {...state, registered: true}
    default:
      return state;
  }
}
