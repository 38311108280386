import React, { useState } from 'react';
import { withRouter } from "react-router";
import PropTypes from 'prop-types';
import { connect, useDispatch } from "react-redux";
import { getCurrentUser, login } from "../Services/authService";
import { useForm} from "react-hook-form";
import AuthLayout from "../components/Auth/AuthLayout";
import SubmitButton from "../components/Auth/submitButton";
import PopUp from "../components/UI-Components/PopUp";
import { Link, useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const authenticationMessages = {
  "registered" : {
    "title": "Account created successfully!",
    "description": "Login with the recently created credentials"
  }
}

const Login = ({registered}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const history = useHistory();
  const { register, handleSubmit } = useForm();
  const [errors, setErrors] = useState(false);
  const inputClass = 'appearance-none rounded-none relative block w-full px-3 py-2 border border-gray-300 placeholder-gray-500 text-gray-900 focus:outline-none focus:ring-indigo-500 focus:border-indigo-500 focus:z-10 sm:text-sm';

  const onSubmit = data => {
    setErrors(false);
    login(data)
      .then((res) => {
          //It Worked
          const { token } = res.data;
          if (token) {
            //setLocalStorage
            localStorage.setItem('accessToken', token);
            dispatch(getCurrentUser());
            history.push('/');
          }
      })
      .catch(error => {
        const {response: {data: {success, message }} } = error;
        if(!success){
          setErrors(message);
        }
      });
  }

  return (
    <AuthLayout title={t('login.title')} displayBrand={true}>
      <form className="mt-2 space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <input type="hidden" name="remember" value="true" />
        <div className="rounded-md shadow-sm space-y-3">
          <div>
            <label htmlFor="email-address" className="sr-only">{t('default.emailAddress')}</label>
            <input
              type="text"
              autoComplete="off"
              {...register('email', { required: true })}
              className={inputClass}
              placeholder={t('default.emailAddress')}
            />
          </div>
          <div>
            <label htmlFor="password" className="sr-only">{t('default.password')}</label>
            <input
              type="password"
              autoComplete="current-password"
              {...register('password', { required: true })}
              className={inputClass}
              placeholder={t('default.password')}
            />
          </div>
        </div>
        <div>
          {errors && (
            <span className='flex items-center font-medium tracking-wide text-red-500 text-xs mt-1 ml-1'>{t(`errors.${errors}`)}</span>
          )}
        </div>
        <div className="flex items-center justify-end">
          <div className="text-sm">
            <Link to='/forgot-password' className="font-medium text-indigo-600 hover:text-indigo-500">
              {t('login.forgotYourPassword')}
            </Link>
          </div>
        </div>
        <div>
          <SubmitButton text={t('login.submit')}/>
        </div>
        <div className="text-sm flex justify-center">
          <Link to='/register' className="text-indigo-600 hover:text-indigo-500">
            {t('default.orCreateAccount')}
          </Link>
        </div>
      </form>
      {registered && (
        <PopUp title={authenticationMessages.registered.title} description={authenticationMessages.registered.description}/>
      )}
    </AuthLayout>
  );
};

Login.propTypes = {
  onSignIn: PropTypes.func.isRequired
}

const stateToProps = state => (
  {
    registered: state.auth.registered,
  }
);

const dispatchToProps = dispatch => (
  {
    onSignIn: (data) => { dispatch(login(data)) },
  }
);
const LoginWithRouter = withRouter(Login);

export default connect(stateToProps, dispatchToProps)(LoginWithRouter);
