import L from "leaflet";
import { createControlComponent } from "@react-leaflet/core";
import "leaflet-routing-machine";
import 'leaflet-routing-machine/dist/leaflet-routing-machine.css'

const createRoutineMachineLayer = ({ routeSteps }) => {
  const waypoints = routeSteps.map(stop => (
     {lat: stop[0], lng: stop[1]}
  ));

  const instance = L.Routing.control({
    position: 'topleft',
    waypoints,
    //serviceUrl: "http://open.mapquestapi.com/directions/v2/route",
    router: L.Routing.mapbox("pk.eyJ1Ijoiam9zaGpyIiwiYSI6ImNsMGhuc283ZTAyeG8zam1sOXdxa2VpZHkifQ.qT39sxOB87yPz4nDaxbquA"),
    lineOptions: {
      styles: [
        {
          color: '#757de8',
        },
      ],
    },
  });

  return instance;
};

// Pass our createRoutingMachineLayer to the createControlHook:
const RoutingMachine = createControlComponent(createRoutineMachineLayer);

// Export
export default RoutingMachine;
