import React, { useEffect, useState } from 'react';
import PageHeader from "../UI-Components/PageHeader";
import useFilters from "../../Services/CustomHooks/useFilters";
import { useTranslation } from "react-i18next";
import RouteGeneratorTable from "../UI-Components/RouteGenerator/RouteGeneratorTable";
import { openRouteGeneratorModal } from "../../actions/Modal";
import { connect, useDispatch } from "react-redux";
import ClientRequest from "../../Services/ClientRequest";
import usePrevious from "../../Services/CustomHooks/usePrevious";
import ConfirmDialog from "../UI-Components/ConfirmDialog";

const Index = ({onOpenRouteGeneratorModal}) => {
  const { t } = useTranslation();
  const [orders, setOrders] = useState([]);
  const [filters, setFilters] = useFilters(true);
  const prevFilters = usePrevious(filters);
  const [ confirmDelete, setConfirmDelete ] = useState(false);

  const dispatch = useDispatch();
  const request = new ClientRequest(dispatch);

  useEffect(() => {
    fetchRecords(true);
  }, []);

  useEffect(() => {
    if(prevFilters?.page !== filters?.page){
      fetchRecords();
    }
  }, [filters]);

  const fetchRecords = (isFirstLoad = false) => {
    const copyFilters = {...filters};
    const { page } = copyFilters;

    request.read(`routes?page=${page}`).then((res)=> {
      const {data:{data, total, per_page, from, to, last_page} } = res;
      setOrders(data);

      if(isFirstLoad){
        setFilters({
          ...filters,
          pagination: {
            total,
            per_page,
            from,
            to,
            last_page
          }
        });
      }
    }).catch((err) => console.log(err));
  }

  const onDeleteRoute = () => {
    const id = confirmDelete.id;

    request.delete(`routes/${id}`)
      .then(() => {
        fetchRecords();
      })
      .catch((err) => console.log('error', err));
    setConfirmDelete(false);
  }

  return (
    <div>
      <div>
        <PageHeader title={t('routeGenerator.title')} action={onOpenRouteGeneratorModal} actionTitle={t('default.create')}/>
        <div className="px-8">
          <RouteGeneratorTable filters={filters} setFilters={setFilters} fetchRecords={fetchRecords} data={orders} deleteAction={setConfirmDelete}/>
        </div>
        {confirmDelete && (
          <ConfirmDialog
            title={'Desea borrar este registro'}
            description={t('routeGenerator.delete.description')}
            buttonTitle={t('default.delete')}
            action={onDeleteRoute}
            close={setConfirmDelete}
          />
        )}
      </div>
    </div>
  );
};

const dispatchToProps = dispatch => ({
  onOpenRouteGeneratorModal: (data = null) => dispatch(openRouteGeneratorModal(data))
});

export default connect(null, dispatchToProps)(Index);
