import React, { useEffect, useState } from 'react';
import PageHeaderSection from "../../UI-Components/PageHeaderSection";
import { connect, useDispatch } from "react-redux";
import { openDriversModal } from "../../../actions/Modal";
import ClientRequest from "../../../Services/ClientRequest";
import ConfirmDialog from "../../UI-Components/ConfirmDialog";
import useFilters from "../../../Services/CustomHooks/useFilters";
import DriversTable from "../../UI-Components/Drivers/DriversTable";
import usePrevious from "../../../Services/CustomHooks/usePrevious";
import { useTranslation } from "react-i18next";

const messages = {
  "delete" : {
    "title": "Delete Driver",
    "description": "Are you sure you want to delete this driver?",
    "action": 'Delete'
  }
}

const Index = ({onOpenDriversModal}) => {
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const [drivers, setDrivers] = useState([]);
  const [filters, setFilters] = useFilters();
  const prevFilters = usePrevious(filters);

  const [ confirmDelete, setConfirmDelete ] = useState(false);
  const request = new ClientRequest(dispatch);

  useEffect(() => {
    fetchRecords(true);
  },[]);

  useEffect(() => {
    if(prevFilters?.page !== filters?.page || prevFilters?.status !== filters?.status){
      fetchRecords();
    }
  }, [filters]);

  const fetchRecords = (isFirstLoad = false) => {
    const copyFilters = {...filters};
    const { page } = copyFilters;

    request.read(`drivers?page=${page}`).then((res)=> {
      const {data:{data, total, per_page, from, to, last_page} } = res;
      setDrivers(data);

      if(isFirstLoad){
        setFilters({
          ...filters,
          pagination: {
            total,
            per_page,
            from,
            to,
            last_page
          }
        });
      }
    }).catch((err) => console.log(err));
  }

  const onDeleteDriver = () => {
    const id = confirmDelete.id;

    request.delete(`drivers/${id}`)
      .then(() => {
        fetchRecords();
      })
      .catch((err) => console.log('error', err));
    setConfirmDelete(false);
  }

  return (
    <div>
      <div>
        <PageHeaderSection title={t('admin.drivers.title')} action={onOpenDriversModal} actionTitle={t('default.create')} callbackAction={fetchRecords}/>
        <DriversTable data={drivers} filters={filters} setFilters={setFilters} action={fetchRecords} deleteAction={setConfirmDelete} editAction={onOpenDriversModal} />
      </div>

      {confirmDelete && (
        <ConfirmDialog
          title={t('admin.drivers.delete.title')}
          description={t('admin.drivers.delete.description')}
          buttonTitle={t('admin.drivers.delete.action')}
          action={onDeleteDriver}
          close={setConfirmDelete}
        />
      )}
    </div>
  );
};

const mapDispatchToProps = dispatch => (
  {
    onOpenDriversModal: (data = null, callback = null) => dispatch(openDriversModal(data, callback)),
  }
);

export default connect(null, mapDispatchToProps)(Index);
