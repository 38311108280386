import React from 'react';

const Pagination = ({filters, updatePagination}) => {
  let totalPages = Array.from(Array(filters.pagination.last_page).keys());

  if(totalPages.length > 7){
    const index = totalPages.indexOf(filters.page) + 1;
    let start = 0;
    let finish = 7;

    if(filters.page >= 4){
      start = index - 5;
      finish = filters.page + 3;
    }

    totalPages = totalPages.slice(start, finish);
  }


  return (
    <div className="bg-white px-4 py-3 flex items-center justify-end border-t border-gray-200 sm:px-6">
      {totalPages.length > 1 && (
        <div className="flex items-center space-x-1">
          {filters.page > 1 && (
            <span
              onClick={() => updatePagination(filters.page - 1)}
              className="flex items-center px-4 py-2 text-gray-500 bg-gray-300 rounded-md"
            >
            <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24"
                 stroke="currentColor">
              <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M11 17l-5-5m0 0l5-5m-5 5h12"/>
            </svg>
          </span>
          )}
          {totalPages.map(index => (
            <span
              key={index}
              onClick={() => updatePagination(index + 1)}
              className={`px-4 py-2 bg-gray-200 rounded-md hover:bg-blue-500 hover:text-white ${filters.page === index+1 ? "text-white bg-blue-400": "text-gray-700"}`}
            >
            {index + 1}
          </span>
          ))}
          {filters.page < totalPages.at(-1) + 1 && (
            <span
              onClick={() => updatePagination(filters.page + 1)}
              className="px-4 py-2 text-gray-500 bg-gray-300 rounded-md hover:bg-blue-400 hover:text-white"
            >
              <svg xmlns="http://www.w3.org/2000/svg" className="w-6 h-6" fill="none" viewBox="0 0 24 24"
                   stroke="currentColor">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13 7l5 5m0 0l-5 5m5-5H6"/>
              </svg>
            </span>
          )}
        </div>
      )}
    </div>

  );
};

export default Pagination;
