import React from 'react';
import { CalendarIcon, LocationMarkerIcon, TruckIcon, UserIcon } from "@heroicons/react/solid";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const RouteDetailSidebar = ({order}) => {
  const { t } = useTranslation();

  return (
    <div>
      <h2 className="sr-only">Details</h2>
      <div className="space-y-5">
        <div className="sm:flex lg:block sm:justify-between">
          <div className="flex items-center space-x-2 sm:my-3">
            <LocationMarkerIcon className="h-5 w-5"/>
            <span className="text-gray-900 text-sm font-medium">{order.details.length} {t('orders.title')}</span>
          </div>
          <div className="flex items-center space-x-2 sm:my-3">
            <TruckIcon className="h-5 w-5"/>
            <span className="text-gray-900 text-sm font-medium">{t('admin.trucks.title')}: {order.truck.name}</span>
          </div>
          <div className="flex items-center space-x-2 sm:my-3">
            <UserIcon className="h-5 w-5"/>
            <span className="text-gray-900 text-sm font-medium">{t('admin.drivers.title')} {order?.driver?.name}</span>
          </div>
          <div className="flex items-center space-x-2 sm:my-3">
            <CalendarIcon className="h-5 w-5"/>
            <span className="text-gray-900 text-sm font-medium">{t('default.createdDate')} <time dateTime="2020-12-02">{order?.created_at}</time></span>
          </div>
        </div>
        <hr/>
        <div>
          <h4 className='px-1 pb-2'>{t('orders.title')}</h4>
          {order && order.details && (
            <div>
              {order?.details && order.details.map((detail, index) => (
                <Link to={`/orders/${order.id}`}>
                  <div className="border p-3 mb-2 shadow-md hover:border-blue-500 cursor-pointer">
                    <div>
                      <p>{detail.shipment.customer.email} - <span className="capitalize">{t(`orders.items.${detail.shipment.status.name}`)}</span></p>
                    </div>
                    <div key={index} className='grid grid-cols-2 py-3'>
                      <div>
                        <p>{detail.shipment.pickup_address.address_1}</p>
                        <p>{detail.shipment.pickup_address.state.name}, {detail.shipment.pickup_address.country.name}. {detail.shipment.pickup_address.zipcode}</p>
                      </div>
                      <div>
                        <p>{detail.shipment.delivery_address.address_1}</p>
                        <p>{detail.shipment.delivery_address.state.name}, {detail.shipment.delivery_address.country.name}. {detail.shipment.delivery_address.zipcode}</p>
                      </div>
                    </div>
                  </div>
                </Link>
              ))}
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default RouteDetailSidebar;
