import React from 'react';
import Pagination from "./Pagination";

const Table2 = ({filters, setFilters, children}) => {
  const updatePagination = (page) => {
    if(page > 0 && filters.pagination.last_page >= page){
      setFilters({
        ...filters,
        page
      });
    }
  }

  return (
    <div className="pt-5">
      <div className="flex flex-col">
        <div className="-my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="py-2 align-middle inline-block min-w-full">
            <div className="overflow-hidden">
              {children}
            </div>
          </div>
        </div>
      </div>
      {filters && (
        <Pagination filters={filters} updatePagination={updatePagination}/>
      )}
    </div>
  );
};

export default Table2;
