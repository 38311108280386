import React from 'react';
import PageHeader from "../UI-Components/PageHeader";
import NavTabs from "../UI-Components/NavTabs";
import { updateAdminMenu } from "../../actions/Menu";
import { connect } from "react-redux";

const Admin = ({adminMenu, updateAdminMenu, user, match}) => {
  const paramName = match.params.name;
  const activeMenuItem = adminMenu.find((item) => item.linkName === paramName);
  return (
    <div>
        <PageHeader title="Admin Panel" />
        <div className="px-8">
            <NavTabs user={user} tabs={adminMenu} activeItem={activeMenuItem} handleClick={updateAdminMenu}/>
            <div className="py-4">
              {
                activeMenuItem.component
              }
            </div>
        </div>
    </div>
  );
};

const stateToProps = state => (
  {
    adminMenu: state.menu.admin,
    orderStatus: state.orders.status,
    user: state.user.info,
  }
);

const dispatchToProps = dispatch => (
  {
    updateAdminMenu: (id) => dispatch(updateAdminMenu(id)),
  }
);

export default connect(stateToProps, dispatchToProps)(Admin);
